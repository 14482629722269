import { addConstants } from "../constants";

const initialState = {
  shouts: []
};

export function add(state = initialState, action) {
  switch (action.type) {
    case addConstants.GETALL_REQUEST:
      return {
        ...state,
        status: "GETALL_REQUEST"
      };
    case addConstants.GETALL_SUCCESS:
      return {
        ...state,
        adds: [...action.res.shouts],
        status: "GETALL_SUCCESS"
      };
    case addConstants.GETALL_ERROR:
      return {
        ...state,
        status: "GETALL_ERROR"
      };
    default:
      return state;
  }
}
