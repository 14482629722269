import {discountConstants} from "../constants";
import {discountServices} from "../services";

export const discountActions ={
    getDiscountDetails,
    getDiscountAmount
}

function getDiscountDetails(coupon_code){
    return dispatch =>{
        dispatch(request());
        discountServices.getDiscountDetails(coupon_code)
         .then(
             res   => dispatch(success(res)),
             error => dispatch(failure(error))
         );
    };

    function request(){
        return {type: discountConstants.DETAILS_REQUEST}
    }
    function success(res){
        return {type: discountConstants.DETAILS_SUCCESS,res};
    }
    function failure(error){
        return {type:discountConstants.DETAILS_ERROR,error}
    }
}

function getDiscountAmount(coupon_code,payload){
    return dispatch =>{
        dispatch(request());
        discountServices.getDiscountAmount(coupon_code,payload)
         .then(
             res   => dispatch(success(res)),
             error => dispatch(failure(error))
         );
    };

    function request(){
        return {type: discountConstants.APPLY_REQUEST}
    }
    function success(res){
        return {type: discountConstants.APPLY_SUCCESS,res};
    }
    function failure(error){
        return {type:discountConstants.APPLY_ERROR,error}
    }
}

