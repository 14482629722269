import { cartConstants } from "../constants";

const initialState = {
  inventories: [],
  partsCount: 0
};

export function cart(state = initialState, action) {
  switch (action.type) {
    case cartConstants.ADD_SUCCESS:
    if (state.inventories.length !== 0 ){
      return {
        ...state,
        status: "ADD_SUCCESS",
        inventories: [...state.inventories, {...action.payload,count:1}],
        partsCount: state.partsCount + 1
      };
    } else{
        return {
            ...state,
            status: "ADD_SUCCESS",
            inventories: [{...action.payload,count:1}],
            partsCount: 1
          };
    }

    case cartConstants.REMOVE_All_SUCCESS:
      return{
        ...state,
        partsCount:0,
        inventories:[],
        cart:[]
      };

    case cartConstants.REMOVE_SUCCESS:

        let index = state.inventories.findIndex(part => {
            return part.inventory_id === action.inventory_id;
        });

        return {
            ...state,
            status: "REMOVE_SUCCESS",
            inventories: [...state.inventories.slice(0, index), ...state.inventories.slice(index + 1)],
            partsCount: state.partsCount - 1
        };
    
    case cartConstants.UPDATE_SUCCESS:
      return {
        ...state,
        status: "UPDATE_SUCCESS", 
        inventories: state.inventories.map(part => {
          if (part.inventory_id !== action.inventory_id) {
            return part;
          }
          return {
            ...part,
            count: part.count + action.count
          };
        }),
      };      

    default:
      return state;
  }
}
