import { inventoryConstants } from "../constants";

const initialState = {
  inventories: []
};

export function inventory(state = initialState, action) {
  switch (action.type) {
    case inventoryConstants.GET_BY_INVENTORYID_REQUEST:
      return {
        ...state,
        status: "GET_BY_INVENTORYID_REQUEST"
      };
    case inventoryConstants.GET_BY_INVENTORYID_SUCCESS:
      return {
        ...state,
        status: "GET_BY_INVENTORYID_SUCCESS",
        inventories: action.res.inventory
      };
    case inventoryConstants.GET_BY_INVENTORYID_ERROR:
      return {
        ...state,
        status: "GET_BY_INVENTORYID_ERROR"
      };
    case inventoryConstants.GET_BY_ID_SUCCESS:
      if(state.status=="GET_BY_INVENTORYID_SUCCESS"){
        return {
          ...state,
          status: "GET_BY_ID_SUCCESS",
          inventories: [action.res.data.inventory]
        };
      }
      else{
        return {
          ...state,
          status: "GET_BY_ID_SUCCESS",
          inventories: [...state.inventories, action.res.data.inventory]
        };
      }
    default:
      return state;
  }
}
