export const searchConstants = {
  SEARCH_REQUEST: "SEARCH_SEARCH_REQUEST",
  FILTER_REQUEST: "SEARCH_FILTER_REQUEST",
  SEARCH_SCROLL_REQUEST : "SEARCH_SCROLL_SEARCH_REQUEST",
  SEARCH_ERROR: "SEARCH_SEARCH_ERROR",
  SEARCH_SCROLL_ERROR : "SEARCH_SCROLL_SEARCH_ERROR",
  FILTER_ERROR: "SEARCH_FILTER_ERROR",

  SEARCH_SUCCESS: "SEARCH_SEARCH_SUCCESS",
  FILTER_SUCCESS: "SEARCH_FILTER_SUCCESS",
  SEARCH_SCROLL_SUCCESS : "SEARCH_SCROLL_SEARCH_SUCCESS",
  
  FEATURED_REQUEST:"FEATURED_REQUEST",
  FEATURED_SUCCESS:"FEATURED_SUCCESS",
  FEATURED_ERROR:"FEATURED_ERROR",




};
