import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container"; 
import appinfo from "../../clientsJSON";

class RefundAndReturn extends Component {
  
    render() {

      return (
          <Container>
            <div style={styles.container}>
                <div style={styles.title}>Return and Refund policy</div>
                <div style={styles.innerContainer}>
                  {
                    appinfo.REFUND &&
                    appinfo.REFUND.map( e => {
                      if(e.key === "topic") {
                        return <div style={styles.topic}> {e.value} </div> ;
                      } else if (e.key === "para") {
                        return <div style={styles.para}> {e.value} </div>;
                      } else if( e.key === "ul") {
                        return (
                          <ul>
                            {
                              e.value.map( ul => {
                                return (
                                  <li><div style={styles.para}>
                                    {
                                      ul.map( li => {
                                        if(li.key === "title") 
                                          return <span style={{fontWeight:"bold"}}>{li.value}</span>;
                                        else return li.value; 
                                      })
                                    }
                                  </div></li>
                                );
                              })
                            }
                          </ul>
                        );
                      }
                    })
                  }
                </div>
            </div>  
          </Container>          
      );
    }
  }

  const styles = {
    container:{
       display:"flex",
       flexDirection:"column",
       flex:1, 
       marginBottom: 60
    },
    title:{
        color: appinfo.PRIMARY_COLOR,
        paddingTop:"20px",
        fontSize: "24px",
        margin: "auto",

    },
    innerContainer:{
        marginTop: "10px",
        paddingLeft:"70px",
        paddingRight: "70px",
    },
    para:{
        display:"flex",
        flex:1,
        flexDirection:"column",
        marginTop: "10px",
        justifyContent:"center",
        fontSize: "14px",
    },
    topic:{
        display:"flex",
        flex:1,
        color: appinfo.SECONDARY_COLOR,
        flexDirection:"column",
        marginTop: "10px",
        fontWeight:"bold",
        alignContent:"center",
        justifyContent:"center",
        fontSize: "16px",
    }
  };

  function mapStateToProps(state) {
    const { search } = state;
  
    return {
        search
    };
  }
  
  export default connect(mapStateToProps)(RefundAndReturn);
  
