import { userConstants } from "../constants";
import { userService } from "../services";

export const userActions = {
  login,
  signup,
  mobileotp,
  otpverification,
  otplogin,
  logout
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function signup(payload) {
  return dispatch => {
    dispatch(request());
    userService.signup(payload).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SIGNUP_REQUEST };
  }
  function success(user) {
    return { type: userConstants.SIGNUP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SIGNUP_FAILURE, error };
  }
}

function mobileotp(payload) {
  return dispatch => {
    dispatch(request());
    userService.mobileotp(payload).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.MOBILEOTP_REQUEST };
  }
  function success(otp) {
    return { type: userConstants.MOBILEOTP_SUCCESS, otp };
  }
  function failure(error) {
    return { type: userConstants.MOBILEOTP_ERROR, error };
  }
}

function otpverification(payload) {
  return dispatch => {
    dispatch(request());
    userService.otpverification(payload).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.OTPVERIFY_REQUEST };
  }
  function success(otp) {
    return { type: userConstants.OTPVERIFY_SUCCESS, otp };
  }
  function failure(error) {
    return { type: userConstants.OTPVERIFY_ERROR, error };
  }
}

function otplogin(payload) {
  return dispatch => {
    dispatch(request());
    userService.otplogin(payload).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.OTPLOGIN_REQUEST };
  }
  function success(otp) {
    return { type: userConstants.OTPLOGIN_SUCCESS, otp };
  }
  function failure(error) {
    return { type: userConstants.OTPLOGIN_ERROR, error };
  }
}

function logout() {

  userService.logout();
  return { type: userConstants.LOGOUT };
}
