export const shippingConstants ={
    ADD_ADDRESS_REQUEST: 'SHIPPING_ADD_ADDRESS_REQUEST',
    GET_ALL_REQUEST:'SHIPPING_GET_ALL_REQUEST',
    GET_AMOUNT_REQUEST:'SHIPPING_GET_AMOUNT_REQUEST',
    GET_DEFAULT_REQUEST: 'SHIPPING_GET_DEFAULT_REQUEST',
    MARK_DEFAULT_REQUEST:'SHIPPING_MARK_DEFAULT_REQUEST',
    EDIT_REQUEST:'EDIT_SHIPPING_ADDRESS_REQUEST',
    DELETE_REQUEST:'DELETE_SHIPPING_ADDRESS_REQUEST',

    ADD_ADDRESS_ERROR: 'SHIPPING_ADD_ADDRESS_ERROR',
    GET_ALL_ERROR:'SHIPPING_GET_ALL_ERROR',
    GET_AMOUNT_ERROR:'SHIPPING_GET_AMOUNT_ERROR',
    GET_DEFAULT_ERROR:'SHIPPING_GET_DEFAULT_ERROR',
    MARK_DEFAULT_ERROR:'SHIPPING_MARK_DEFAULT_ERROR',
    EDIT_ERROR:'EDIT_SHIPPING_ADDRESS_ERROR',
    DELETE_ERROR:'DELETE_SHIPPING_ADDRESS_ERROR',

    ADD_ADDRESS_SUCCESS: 'SHIPPING_ADD_ADDRESS_SUCCESS',
    GET_ALL_SUCCESS:'SHIPPING_GET_ALL_SUCCESS',
    GET_AMOUNT_SUCCESS:'SHIPPING_GET_AMOUNT_SUCCESS',
    GET_DEFAULT_SUCCESS: 'SHIPPING_GET_DEFAULT_SUCCESS',
    MARK_DEFAULT_SUCCESS:'SHIPPING_MARK_DEFAULT_SUCCESS',
    EDIT_SUCCESS:'EDIT_SHIPPING_ADDRESS_SUCCESS',
    DELETE_SUCCESS:'DELETE_SHIPPING_ADDRESS_SUCCESS',
};