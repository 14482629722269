import { checkoutConstants } from "../constants";
import { checkoutService } from "../services";

export const checkoutActions = {
  getquotation
};

function getquotation(payload,type) {
  return dispatch => {
    dispatch(request());
    checkoutService.getquotation(payload,type)
    .then(res =>  dispatch(success(res)), err => dispatch(error(err)));
  };

  function request(){
    return { type: checkoutConstants.GET_QUOTATION_REQUEST};
  }
  function success(res) {
    return { type: checkoutConstants.GET_QUOTATION_SUCCESS, res };
  }
  function error(err) {
    return { type: checkoutConstants.GET_QUOTATION_ERROR, err};
  }
}
