import { userConstants } from "../constants";

const initialState = {
  result: [],
  otp: []
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.SIGNUP_REQUEST:
      return {
        ...state,
        status: "SIGNUP_REQUEST"
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        status: "SIGNUP_SUCCESS",
        result: action.user,
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        ...state,
        status: "SIGNUP_ERROR",
        result : action.error,
      };
      case userConstants.MOBILEOTP_REQUEST:
      return {
        ...state,
        status: "MOBILEOTP_REQUEST"
      };
    case userConstants.MOBILEOTP_SUCCESS:
      return {
        ...state,
        status: "MOBILEOTP_SUCCESS",
        otp: action.otp,
      };
    case userConstants.MOBILEOTP_ERROR:
      return {
        ...state,
        status: "MOBILEOTP_ERROR",
        otp : action.error,
      };

    case userConstants.OTPVERIFY_REQUEST:
      return {
        ...state,
        status: "OTPVERIFY_REQUEST"
      };
    case userConstants.OTPVERIFY_SUCCESS:
      return {
        ...state,
        status: "OTPVERIFY_SUCCESS",
        otp: action.otp,
      };
    case userConstants.OTPVERIFY_ERROR:
      return {
        ...state,
        status: "OTPVERIFY_ERROR",
        otp : action.error,
      };
    
    case userConstants.OTPLOGIN_REQUEST:
      return {
        ...state,
        status: "OTPLOGIN_REQUEST"
      };
    case userConstants.OTPLOGIN_SUCCESS:
      return {
        ...state,
        status: "OTPLOGIN_SUCCESS",
        loggedIn: true,
        otp: action.otp,
      };
    case userConstants.OTPLOGIN_ERROR:
      return {
        ...state,
        status: "OTPLOGIN_ERROR",
        otp : action.error,
      }    
    default:
      return state;
  }
}
