import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container";
import appinfo from "../../clientsJSON";
import Product from "../../components/product";
import SearchLoader from "../../components/search_loader";
import { dealsActions } from "../../actions";
import Cookies from "universal-cookie";

import Contact from "../../images/why-choose-us/contact.png";
import Delivery from "../../images/why-choose-us/delivery.png";
import ProductPortfolio from "../../images/why-choose-us/product-portfolio.png";
import SaveMoney from "../../images/why-choose-us/save-money.png";
import SignUp from "../../images/why-choose-us/Group.png";
import {Helmet} from "react-helmet";

import "../styles.css";

class Deals extends Component {
    requestRedirect = () => {
        const { history } = this.props;
        const cook = new Cookies();
        cook.set("fromPage", "BOOK_NOW", { path: "/" });
        cook.set("id", "", {path: "/"});
        history.push("/submitform", { page: "demo" });
        window.scrollTo(0, 0);
      };

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(dealsActions.getDeals());
    }

    renderDeals = () => {
        const { deals, dispatch }  = this.props;
        // return null;
        if ( deals.status === "GET_DEALS_ERROR" || 
            ( deals.status === "GET_DEALS_SUCCESS" && deals.result.deals.length === 0 )
            ) {
                return <h4 style={{textAlign: "center", marginTop: 80}}>
                            Sorry !! No Deals Found
                        </h4>;
            }
        else if ( deals.status === "GET_DEALS_SUCCESS" && deals.result.deals.length != 0 ){
            let dealRender = [];
            deals.result.deals.map( dl => {
                dl.associations.map( e => {
                    if(e.status === 1){
                        let price_deals="";
                        if(e.inventory.recent_selling_price !== 0 && e.inventory.msrp_price !== 0){
                            price_deals="₹"+ e.inventory.recent_selling_price + "-" + "₹"+ e.inventory.msrp_price;
                        }
                        dealRender[dealRender.length]= {
                            inventory_id: e.inventory_id,
                            inventory_name: e.inventory.inventory_name,
                            sku_image: e.inventory.image_path,
                            product_name: e.inventory.brand,
                            brand: price_deals,
                        }
                    }
                });                    
            });
            return dealRender.map( val => {
                let inv_name = val.inventory_name.split(" ").join("-");
                let onClick = () =>  window.open(`product/${val.inventory_id}/${inv_name.replace(/\s+/g, '-').toLowerCase()}`);
                return <Product data={val} onClick={onClick}/>;
            });
        } else {
            return <SearchLoader />;
        }
    }

    render(){
        const { deals, search, history, location, authentication, user } = this.props;
        let whyChooseUs = [SignUp, ProductPortfolio, Contact,SaveMoney];
        let urlID=`${appinfo.ENDPOINTS.URI}/deals`;
        return(
            <Container menu={false}>
                <Helmet>
                    <meta name="description" content={appinfo.deals_description}/>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Active Deals" />
                    <meta property="og:url" content={urlID}/>
                    <meta property="og:description" content={appinfo.deals_description} />
                </Helmet>

                {/********************   DEALS HEADER *****************************/}

                <div className="deals-header">               
                    <div style={{textAlign: "center", color: "#ffffff"}}>
                        <div style={{fontSize: 20, paddingTop: "90px", fontWeight: "bold"}}>
                            GROUP DEALS
                        </div>
                        <div style={{fontSize: 18, paddingTop: 15}}>
                            Join the deal. Share it with your network. Enjoy massive discount.
                        </div>
                    </div>
                    {authentication.loggedIn || user.loggedIn ? (
                    <span></span>
                    ) : (
                    <div className="thuthu" style={{textAlign: "center",fontSize: 14, paddingTop: 15,color:appinfo.SECONDARY_COLOR,borderRadius:24,backgroundColor:'#FFF',padding:8,margin:'auto',marginTop:16,cursor:'pointer'}} onClick={()=>{history.push("/submitform",{page:"signup"})}}>
                    <span style={{textDecoration:'underline',fontWeight:700}}>Signup</span> to join & get notified about group deals
                    </div>
                ) }
                </div>

                {/********************   ONGOING DEALS SECTION  **********************/}
                    <div className="mpcenter">
                        <h2 
                            style={{
                                paddingTop: 13,
                                fontWeight: "bold",
                                color: appinfo.SECONDARY_COLOR,
                                fontSize: 18,
                                alignItems: "center"
                            }}
                            >
                                ONGOING DEALS
                        </h2>
                        <div
                            style={{
                                marginTop: 8,
                                width: 90,
                                height: 3,
                                backgroundColor: appinfo.PRIMARY_COLOR
                            }}
                        />
                        <div  className="product">
                            {this.renderDeals()}
                        </div>
                    </div>

                { /****************HOW GROUP DEALS WORK SECTION   *******************/}

                {/* <div className="why-choose-us">

                    <h2
                    style={{
                        paddingTop: 13,
                        fontWeight: "bold",
                        color: appinfo.SECONDARY_COLOR,
                        fontSize: 18,
                        alignItems: "center"
                    }}
                    >
                        HOW GROUP DEALS WORK
                    </h2>
                    <div
                    style={{
                        marginTop: 8,
                        width: 90,
                        height: 3,
                        backgroundColor: appinfo.PRIMARY_COLOR
                    }}
                    />

                    <div className="why-choose-us-content">


                        {
                        appinfo.CONTENTS.HomePage.whyChooseUs.desc.map( (wcu, index) => {
                            return (
                                <div className="wcu-div" key={index}>
                                    <div className="wcu-div-image">
                                    <img src={whyChooseUs[index]} width="77px" height="77px"/>
                                    </div>
                                    <div className="wcu-div-title">{"step "+ (index+1)}</div>
                                    <div className="wcu-div-desc">{wcu.dealStep}</div>
                                </div>
                            );
                        })
                        }

                    </div>
                </div> */}
            </Container>
        );
    }
}
function mapStateToProps(state) {
    const { deals,authentication, user } = state;
  
    return {
      deals,
      authentication,
      user
    };
  }
  
  export default connect(mapStateToProps)(Deals);
// export default Deals;