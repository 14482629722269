import { httpClient } from "../utils";
import appinfo from "../app.json"

export const checkoutService={
    getquotation,
};

function getquotation(payload,type) {
    if(type!=3){
        return httpClient.callApi("POST",`/order`,payload);
    }
    if(type==3){
        return httpClient.callApi("POST",`/quotation/buyer/request_quotation`,payload);
    } 
}
