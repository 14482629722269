import { inventoryConstants } from "../constants";
import { inventoryService } from "../services";

export const inventoryActions = {
  getByInventoryId,
  getById
};

function getByInventoryId(inventory_id) {
  return dispatch => {
    dispatch(request());
    inventoryService
      .getByInventoryId(inventory_id)
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: inventoryConstants.GET_BY_INVENTORYID_REQUEST };
  }
  function success(res) {
    return { type: inventoryConstants.GET_BY_INVENTORYID_SUCCESS, res };
  }
  function error(err) {
    return { type: inventoryConstants.GET_BY_INVENTORYID_ERROR, err };
  }
}

function getById(id) {
  return dispatch => {
    inventoryService.getByInventoryId(id).then(
      res => {
        let result = {
          id,
          data: res
        };
        dispatch(success(result));
      },
      err => {}
    );
  };

  function success(res) {
    return { type: inventoryConstants.GET_BY_ID_SUCCESS, res };
  }
}
