import React, { Component } from "react";
import appinfo from "../../clientsJSON";
import "../styles.css";
import { CollapsableList } from "../../components";
import { order } from "../../reducers/order.reducer";

class CollapsedItem extends Component {

    state = {
        show: -1
    }

    parseDate = date => {
        if (date === null) {
          return "Unknown";
        } else {
          return date.format("D MMM YYYY");
        }
      };

    reduceArray = (propertyName, list) => {
        const total = (accumulator, currentValue) => {
          return accumulator + currentValue[propertyName];
        };
    
        return list.reduce(total, 0);
      };
    
    render() {
        const { item, dispatch } = this.props;
        
        let order_lines = item.line_items;
        let totalQty = this.reduceArray("qty", order_lines);
        let orderPrice = 0;
        item.line_items.map(e => {
          orderPrice += e.line_amount + e.shipping_amount;
          orderPrice -= e.discount_amount;
        });
        let date = new Date( Date.parse(order_lines[0].created_at))
        return (
            <div style={{borderBottom: "solid 1px #d8d8d8"}}>
            <div style={styles.collapsedContent}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={styles.topic}>
                        Order Number
                    </div>
                    <div style={styles.value}>
                        { item.order_id } ( {totalQty} )
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={styles.topic}>
                        Ordered on
                    </div>
                    <div style={styles.value}>
                        {/* {this.parseDate(item.line_items[0].created_at)} */}
                        { date.toString()}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={styles.topic}>
                        Total Price
                    </div>
                    <div style={styles.value}>
                     &#8377;{ orderPrice }
                    </div>
                </div>
                <i className="material-icons large"
                    style={{ cursor: "pointer"}}
                    onClick={ () => this.setState({ 
                        show: this.state.show == item.order_id ? -1 : item.order_id
                        })
                    }
                >
                    { this.state.show == item.order_id ? "keyboard_arrow_up" : "keyboard_arrow_down" }
                </i>
            </div>
            { this.state.show === item.order_id && 
                <CollapsableList 
                    items={item.line_items} 
                    payment={item.payment_status}
                    paymentType={item.payment_details[0].payment_mode}
                /> 
            }
            </div>

            
        );
    }
}

export default CollapsedItem;

const styles = {
    collapsedContent : {
        display: "flex", 
        flexDirection: "row", 
        paddingTop:25, 
        paddingBottom: 25, 
        paddingLeft: 25, 
        paddingRight: 25,
        justifyContent: "space-between", 
        textAlign: "center"
    },
    topic : {
        opacity: 0.54,
        fontFamily: "OpenSans",
        fontSize: 12,
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom : 5
    },
    value : {
        fontFamily: "OpenSans",
        fontSize: 13,
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.54)"
    }
}
