import React, { Component } from "react";
import { connect } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {  profileActions } from "../../actions";
import { Loader } from "../../components";
import appinfo from "../../clientsJSON";
import notyf from "../../utils/notification"
class Profile extends Component {

    constructor() {
      super();
      this.state = {
        phoneError: false,
        myself: [],
        tenant: []
      }
    }

    componentDidMount() {
      const { dispatch } =this.props;
      dispatch(profileActions.getTenantDetails());
    }


    componentDidUpdate(prevProps){
      const { profile, dispatch } =this.props;

      let prev = prevProps.profile;

      if( profile.status === "TENANT_GET_SUCCESS" && prev.status !== "TENANT_GET_SUCCESS"){
        this.setState({ 
                        tenant: profile.tenant.tenants[0], 
                        business_mobile: profile.tenant.tenants[0].business_mobile
                      });
      }

      if( profile.status === "PROFILE_UPDATE_SUCCESS" && prev.status !== "PROFILE_UPDATE_SUCCESS"){
       notyf.open({type:"success",message:"PROFILE UPDATED SUCCESSFULLY"});
        dispatch(profileActions.getTenantDetails());
      }
      if( profile.status === "PROFILE_UPDATE_ERROR" && prev.status !== "PROFILE_UPDATE_ERROR"){
        notyf.open({type:"error",message:"PROFILE UPDATE ERROR"});
      }

      if( profile.status === "TENANT_GET_ERROR" && prev.status !== "TENANT_GET_ERROR"){
        alert(profile.tenant.message);
      }
    }

    handleChange = (event) => {
      this.setState({ [event.target.name] : event.target.value })
    }

    handleSubmit = () => {
      const { dispatch } =this.props;

      let state = Object.getOwnPropertyNames(this.state);
      let values = {};
      for( var i of state){
        if (["myself", "tenant", "phoneError"].indexOf(i) == -1){
          values[i]=this.state[i];
        }
      }
      

      dispatch(profileActions.updateUserDetails(values));

    }

    render() {

      const { profile } = this.props;
      const { tenant } =this.state;

      if( profile.status === "TENANT_GET_SUCCESS" || profile.status === "PROFILE_SUCCESS"){
      return (
            <div style={{marginLeft: "10%", marginBottom: 20}}>
              <input
                type="text"
                className="input"
                placeholder={ tenant.name ? tenant.name : "Enter Name" }
                name="name"
                onChange={this.handleChange}
              />
              <PhoneInput
                country="IN"
                name="business_mobile"
                value={this.state.business_mobile}
                className={this.state.phoneError ? "input input-error" : "input"}
                placeholder={ tenant.business_mobile ? tenant.business_mobile : "Enter phone number" }
                onChange={ business_mobile => this.setState({ business_mobile })}
              />
              <input
                type="email"
                className="input"
                placeholder={ tenant.email ? tenant.email : "Enter Email ID" }
                name="email"
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="input"
                placeholder={ tenant.name ? tenant.name : "HealthCare Facility Name" }
                name="healthcare"
                // onChange={this.handleChange}
              />
              <input
                type="text"
                className="input"
                placeholder={ tenant.address ? tenant.address : "Address Line 1" }
                name="address"
                onChange={this.handleChange}
              />
              {/* <input
                type="text"
                className="input"
                placeholder={ tenant.address?  tenant.address : "Address Line 2" }
                name="address2"
              /> */}
              <input
                type="text"
                className="input"
                placeholder={ tenant.city ? tenant.city : "City" }
                name="city"
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="input"
                placeholder={ tenant.pincode ? tenant.pincode : "PinCode" }
                name="pincode"
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="input"
                placeholder={ tenant.state ? tenant.state : "State" }
                name="state"
                onChange={this.handleChange}
              />
              {/* <input
                type="text"
                className="input"
                placeholder={ tenant.country ? tenant.country : "Country" }
                name="country"
                onChange={this.handleChange}
              /> */}
              <div>
                <button
                  className="demo-button-demo"
                  style={{backgroundColor:appinfo.PRIMARY_COLOR}}
                  onClick={() => this.handleSubmit()}
                >
                  SAVE
                </button>
              </div>
            </div>        
      );
      } else {
        return <Loader />;
      }
    }
  }


  function mapStateToProps(state) {
    const { user, search, profile } = state;
  
    return {
      user,
      search,
      profile
    };
  }
  
  export default connect(mapStateToProps)(Profile);
  