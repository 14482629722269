import {httpClient} from "../utils";

export const discountServices ={
     getDiscountDetails,  
     getDiscountAmount
}
 
function getDiscountDetails(coupon_code){
    return httpClient.callApi("GET",`/discount-coupon?coupon-code=${coupon_code}`);
}

function getDiscountAmount(coupon_code,payload){
    return httpClient.callApi("POST",`/discount-coupon/${coupon_code}/apply`,payload);
}