import Store from "../reducers";

export const utils = {
  getDataFromUrl,
  getSearchString,
  getValueFromUrl,
  getAllValueFromUrl,
  fixtwodecimal,
  hasParamKey,
  isEmpty,
  isObjectEmpty,
  imagePath,
  mergeObjects,
  removeStringFromArray,
  removeValueFromUrl,   
  setValueForUrl,
  isString,
  parseImagepath
};
function parseImagepath(list){
  return list.split(",")[0];
}
function getSearchString({
  category_name = "",
  sku_name = "",
  product_name = "",
  level = 0
}) {
  let category = constructPhrase(category_name, 1);
  let sku = constructPhrase(sku_name, 2);
  let product = constructPhrase(product_name, 3);
  let nameList = [];

  nameList = [category, sku, product].filter((name, index) => {
    if (index > level - 1) return false;

    return name != null;
  });

  return nameList.join(" AND ");
}
function fixtwodecimal(number){
  return parseFloat(parseFloat(Math.round(number*100)/100).toFixed(2));
}
function constructPhrase(name, type = 0) {
  let prefix = "";
  let main = `"${name}`;
  let suffix = `\\"`;

  if (isEmpty(name)) return null;

  switch (type) {
    case 1:
      prefix = "category_name: \\";
      break;
    case 2:
      prefix = "sku_name: \\";
      break;
    case 3:
      prefix = "product_name: \\";
      break;
    default:
      break;
  }

  return prefix + main + suffix;
}

function imagePath(fileName, size) {
  let { endpoint } = Store.getState();
  let url = endpoint.service_url;
  let cloud_id = endpoint.x_cloud_id;
  let argString = JSON.stringify({ size, cloud_id: `${cloud_id}` });
  let token = btoa(argString);
  let finalUrl = url + "/image/" + fileName + "?token=" + token;
  return finalUrl;
}

function isEmpty(value) {
  return value == null || typeof value === "undefined" || value.trim() === "";
}

function isObjectEmpty(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

function isString(val) {
  return typeof val === "string" || val instanceof String;
}

function mergeObjects(firstObj, secondObj) {
  return {
    ...firstObj,
    ...secondObj
  };
}

function removeStringFromArray(array, search_term) {
  let arrayCopy = array;
  var index = arrayCopy.indexOf(search_term);

  if (index !== -1) {
    arrayCopy.splice(index, 1);
  }

  return arrayCopy;
}

function getDataFromUrl(paramString) {
  let searchParams = new URLSearchParams(paramString);
  let data = {};

  for (let p of searchParams) {
    let key = p[0];
    let value = p[1];

    if (data.hasOwnProperty(key)) {
      data[key].push(value);
    } else {
      data[key] = [value];
    }
  }

  return data;
}

function hasParamKey(searchParams, key) {
  return new URLSearchParams(searchParams).has(key);
}

function getValueFromUrl(searchParams, key) {
  return new URLSearchParams(searchParams).get(key);
}

function getAllValueFromUrl(searchParams, key) {
  return new URLSearchParams(searchParams).getAll(key);
}

function removeValueFromUrl(searchParams, keys = []) {
  let urlSearchParams = new URLSearchParams(searchParams);

  keys.map(key => {
    urlSearchParams.delete(key);
  });

  return urlSearchParams.toString();
}

function setValueForUrl(searchParams, key, value) {
  let urlSearchParams = new URLSearchParams(searchParams);

  urlSearchParams.set(key, value);

  return urlSearchParams.toString();
}
