import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container"; 
import appinfo from "../../clientsJSON";
import {endpointActions} from "../../actions"

class TermsAndConditions extends Component{
    componentDidMount() {
        const { dispatch } = this.props;
    
        dispatch(endpointActions.termsAndConditions());
      }
    render(){
        const {endpoint}=this.props
        return(
            <Container>
                 <div style={styles.container}>
                <div style={styles.title}>Terms and Conditions</div>
                <div style={styles.innerContainer}>
                  {endpoint.termsAndConditions}
                </div>
            </div>  
            </Container>
        );
    }
}
const styles = {
    container:{
       display:"flex",
       flexDirection:"column",
       flex:1, 
       marginBottom: 60
    },
    title:{
        color: appinfo.PRIMARY_COLOR,
        paddingTop:"20px",
        fontSize: "24px",
        margin: "auto",

    },
    innerContainer:{
        marginTop: "10px",
        paddingLeft:"70px",
        paddingRight: "70px",
    },
    para:{
        display:"flex",
        flex:1,
        flexDirection:"column",
        marginTop: "10px",
        justifyContent:"center",
        fontSize: "14px",
    },
    topic:{
        display:"flex",
        flex:1,
        color: appinfo.SECONDARY_COLOR,
        flexDirection:"column",
        marginTop: "10px",
        fontWeight:"bold",
        alignContent:"center",
        justifyContent:"center",
        fontSize: "16px",
    }
}

function mapStateToProps(state) {
    const { endpoint } = state;
  
    return {
      endpoint
    };
}
export default connect(mapStateToProps)(TermsAndConditions);
