import { skudocConstants } from "../constants";

const initialState = {
  docs: []
};

export function skudoc(state = initialState, action) {
  switch (action.type) {
    case skudocConstants.DOCDETAILS_SUCCESS:
    
      return {
        ...state,
        status: "DOCDETAILS_SUCCESS",
        docs: action.res.docs
      };
    default:
      return state;
  }
}
