import appinfo from "../clientsJSON";
import { httpClient } from "../utils";

export const orderService = {
  createOrder,
  getAll,
  paymentUpdate,
  paymentPending
};

function createOrder(order) {
  return httpClient.callApi("POST", `/order`, order);
}

function getAll() {
  return httpClient.callApi("GET", `/order/byme`);
}

function paymentPending() {
  return httpClient.callApi("GET", `/order/byme/payment-pending`);
}

function paymentUpdate(transactionDetails) {
  return httpClient.callApi("PUT", `/order/payment`, transactionDetails);
}
