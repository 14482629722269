import React, { Component } from "react";
import { utils } from "../../utils";
import "../styles.css";
import appinfo from "../../clientsJSON";

export default class BreadCrumbs extends Component {
  removeSelection = (list, index) => {
    const { onSelection, searchParams } = this.props;
    const { removeValueFromUrl, setValueForUrl } = utils;
    let itemsToRemove = list.slice(index);
    let keysToRemove = itemsToRemove.map(e => {
      return e.key;
    });
    let newSearchParams = removeValueFromUrl(searchParams, keysToRemove);
    // console.log(list, index, itemsToRemove, keysToRemove, newSearchParams);
    newSearchParams = setValueForUrl(newSearchParams, "level", index);

    onSelection("?" + newSearchParams);
  };

  render() {
    const { containerStyle, hide, searchParams } = this.props;
    const { getAllValueFromUrl } = utils;
    let category_name = getAllValueFromUrl(searchParams, "category_name");
    let sku_name = getAllValueFromUrl(searchParams, "sku_name");
    let product_name = getAllValueFromUrl(searchParams, "product_name");
    let list = [
      { key: "category_name", value: category_name },
      { key: "sku_name", value: sku_name },
      { key: "product_name", value: product_name }
    ];
    let textStyle = {
      fontSize: 15,
      color: appinfo.PRIMARY_COLOR,
      cursor: "pointer"
    };
    let selectedTextStyle = {
      ...textStyle,
      color: appinfo.SECONDARY_COLOR,
      textDecoration: "underline"
    };
    list = list.filter(e => {
      return e.value.length != 0;
    });

    return (
      <div
        className={"bread-crumbs"}
        style={hide ? { display: "none" } : containerStyle}
      >
        {list.map((e, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div
                style={
                  index === list.length - 1 ? textStyle : selectedTextStyle
                }
                onClick={() => this.removeSelection(list, index + 1)}
              >
                {e.value[0]}
              </div>
              { e.value.length>1 &&
              <div style={textStyle}>
                  &amp; more
              </div>
              }
              {index !== list.length - 1 && (
                <div
                  style={{
                    fontSize: 11,
                    color: "rgba(32, 48, 71, 0.54)",
                    padding: "0px 5px"
                  }}
                >
                  /
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

BreadCrumbs.defaultProps = {
  containerStyle: {},
  hide: false,
  onSelection: () => null,
  searchParams: ""
};
