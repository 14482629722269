import { compareConstants } from "../constants";

const initialState = {
    compare: [],
};

export function compare(state = initialState, action) {
  switch (action.type) {
    case compareConstants.ADD_COMPARE_SUCCESS:
    if (state.compare.length !== 0){
      return {
        ...state,
        status: "ADD_TO_COMPARE_SUCCESS",
        compare: [...state.compare, {...action.payloadCompare}],
      };
    } else{
        return {
            ...state,
            status: "ADD_TO_COMPARE_SUCCESS",
            compare: [{...action.payloadCompare}],
          };
    }

    case compareConstants.REMOVE_All_COMPARE_SUCCESS:
      return{
        ...state,
        status:"REMOVE_ALL_COMPARE_SUCCESS",
        compare:[],
      };

    case compareConstants.REMOVE_COMPARE_SUCCESS:

        let index = state.compare.findIndex(part => {
            return part.inventory_id === action.inventory_id;
        });

        return {
            ...state,
            status: "REMOVE_FROM_COMPARE_SUCCESS",
            compare: [...state.compare.slice(0, index), ...state.compare.slice(index + 1)],
        };    

    default:
      return state;
  }
}
