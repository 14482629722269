import React, { Component } from "react";
import { connect } from "react-redux";
import { CollapsedItem, Loader } from "../../components";
import { orderActions } from "../../actions";

class TrackOrders extends Component {

    componentDidMount(){
      const { dispatch } =this.props;
      dispatch(orderActions.getAll());
    }
  
    render() {
      const { order, dispatch } = this.props;
      
      if( order.status === "GET_ALL_SUCCESS") {
        if( order.order_list.length !== 0) {
          {
            return order.order_list.map ( value => {
              return <CollapsedItem dispatch={dispatch} item={value}/>;
            })
          }
        } else {
          return (
            <div style={{ textAlign: "center", paddingTop: 50}}>
              Place and Order to track it.
            </div>
          );
        }
      } else {
        return <Loader />;
      }
    }
  }


  function mapStateToProps(state) {
    const { order } = state;
  
    return { 
      order
    };
  }
  
  export default connect(mapStateToProps)(TrackOrders);
  