export const orderConstants = {
    CREATE_ORDER_REQUEST: 'ORDER_CREATE_ORDER_REQUEST',
    GET_ALL_REQUEST: 'ORDER_GET_ALL_REQUEST',
    PAYMENT_UPDATE_REQUEST: 'ORDER_PAYMENT_UPDATE_REQUEST',
    PAYMENT_PENDING_REQUEST: 'ORDER_PAYMENT_PENDING_REQUEST',
    CREATE_ORDER_FAILURE: 'ORDER_CREATE_ORDER_FAILURE',
    GET_ALL_FAILURE: 'ORDER_GET_ALL_FAILURE',
    PAYMENT_UPDATE_FAILURE: 'ORDER_PAYMENT_UPDATE_FAILURE',
    PAYMENT_PENDING_ERROR: 'ORDER_PAYMENT_PENDING_ERROR',

    CREATE_ORDER_SUCCESS: 'ORDER_CREATE_ORDER_SUCCESS',
    GET_ALL_SUCCESS: 'ORDER_GET_ALL_SUCCESS',
    PAYMENT_UPDATE_SUCCESS: 'ORDER_PAYMENT_UPDATE_SUCCESS',
    PAYMENT_PENDING_SUCCESS: 'ORDER_PAYMENT_PENDING_SUCCESS',
};