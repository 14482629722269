import { searchConstants } from "../constants";
import { searchService } from "../services";
import { utils } from "../utils";

export const searchActions = {
  search,
  searchFilter,
  searchV2,
  searchScroll,
  searchV3
};

function search(payload = {}, page = 0) {
  return dispatch => {
    dispatch(request());
    searchService
      .search(payload, page)
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: searchConstants.SEARCH_REQUEST };
  }
  function success(res) {
    return { type: searchConstants.SEARCH_SUCCESS, res };
  }
  function error(err) {
    alert("ERROR! REFRESH THE PAGE");
    return { type: searchConstants.SEARCH_ERROR, err };
  }
}

function searchScroll(payload, page = 0) {
  return dispatch => {
    dispatch(request());
    searchService
      .search(payload, page)
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: searchConstants.SEARCH_SCROLL_REQUEST };
  }
  function success(res) {
    return { type: searchConstants.SEARCH_SCROLL_SUCCESS, res };
  }
  function error(err) {
    alert("ERROR! REFRESH THE PAGE");
    return { type: searchConstants.SEARCH_SCROLL_ERROR, err };
  }
}

function searchV2(payload, page = 0, resetFilters = true) {
  const { SEARCH_SUCCESS, SEARCH_SCROLL_SUCCESS } = searchConstants;

  return dispatch => {
    dispatch(request());
    searchService
      .searchV2(payload, page)
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: searchConstants.SEARCH_REQUEST };
  }
  function success(res) {
    return {
      type: page === 0 ? SEARCH_SUCCESS : SEARCH_SCROLL_SUCCESS,
      res,
      resetFilters
    };
  }
  function error(err) {
    return { type: searchConstants.SEARCH_ERROR, err };
  }
}

function searchFilter ( payload, resetFilters = true ) {
  return dispatch => {
    dispatch(request());
    searchService.searchV2( payload, 0)
    .then ( res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request(){
    return { type: searchConstants.FILTER_REQUEST };
  }

  function success( res ) {
    return { type: searchConstants.FILTER_SUCCESS, res, resetFilters };
  } 

  function error(err) {
    return { type: searchConstants.FILTER_ERROR, err };
  }

}
function searchV3(payload){
  return dispatch => {
      dispatch(request());
      searchService.searchV3(payload).then(res => {
        let result={
         
          search_result:res.search_result
        
        };
       
        dispatch(success(result))}, error => dispatch(failure(error)));

  };
  function request(){
      return {type: searchConstants.FEATURED_REQUEST};
  }
  function success(res){
      return {type: searchConstants.FEATURED_SUCCESS,res};
  }
  function failure(error){
      return {type:searchConstants.FEATURED_ERROR,error};
  }
}
