import React, { Component } from "react";
import phone from "../../images/phone-success/phone-success.png";
import mail from "../../images/mail-success/mail-success.png";
import success from "../../images/success/success.png";
import "../styles.css";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import {categoryActions,searchActions} from "../../actions";
import { MayIHelpYou } from "../../components";

class Success extends Component {
    constructor() {
      super();
    }
    performSearch = payload => {
        const { dispatch, history } = this.props;
      
        if (!utils.isEmpty(payload.search)) {
          dispatch(searchActions.search(payload));
          history.push("/search");
        }
      };

    performSearchV2 = () => {
      const { dispatch, history } = this.props;
  
        let payload = {
          search: "*"
        };
        dispatch(searchActions.searchV2(payload));
        history.push("/search?searchQuery=*&id=null", {searchPayload: payload});
  
    };

    render() {
      const { history } = this.props;
        
        return (
          <div style={{ display: "flex" }}>
          <div
            className="demo" style={{backgroundColor:appinfo.SECONDARY_COLOR,minHeight:550}}
          >
            {/* <img src="#"  alt="Demo" className="backgroundimage" /> */}
            <div
              className="insidedemo" 
            >
              <img
                onClick = {() => history.push("/home")}
                className="titleimage"
                src={appinfo.logowhite}
                alt={"Title"}
              />
              <div className="quotedata"   >
                {appinfo.CONTENTS.submitFormPage}

                <div className="statssection"     >

                  {
                    appinfo.stats.map( indStats => {
                      return (
                        <div>
                          <div style={{ fontSize: 22, fontWeight: "bold" }}>{indStats.value}{indStats.adder}</div>
                          <div style={{ fontSize: 16, fontWeight: "600" }}>
                            {indStats.key}
                          </div>
                        </div>
                      );
                    })
                  }

                </div>
              </div>
            </div>
          </div>
          <div className="demoright" >
            <div className="phoneemail" >
              <div className="mobile">
              <a href={"tel:"+appinfo.PHONE} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
              <img src={phone} className="phone" />
              &nbsp;{appinfo.PHONE} &nbsp;
              </a>
              </div>
              <div className="email">
              <a href={"mailto:"+appinfo.EMAIL} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
              <img src={mail} className="phone"/>
              &nbsp;{appinfo.EMAIL}
              </a>
              </div>
            </div>
                <div className="successcontainer" >
                <div className="successinline" >
                    <div className="successdata">
                    <img src={success} height="36" width="36" alt="success"  />
                    </div>
                    <div
                        style={{
                            marginTop: "15px",
                            marginLeft: "auto",
                            marginRight:"auto",
                            fontWeight:"bold",
                            fontSize: "18px",
                            color:appinfo.SECONDARY_COLOR,
                        }}
                    >
                        {history.location.state.message[0]}
                    </div>
                        <div  style={{
                                marginTop: 10,
                                marginLeft:"auto",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                marginRight: "auto",
                                fontSize: "16px",
                                color:"rgb(0,0,0,0.87)",
                            }}>
                        {history.location.state.message[1]}
                        </div>
                        <div  style={{
                                marginLeft:"auto",
                                marginRight: "auto",
                                fontSize: "16px",
                                color:"rgb(0,0,0,0.87)",
                            }}>
                        </div>

                        <div  style={{
                                marginTop: 30,
                                marginLeft:"auto",
                                marginRight: "auto",
                                fontSize: "16px",
                                color:"rgb(0,0,0,0.54)",
                                fontWeight:"normal",
                                fontStyle:"normal",
                                fontStretch:"normal",
                            }}>
                        In the meantime, you can explore our
                        </div>
                        <div  style={{
                                marginLeft:"auto",
                                marginRight: "auto",
                                fontSize: "16px",
                                color:"rgb(0,0,0,0.54)",
                                fontWeight:"normal",
                                fontStyle:"normal",
                                fontStretch:"normal",
                            }}>
                         other products
                        </div>
                    
                    <div style={{
                        display: "flex",
                        justifyContent:"center"}}
                    >
                        <button
                        className="demo-button"
                        style={{
                            color: "#ffffff",
                            backgroundColor: appinfo.PRIMARY_COLOR,
                            boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.18)",
                            fontWeight: "600",
                            maxWidth: 180,
                            marginTop:10,
                        }}
                        onClick={() => {
                            let payload = {
                            search: "*",
                            category_id:null
                          };
                          this.performSearchV2();}}
                        >
                        SEE PRODUCTS
                        </button>
                    </div>
                </div>
                </div>
            </div>
              {/* <MayIHelpYou /> */}
          </div>
        );
      }
}  

function mapStateToProps(state) {
    const { category, sku } = state;
  
    return {
      category,
      sku
    };
  }
  
  export default withRouter(connect(mapStateToProps)(Success));