import React, { Component } from "react";
import { connect } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {signupActions} from "../../actions";
import appinfo from "../../clientsJSON";

class Signup extends Component {
    constructor() {
        super();
        this.state = {
          username: "",
          phone: "",
          phoneError: false,
          email: "",
          street1: "",
          street2: "",
          city: "",
          state: "",
          pincode: "",
          error:false
        };
      }
    
    handleSubmit = () => {
        const { dispatch,history, signup } = this.props;
        if(this.validatePhoneInput() && this.state.username !== "" && this.state.email !== "" && this.state.street1 !== "" && this.state.street2 !== "" && this.state.city !== "" && this.state.state !== "" && this.state.pincode !== ""){
            let payload= {
                "mobile_no":this.state.phone
              }
            dispatch(signupActions.otpVerify(payload));
        } else {
          this.setState({
            error:true
          })
        }
    }

    handleChange = event => {
      this.setState({ [event.target.name]: event.target.value });
      let property = document.getElementsByName(event.target.name)[0];
      if(property.value == ""){
        property.style.borderColor = "red";
      }
    }; 

    validatePhoneInput = () => {
        const { phone, phoneError } = this.state;
        let valid = isValidPhoneNumber(phone || "");
        this.setState({
          phoneError: !valid
        })
        return valid;
      }

    componentWillReceiveProps(newProps){
      const {signup,history}=newProps;
      if(signup.status == "OTP_VERIFICATION_SUCCESS"){
        history.push("/verify",{name: this.state.username,phone:this.state.phone,email:this.state.email,street1:this.state.street1,street2:this.state.street2,city:this.state.city,state:this.state.state,pincode:this.state.pincode });
      }
    }
    
    render(){
        const { phoneError} = this.state;
        const { history } = this.props;
        let error = true;
        return (
            <div style={{ display: "flex" }}>
              <div className="demo" style={{backgroundColor:appinfo.PRIMARY_COLOR,flex:1,position:"relative",minHeight:720}}>
                <div className="insidedemo" style={{position:"absolute"}}>
                    <img
                    onClick = {() => history.push("/home")}
                    className="titleimage"
                    src={appinfo.logowhite}
                    alt={"Title"}
                    /> 
                    <div style={{color:"white",textAlign:"center",padding:20,marginTop:200}}>
                      <div>{appinfo.CONTENTS.submitFormPage}
                          <div style={{display:"flex",padding:20}}>
                          {
                            appinfo.stats.map( (indStats,index) => {
                              return (
                                <div key={index} style={{padding:8}}>
                                  <div style={{ fontSize: 22, fontWeight: "bold" }}>{indStats.value}{indStats.adder}</div>
                                  <div style={{ fontSize: 16, fontWeight: "600" }}>{indStats.key}</div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>                      
                    </div>                 
                </div>
              </div>
              <div style={{flex:1,display:"flex",flexDirection:"column",padding:20}}>  
                <input name="username" type="text" className="input" placeholder="Name" onChange={this.handleChange}/>
                <PhoneInput
                country="IN"
                name="phone"
                className={phoneError ? "input input-error" : "input"}
                placeholder="Enter mobile number"
                value={this.state.phone}
                onChange={phone => this.setState({ phone })} />  
                <input name="email" type="text" className="input" placeholder="email address" onChange={this.handleChange}/>
                <input name="street1" type="text" className="input" placeholder="Address Line 1" onChange={this.handleChange}/>
                <input name="street2" type="text" className="input" placeholder="Address Line 2" onChange={this.handleChange}/>
                <input name="city" type="text" className="input" placeholder="City" onChange={this.handleChange}/> 
                <input name="state" type="text" className="input" placeholder="State" onChange={this.handleChange}/>
                <input name="pincode" type="text" className="input" placeholder="Pincode" onChange={this.handleChange}/>
                {(this.state.error) && <div id="errorSubmit" style={{color:"red",paddingTop:32}}>Error! Please check the details entered.</div>}
                <p><button onClick={this.handleSubmit} style={{height:34,border:"none",color:"white",backgroundColor:appinfo.PRIMARY_COLOR,borderRadius:8,padding:"0px 22px 0px 22px",cursor:"pointer",fontWeight:600}}>SIGN UP</button></p>  
              </div>    
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { signup } = state;
    return {
        signup
    };
  }
  
export default connect(mapStateToProps)(Signup);