import { searchConstants } from "../constants";

const initialState = {
  results: [],
  aggregations: [],
  resetFilters: true
};

export function search(state = initialState, action) {
  switch (action.type) {
    case searchConstants.SEARCH_REQUEST:
      return {
        ...state,
        status: "SEARCH_REQUEST"
      };
    case searchConstants.SEARCH_SUCCESS:
      return {
        ...state,
        status: "SEARCH_SUCCESS",
        results: action.res.search_result.hits.hits,
        total: action.res.search_result.hits.total,
        // aggregations: action.res.search_result.aggregations,
        resetFilters: action.resetFilters
      };
    case searchConstants.FILTER_SUCCESS :
      return {
        ...state,
        aggregations: action.res.search_result.aggregations,
        resetFilters: action.resetFilters
      }
    case searchConstants.SEARCH_ERROR:
      return {
        ...state,
        status: "SEARCH_ERROR"
      };
    case searchConstants.SEARCH_SCROLL_REQUEST:
      return {
        ...state,
        status: "SEARCH_SCROLL_REQUEST"
      };
    case searchConstants.SEARCH_SCROLL_SUCCESS:
      return {
        ...state,
        status: "SEARCH_SCROLL_SUCCESS",
        results: [...state.results, ...action.res.search_result.hits.hits],
        total: action.res.search_result.hits.total,
        resetFilters: action.resetFilters
      };
    case searchConstants.SEARCH_SCROLL_ERROR:
      return {
        ...state,
        status: "SEARCH_SCROLL_ERROR"
      };
    default:
      return state;
  }
}
