import React, { Component } from "react";
import { connect } from "react-redux";

import "./index.css";
import WavePattern from "../../images/wave-pattern/wave-pattern.png";
import { MayIHelpYou } from "../../components";
import appinfo from "../../clientsJSON";

class Login extends Component {
    render(){
        const { history } = this.props;
        let size = window.screen;
        document.title = appinfo.TITLE;

        return(
            <div style={{backgroundColor: "#f9f9f9", height: size.width < 768 ? size.height : size.height-192}}>
                <div className="top-contacts">
                    <div className="top-phone">
                        <a href={"tel:"+appinfo.PHONE} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
                            <i className="material-icons mi">phone</i>
                            &nbsp;{appinfo.PHONE} &nbsp;
                        </a>
                    </div>
                    <div className="top-phone">
                        <a href={"mailto:"+appinfo.EMAIL} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
                            <i className="material-icons mi">mail</i>
                            &nbsp;{appinfo.EMAIL}
                        </a>
                    </div>
                </div>
                <div className="login-header">
                    <div className="login-logo" onClick={()=>{history.push("/home")}}>
                        <img src={appinfo.logo} height={40} />
                    </div>
                    <div className="signup-button" style={{color:appinfo.PRIMARY_COLOR,borderColor:appinfo.PRIMARY_COLOR}} onClick={()=>{history.push("/signup")}}>
                        <div style={{paddingTop:"8px"}}>CREATE AN ACCOUNT</div>
                    </div>
                </div>

                { this.props.children }

                <div style={{ position: "fixed", bottom: "0px" }}>
                    {/* <img src={WavePattern} width="100%" /> */}
                </div>
                {/* <MayIHelpYou /> */}
            </div>);
    }
}

function mapStateToProps(state) {
    const { user } = state;
  
    return {
      user
    };
  }
  
  export default connect(mapStateToProps)(Login);