import { endpointConstants } from "../constants";
import appinfo from "../clientsJSON";

const initialState = {
  service_url: appinfo.ENDPOINTS.URI,
  x_cloud_id: appinfo.X_CLOUD_ID,
  status: "IDLE",
  termsAndConditions: "",
 
 
};

export function endpoint(state = initialState, action) {
  switch (action.type) {
    case endpointConstants.HELLO_REQUEST:
      return {
        ...state,
        status: "HELLO_REQUEST"
      };
      case endpointConstants.TERMS_CONDITIONS_REQUEST:
        return {
          ...state,
          status: "TERMS_CONDITIONS_REQUEST"
        };
    case endpointConstants.HELLO_SUCCESS:
      return {
        ...state,
        service_url:  appinfo.ENDPOINTS.URI,
        x_cloud_id: action.res.cloud_id,
        status: "HELLO_SUCCESS"
      };
      case endpointConstants.TERMS_CONDITIONS_SUCCESS:
        return {
          ...state,
          termsAndConditions: action.res["terms-condition"].terms_condition,
          status: "TERMS_CONDITIONS_SUCCESS"
        };
        case endpointConstants.TERMS_CONDITIONS_ERROR:
          return {
            ...state,
            status: "TERMS_CONDITIONS_ERROR"
          };
        
    default:
      return state;
  }
}
