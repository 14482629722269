import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import FacebookImage from "../../images/facebook/facebook@3x.png";
import instagramImage from "../../images/instagram/instagram@3x.png";
import LinkedInImage from "../../images/linkedin/linkedin.png";

import "../styles.css";
import "./container.css";
import { utils } from "../../utils";
import {
  categoryActions,
  searchActions,
  userActions,
  profileActions
} from "../../actions";
import appinfo from "../../clientsJSON";
import {  MayIHelpYou, TeamPopup } from "../../components";
import { bold, stripColor } from "ansi-colors";

class Container extends Component {
  constructor() {
    super();
    const cookie = new Cookies();
    this.state = {
      searchQuery: "",
      hoveringOn: -1,
      hoveringLevelOn: -1,
      hoveringLevelThreeOn: -1,
      hoveringMobileLevelTwoOn: -1,
      loggedIn: cookie.get("loggedIn"),
      cartCount:0
    };

    this.keys = [
      "category_name",
      "product_name",
      "sku_name",
      "fromMenu",
      "level",
      "category_id",
      "searchQuery"
    ];
    this.hoverState = {
      category_name: null,
      product_name: null,
      sku_name: null
    };
    this.category_name = null;
    this.product_name = null;
    this.sku_name = null;
    this.fromMenu = false;
    this.level = 0;
    this.category_id = null;
    this.searchQuery = "";
  }

  componentDidMount() {
    const { dispatch, category, profile, location } = this.props;
    const hasFilter = new URLSearchParams(location.search).has("filter");

    document.title = appinfo.TITLE;

    if (!hasFilter) {
      this.getUrlQueryInfo();
      this.performSearchV2();
    }

    if (profile.status != "PROFILE_SUCCESS" && this.state.loggedIn == "true") {
      dispatch(profileActions.whoami());
    }
    if (category.status != "GET_SUCCESS") {
      dispatch(categoryActions.getMenu());
    }
  }

  getUrlQueryInfo = () => {
    const { location } = this.props;
    let keys = [
      "category_name",
      "sku_name",
      "product_name",
      "searchQuery",
      "category_id",
      "fromMenu",
      "level"
    ];
    let searchParams = new URLSearchParams(location.search);

    for (let key of keys) {
      try {
        this[key] = searchParams.get(key);
      } catch (e) {
        this[key] = null;
      }
    }
  };

  getUrlQueryString = () => {
    const { searchQuery, fromMenu, level } = this;
    let urlQueryString = "";

    if (fromMenu) {
      [
        "fromMenu",
        "category_id",
        "level",
        "category_name",
        "sku_name",
        "product_name"
      ].map((key, index) => {
        if (this[key] && index <= level + 2) {
          let encodedString = encodeURIComponent(this[key]);

          urlQueryString += `${key}=${encodedString}&`;
        }
      });

      urlQueryString = urlQueryString.slice(0, -1);
    } else {
      let encodedString = encodeURIComponent(searchQuery);

      urlQueryString = `searchQuery=${encodedString}`;
    }

    return urlQueryString;
  };

  filterHovers = () => {
    const { level } = this;

    ["category_name", "sku_name", "product_name"].map((name, index) => {
      let val = this.hoverState[name];

      if (!val) return -1;

      if (index <= level - 1) {
        this[name] = val;
      } else {
        this[name] = null;
      }
    });
  };

  performSearchV2 = (payload = {}) => {
    this.filterHovers();

    const {
      category_name,
      level,
      sku_name,
      product_name,
      fromMenu,
      category_id,
      searchQuery,
      props
    } = this;
    const { dispatch, history, match } = props;
    let rawSearchPayload = {
      category_name,
      sku_name,
      product_name,
      level
    };
    let searchPayload = utils.getSearchString(rawSearchPayload);
    let finalPayload = {};
    let urlQuery = this.getUrlQueryString();
    const cookies = new Cookies();

    if (
      history.location.pathname !== `/search` &&
      utils.isObjectEmpty(payload)
    ) {
      return;
    }

    finalPayload = {
      search: fromMenu ? searchPayload : searchQuery
    };

    if (fromMenu) {
      finalPayload["filter"] = { category_id: category_id };
    }

    let filterPayload = {
      search: "*",
      filter : {
        category_id: category_id
      }
    }

    dispatch(searchActions.searchV2(finalPayload));
    dispatch(searchActions.searchFilter(filterPayload));

    cookies.set("search", payload.search, { path: "/" });
    history.push(`/search?` + urlQuery, {
      searchPayload: finalPayload,
      rawSearchPayload
    });
  };

  onChangeText = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  onChangeState = (inputKey, val) => {
    let value =
      inputKey == "hoveringMobileLevelTwoOn"
        ? this.state[inputKey] == val
          ? -1
          : val
        : val;
    this.setState({ [inputKey]: value });
  };

  handleLogout = () => {
    const { dispatch } = this.props;
    const cookie = new Cookies();
    cookie.remove("loggedIn");
    cookie.set("loggedIn", "false", { path: "/" });
    this.setState({ loggedIn: "false" });
    dispatch(userActions.logout());
    window.location.reload();
  };

  redirectDemo = () => {
    const { history } = this.props;
    const cookie = new Cookies();
    cookie.set("fromPage", "CONTACT_US", { path: "/" });
    cookie.set("id", null, { path: "/" });
    history.push("/submitform", { page: "demo" });
    window.scrollTo(0, 0);
  };

  redirectTermsPrivacy = (path) => {
    const { history } = this.props;
    history.push(`${path}`);
    window.scrollTo(0, 0);
  };

  redirectZepnur = (path) => {
    window.open(`http://zepnur.com${path}`, "_blank");
  };

  handleSubmit = e => {
    const { searchQuery } = this.state;

    e.preventDefault();

    let payLoad = {
      search: searchQuery,
      category_id: null
    };

    this.fromMenu = false;
    this.category_id = null;
    this.searchQuery = searchQuery;
    this.performSearch(payLoad);
  };

  performSearch = payload => {
    if (!utils.isEmpty(payload.search)) {
      this.performSearchV2(payload);
    }
  };

  renderLevelThree = (l1, id, action) => {
    const { category } = this.props;
    const { hoverState } = this;

    let arr = category.categories[l1].data.map(l2 => {
      if (l2.hasOwnProperty(id)) {
        return l2;
      }
    });
    let menu = arr.filter(e => e !== undefined);
    if (menu) {
      return (
        <>
          {menu[0][id].map((e, index) => {
            let payload = {
              search: e,
              category_id: action
            };

            return (
              <div
                onMouseEnter={() => {
                  hoverState["product_name"] = e;
                  this.onChangeState("hoveringLevelThreeOn", index);
                }}
                onMouseLeave={() =>
                  this.onChangeState("hoveringLevelThreeOn", -1)
                }
                onClick={() => {
                  this.fromMenu = true;
                  this.level = 3;
                  this.performSearch(payload);
                }}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  backgroundColor:
                    index == this.state.hoveringLevelThreeOn
                      ? "#f1f1f1"
                      : "#f9f9f9",
                  color:
                    index == this.state.hoveringLevelThreeOn
                      ? appinfo.SECONDARY_COLOR
                      : "rgba(0, 0, 0, 0.54)",
                  fontSize: 13
                }}
              >
                {e.toLocaleUpperCase()}
              </div>
            );
          })}
        </>
      );
    }
  };

  renderLevelTwo = (id, action) => {
    const { category } = this.props;
    const { hoverState } = this;
    let menu = category.categories[id].data.map(l2 => {
      return Object.keys(l2);
    });
    if (menu) {
      return (
        <>
          {menu.map((e, index) => {
            let payload = {
              search: e[0],
              category_id: action
            };

            return (
              <div key={index} style={{ display: "flex" }}>
                <div
                  onMouseEnter={() => {
                    this.onChangeState("hoveringLevelOn", index);
                    hoverState["sku_name"] = e[0];
                  }}
                  onClick={() => {
                    this.level = 2;
                    this.fromMenu = true;
                    this.performSearch(payload);
                  }}
                  style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    backgroundColor:
                      index == this.state.hoveringLevelOn
                        ? "#f1f1f1"
                        : "#ffffff",
                    color:
                      index == this.state.hoveringLevelOn
                        ? appinfo.SECONDARY_COLOR
                        : "rgba(0, 0, 0, 0.54)",
                    fontSize: 13,
                    width: 180,
                  }}
                >
                  {e[0].toLocaleUpperCase()}
                </div>
                {appinfo.menu == 3 && index == this.state.hoveringLevelOn && (
                  <div
                    onMouseEnter={() =>
                      this.onChangeState("hoveringLevelOn", index)
                    }
                    onMouseLeave={() =>
                      this.onChangeState("hoveringLevelOn", -1)
                    }
                    style={{
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 7px 0 rgba(81, 81, 81, 0.15)",
                      position: "absolute",
                      width: 180,
                      zIndex: 2,
                      left: this.state.hoveringOn > 8 ? -180 : 180,
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "center",
                      cursor: "pointer"
                    }}
                  >
                    {this.renderLevelThree(id, payload.search, action)}
                  </div>
                )}
              </div>
            );
          })}
        </>
      );
    }
  };

  renderLevelOne = () => {
    const { category } = this.props;
    const { hoverState } = this;

    let menu = Object.keys(category.categories);
    if (menu) {
      return (
        <>
          {menu.map((e, index) => {
            let payload = {
              category_id: category.categories[menu[index]].category_id,
              search: e
            };
            return (
              <div key={index}
                style={{
                  position: "relative",
                  display: "flex"
                }}
              >
                <li
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20
                  }}
                >
                  <div
                    onMouseEnter={() => {
                      this.onChangeState("hoveringOn", index);
                      hoverState["category_name"] = e;
                      this.category_id = payload.category_id;
                    }}
                    onMouseLeave={() => this.onChangeState("hoveringOn", -1)}
                    onClick={() => {
                      this.level = 1;
                      this.fromMenu = true;
                      this.performSearch(payload);
                    }}
                    style={{
                      textDecoration: "none",
                      color: appinfo.SECONDARY_COLOR,
                      fontSize: "12px",
                      // fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      padding: "10px 0px",
                      cursor: "pointer",
                      // color:
                      //   index == this.state.hoveringOn ?   appinfo.SECONDARY_COLOR:appinfo.PRIMARY_COLOR,
                      color:
                      index==appinfo.PRIMARY_COLOR,
                      borderBottom:
                        index == this.state.hoveringOn
                          ? ("3px solid" + appinfo.SECONDARY_COLOR)
                          : "0px solid white"
                    }}
                  >
                    {e.toLocaleUpperCase()}
                  </div>
                </li>
                {index == this.state.hoveringOn && (
                  <div
                    onMouseEnter={() => {
                      this.onChangeState("hoveringOn", index);
                    }}
                    onMouseLeave={() => this.onChangeState("hoveringOn", -1)}
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0 3px 7px 0 rgba(81, 81, 81, 0.2)",
                      position: "absolute",
                      zIndex: 2,
                      width: 180,
                      top: 35,
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "center",
                      cursor: "pointer"
                    }}
                  >
                    {this.renderLevelTwo(payload.search, payload.category_id)}
                  </div>
                )}
              </div>
            );
          })}
        </>
      );
    }
  };

  renderLevelThreeForMobile = (l1, id, action) => {
    const { category } = this.props;
    let arr = category.categories[l1].data.map(l2 => {
      if (l2.hasOwnProperty(id)) {
        return l2;
      }
    });
    let menu = arr.filter(e => e !== undefined);

    if (menu) {
      return (
        <>
          {menu[0][id].map((e, index) => {
            let payload = {
              search: e,
              category_id: action
            };

            return (
              <div
                onClick={() => {
                  this.fromMenu = true;
                  this.level = 3;
                  this.product_name = e;
                  this.onChangeState("hoveringMobileLevelThreeOn", index);
                  this.performSearch(payload);
                  document.getElementById("myModal").style.display = "none";
                }}
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor:
                    index == this.state.hoveringMobileLevelThreeOn
                      ? "#f1f1f1"
                      : "#f9f9f9",
                  color:
                    index == this.state.hoveringMobileLevelThreeOn
                      ? appinfo.SECONDARY_COLOR
                      : "rgba(0, 0, 0, 0.54)",
                  fontSize: 13
                }}
              >
                {e.toLocaleUpperCase()}
              </div>
            );
          })}
        </>
      );
    }
  };

  renderLevelTwoForMobile = () => {
    const { category } = this.props;
    const { hoverState } = this;
    if (this.state.hoveringMobileOn) {

      let menu = category.categories[this.state.hoveringMobileOn].data.map(
        l2 => {
          return Object.keys(l2);
        }
      );
      return (
        <>
          {menu.map((e, index) => {
            let payload = {
              category_id: this.state.action,
              search: e[0]
            };
            return (
              <div>
                <div style={{ paddingTop: 20 }}>
                  <span
                    onClick={() => {
                      this.level = 2;
                      this.fromMenu = true;
                      this.sku_name = e[0];
                      this.performSearch(payload);
                    }}
                  >
                    {e[0].toLocaleUpperCase()}{" "}
                  </span>
                  <i
                    className={
                      this.state.hoveringMobileLevelTwoOn == index
                        ? "up"
                        : "down"
                    }
                    onClick={() => {
                      hoverState["sku_name"] = e[0];
                      this.onChangeState("hoveringMobileLevelTwoOn", index);
                    }}
                  />
                </div>
                {appinfo.menu == 3 && index == this.state.hoveringMobileLevelTwoOn && (
                  <div
                    onClick={() =>
                      this.onChangeState("hoveringMobileLevelTwoOn", index)
                    }
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      zIndex: 2,
                      width: 250,
                      flexDirection: "column",
                      display: "flex",
                      textAlign: "left",
                      cursor: "pointer"
                    }}
                  >
                    <div>
                      {this.renderLevelThreeForMobile(
                        this.state.hoveringMobileOn,
                        payload.search,
                        payload.category_id
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </>
      );
    }
  };

  renderLevelOneForMobile = () => {
    const { category } = this.props;
    const { hoverState } = this;

    let menu = Object.keys(category.categories);
    return (
      <>
        {menu.map((e, index) => {
          let payload = {
            category_id: category.categories[menu[index]].category_id,
            search: e
          };
          return (
            <div style={{ marginTop: 25 }}>
              <span
                onClick={() => {
                  hoverState["category_name"] = e;
                  this.level = 1;
                  this.fromMenu = true;
                  this.category_name = e;
                  this.category_id = payload.category_id;
                  this.performSearch(payload);
                }}
              >
                {e.toLocaleUpperCase()}{" "}
              </span>
              <i
                className="right"
                onClick={() => {
                  hoverState["category_name"] = e;
                  this.category_id = payload.category_id;
                  this.setState({
                    hoveringMobileOn: e,
                    action: payload.category_id,
                    name: e
                  });

                  document.getElementById("myModal").style.display = "block";
                }}
              />
            </div>
          );
        })}
      </>
    );
  };


  render() {
    const { history, category, authentication, cart } = this.props;
    const { header=true, footer=true, menu=true } = this.props;

    let specialities = [];

    if (category.status == "GET_SUCCESS") {
      let menu = Object.keys(category.categories);

      for (let i = 0; i < menu.length && i < 8; i++) {
        specialities[specialities.length] = (
          <div key={i}
            onClick={() => null}
            style={{
              color: "#ffffff",
              fontSize: 13,
              marginTop: 8,
              cursor: "pointer"
            }}
          >
            {menu[i]}
          </div>
        );
      }
      if (menu.length >= 8) {
        specialities[specialities.length] = (
          <div
            onClick={() => null}
            style={{
              color: "#ffffff",
              fontSize: 13,
              marginTop: 8,
              cursor: "pointer"
            }}
          >
            + MORE
          </div>
        );
      }
    }

    return (
      <div style={{ backgroundColor: "#f8f8f8" }}>
        {/* <TeamPopup /> */}
        <div className="header" style={{ backgroundColor:appinfo.HEADER_COLOR}}>
          <div style={{display:'flex',flexDirection:'column'}}>
            <img
              className="logo"
              onClick={() => history.push("/home")}
              src={appinfo.logo}
              alt={appinfo.APP_FOR}
              height="40"
              style={{ cursor: "pointer" }}
            />
            {/* <a href="/deals" style={{fontSize:12,color:appinfo.PRIMARY_COLOR,fontWeight:'bold',paddingTop:8}}>{appinfo.deals}</a> */}
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="search">
              <input
                onChange={event => this.onChangeText(event, "searchQuery")}
                className="search-input"
                placeholder={
                  window.screen.width > 768
                    ? appinfo.search
                    : "Search ..."
                }
                value={this.state.searchQuery}
              />
              <button className="button" style={{backgroundColor:appinfo.PRIMARY_COLOR}}>
                <i className="material-icons md-18 white-icon">search</i>
              </button>
            </div>
          </form>
          {this.state.loggedIn == "true" || authentication.loggedIn ? (
            <div style={{display:"flex",flexDirection:"row"}}>
              <div 
                className="textButton"
                style={{color:appinfo.PRIMARY_COLOR}}
                onClick={
                  () => {
                    history.push("/account");
                  }
                }
              >
                ACCOUNT
              </div>
              <div
                className="textButton"
                style={{color:appinfo.PRIMARY_COLOR}}
                onClick={() => {
                  this.handleLogout();
                }}
              >
                LOGOUT
              </div>
              <button style={{border:"none",backgroundColor:"#FFF",color:appinfo.PRIMARY_COLOR,cursor:"pointer"}} onClick={()=>{
                history.push("/cart","/cartpage");
              }}> 
                <i className="material-icons">shopping_cart</i> 
                <div style={{verticalAlign:"top",marginTop:-32,marginBottom:0,marginRight:-8,marginLeft:24,position:"relative"}}><div style={{verticalAlign:"top",backgroundColor:appinfo.PRIMARY_COLOR,color:'#FFFFFF',fontWeight:900,borderRadius:4,padding:2}}>{cart.partsCount}</div></div>                                
              </button>               
            </div>
          ) : (
            <div style={{display:"flex",flexDirection:"row"}}>
              <div
                className="textButton"
                style={{color:appinfo.PRIMARY_COLOR}}
                onClick={() => {
                  history.push("/login");
                }}
              >
                LOGIN
              </div>
              <div
                className="textButton"
                style={{color:appinfo.PRIMARY_COLOR}}
                onClick={() => {
                  history.push("/signup");
                }}
              >
                SIGNUP
              </div>
              <button style={{border:"none",color:appinfo.PRIMARY_COLOR,cursor:"pointer",backgroundColor:"#FFF"}} onClick={()=>{
                history.push("/cart","/cartpage");
              }}> 
               <i className="material-icons" style={{border:"none",color:appinfo.PRIMARY_COLOR,cursor:"pointer"}}>shopping_cart</i> 
                <div style={{verticalAlign:"top",marginTop:-32,marginBottom:0,marginRight:-8,marginLeft:24,position:"relative"}}><div style={{verticalAlign:"top",backgroundColor:appinfo.PRIMARY_COLOR,color:'#FFFFFF',fontWeight:900,borderRadius:8,padding:2}}>{cart.partsCount}</div></div>                                   
              </button>               
            </div>
          )}
{/* cart */}

        { appinfo.ROLE == 3 && <button className="contact-button" onClick={this.redirectDemo}>
          CONTACT US
        </button>}
        </div>

        {window.screen.width > 1024 ? (
          <div style={{ display : window.screen.width > 1024 && menu ? "block": "none"}}>
          <div  className="nav" >
              <ul>{this.renderLevelOne()}</ul>
          </div>
          </div>
         ) : (
          <div style={{ display : window.screen.width <= 1024 && menu ? "block": "none"}}>
            <div className="nav">
              <div className="dropdown">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {appinfo.terms.category_name}
                  <div className="arrow-down" ><span class="material-icons" style={{margin:"auto"}}>keyboard_arrow_down</span></div>
                </div>
                <div className="dropdown-content">
                  {this.renderLevelOneForMobile()}
                </div>
              </div>
            </div>
          </div>
          )} 

        <div style={{minHeight:360}}>{this.props.children}</div>

        {window.screen.width <= 1024 ? (
          <div id="myModal" className="modal">
            <div className="modal-content">
              <span
                className="close"
                style={{ color: appinfo.SECONDARY_COLOR }}
                onClick={() => {
                  document.getElementById("myModal").style.display = "none";
                }}
              >
                &times;
              </span>
              <div style={{ color: appinfo.SECONDARY_COLOR }}>{this.state.name}</div>
                <div style={{ marginTop: 30 }}>
                  {this.renderLevelTwoForMobile()}
                </div>
            </div>
          </div>
        ) : (
          <div />
        )}

        <div className="footer" style={{backgroundColor:appinfo.FOOTER_COLOR}}>
          <div className="footerTabs">
            <div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  fontWeight: 600,
                  marginBottom: 9
                }}
              >
                { appinfo.CONTENTS.HomePage.specialties.title }
              </div>
              {specialities}
            </div>


            <div className="has-left">
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  fontWeight: 600,
                  marginBottom: 9
                }}
              >
                COMPANY
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => this.redirectTermsPrivacy("/about-us")}
              >
                About Us
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => this.redirectTermsPrivacy("/our-team")}
              >
                Team
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => this.redirectTermsPrivacy("/terms")}
              >
                Terms and Conditions
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => this.redirectTermsPrivacy("/privacy")}
              >
                Privacy Policy
              </div>
              {
                appinfo.CANCELLATION &&
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: 13,
                    marginTop: 8,
                    cursor: "pointer"
                  }}
                  onClick={() => this.redirectTermsPrivacy("/cancellation")}
                >
                  Cancellation
                </div>
              }
              {
                appinfo.REFUND &&
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: 13,
                    marginTop: 8,
                    cursor: "pointer"
                  }}
                  onClick={() => this.redirectTermsPrivacy("/return-and-refund")}
                >
                  Return &amp; Refund Policy
                </div>
              }
              {
                appinfo.SHIPPING &&
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: 13,
                    marginTop: 8,
                    cursor: "pointer"
                  }}
                  onClick={() => this.redirectTermsPrivacy("/shipping")}
                >
                  Shipping Policy
                </div>
              }
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => {
                  window.open(appinfo.BLOG);
                }}
              >
                Blog
              </div>
            </div>

            <div className="has-left">
              <div
                style={{
                  color: "#ffffff",
                  fontSize: 13,
                  fontWeight: 700,
                  marginBottom: 9
                }}
              >
                CONTACT US
              </div>
              <div style={{ color: "#ffffff", fontSize: 13, marginTop: 8 }}>
                {appinfo.address1}
                <br />
                {appinfo.address2}
                <br />
                {appinfo.city}, {appinfo.state} - {appinfo.pincode}
              </div>
              <div style={{ color: "#ffffff", fontSize: 13, marginTop: 20 }}>
                <a
                  href={"tel:"+appinfo.PHONE}
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  {appinfo.PHONE}
                </a>
                <br />
                <a
                  href={"mailto:"+appinfo.EMAIL}
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  {appinfo.EMAIL}
                </a>
              </div>

              <div style={{ marginTop: 27 }}>
                {appinfo.FACEBOOK !== "" && <img
                  src={FacebookImage}
                  width="22"
                  height="22"
                  alt="Facebook"
                  style={{ cursor: "pointer"}}
                  onClick={() =>
                    window.open(
                      appinfo.FACEBOOK
                    )
                  }
                />}
                {appinfo.instagram !== "" && <img
                  src={instagramImage}
                  width="22"
                  height="22"
                  alt="Instagram"
                  style={{ cursor: "pointer" ,marginLeft: 12}}
                  onClick={() =>
                    window.open(
                      appinfo.instagram
                    )
                  }
                />}                
                {appinfo.LINKEDIN !== "" &&<img
                  style={{ marginLeft: 12, cursor: "pointer"}}
                  src={LinkedInImage}
                  width="22"
                  height="22"
                  onClick={() =>
                    window.open(
                      appinfo.LINKEDIN
                    )
                  }
                  alt="LinkedIn"
                />}
              </div>
            </div>
          </div>
        </div>

        <div
          className="copyright"
          style={{
            fontWeight: "600",
            fontSize: 12,
            backgroundColor: "#000000",
            height: 64,
            color: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div> Copyright © 2020 &middot; </div>
          <div>{appinfo.APP_FOR}&nbsp;</div>
          <div> &middot;All Rights Reserved by&nbsp;<a href="https://moonproduct.in" style={{textDecoration:"none",color:"#fff"}}>{appinfo.APP_FOR}</a></div>
        </div>
        {/* <MayIHelpYou /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, category, sku, profile,cart } = state;
  return {
    authentication,
    category,
    sku,
    profile,
    cart
  };
}

export default withRouter(connect(mapStateToProps)(Container));
