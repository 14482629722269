import { demoConstants } from "../constants";

const initialState = {
  results: []
};

export function demo(state = initialState, action) {
  switch (action.type) {
    case demoConstants.DEMO_REQUEST:
      return {
        ...state,
        status: "DEMO_REQUEST"
      };
    case demoConstants.DEMO_SUCCESS:
      return {
        ...state,
        status: "DEMO_SUCCESS",
        results: action.res,
      };
    case demoConstants.DEMO_ERROR:
      return {
        ...state,
        status: "DEMO_ERROR",
        results: action.err
      }
    default:
      return state;
  }
}
