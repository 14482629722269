import { endpointService } from "../services";
import { endpointConstants } from "../constants";

export const endpointActions = {
  hello,
  termsAndConditions,

};

function hello() {
  return dispatch => {
    dispatch(request());
    endpointService
      .hello()
      .then(res => dispatch(success(res)), err => console.log(err));
  };
  function request() {
    return { type: endpointConstants.HELLO_REQUEST };
  }
  function success(res) {
    return { type: endpointConstants.HELLO_SUCCESS, res };
  }
}
function termsAndConditions() {
  return dispatch => {
    dispatch(request());
    endpointService
      .termsAndConditions()
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: endpointConstants.TERMS_CONDITIONS_REQUEST };
  }
  function success(res) {
    return { type: endpointConstants.TERMS_CONDITIONS_SUCCESS, res };
  }
  function error(err) {
   
    return { type: endpointConstants.TERMS_CONDITIONS_ERROR, err };
  }
}
