import { compareConstants } from "../constants";

export const compareActions = {
  addToCompare,
  removeFromCompare,
  removeAllFromCompare
};

function addToCompare(payloadCompare) {
    return {
        type: compareConstants.ADD_COMPARE_SUCCESS,
        payloadCompare
      };
}

function removeFromCompare(inventory_id) {
    return {
        type: compareConstants.REMOVE_COMPARE_SUCCESS,
        inventory_id
      };
}

function removeAllFromCompare(){
  return {
    type: compareConstants.REMOVE_All_COMPARE_SUCCESS
  };
}



