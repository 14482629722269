import { httpClient } from "../utils";

export const categoryService = {
  getAll,
  getMenu
};

function getAll() {
  return httpClient.callApi("GET", `/category`);
}

function getMenu(){
  return httpClient.callApi("GET", `/v2/menu`);
}