import app from "../app.json";

const clientFiles ={
    Nefa:require('./nefa.json'),
    Zepnur:require("./zepnur.json"),
    BikerzModule:require("./bikezModule.json"),
    Testing:require("./testing.json")
}

const appinfo = clientFiles[app.JsonFile];
export default appinfo;  