import React, { Component } from "react";
import { utils } from "../../utils";
import appinfo from "../../clientsJSON";

class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku_name: false,
      category_name: false,
      product_name: false,
      brand: false
    }
    this.filterState = props.initialData;
  }

  componentDidUpdate(prevProps) {
    const newProps = this.props;
    let resetFilters = newProps.resetFilters;
    let hasPropsChanged = newProps !== prevProps;


    if (hasPropsChanged && resetFilters) {
      this.filterState = newProps.initialData;
      this.forceUpdate();
    }
  }

  isChecked = (title, value) => {
    let valueList = [];

    if (this.filterState.hasOwnProperty(title)) {
      valueList = this.filterState[title];

      return valueList.includes(value);
    }

    return false;
  };

  cleanTitle = title => {
    return appinfo.terms[title].toUpperCase();
  };

  updateFilter = (title, key) => {
    const { filterState } = this;
    const { applyFilter } = this.props;
    let values = filterState[title];

    if (values) {
      if (values.includes(key)) {
        values = utils.removeStringFromArray(values, key);
      } else {
        values.push(key);
      }
    } else {
      values = [key];
    }

    if (values.length > 0) {
      this.filterState[title] = values;
    }

    applyFilter(this.filterState);
  };

  renderFilters = (title, filters) => {
    let checked = filters.filter(e => {return true === this.isChecked(title, e.key)});
    let unchecked = filters.filter(e =>  { return false === this.isChecked(title, e.key)});
    let groupFilter = [...checked, ...unchecked]; 

    return groupFilter.map((filter, index) => {
      let label = filter.key;
      let count = filter.doc_count;

      let content =  (
        <div
          style={{
            paddingTop: 7,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <input
            className="filter-checkbox"
            checked={this.isChecked(title, label)}
            onChange={() => this.updateFilter(title, label)}
            type="checkbox"
          />
          <label
            style={{
              paddingLeft: 4,
              fontSize: 12,
              color: "rgba(32, 48, 71, 0.54)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {label} 
          </label>
        </div>
      );

      if( this.state[title] || appinfo.filterLimit > index){
        return content;
      } 
    });
  };

  renderFilterGroups = () => {
    const { data } = this.props;
    let groupTitles = Object.keys(data);

    return groupTitles.map(title => {
      let filters = data[title].buckets;
      if (filters.length <= 1 || (title == "product_name" && appinfo.product_name == 0)) {
        return null;
      }

      return (
        <div
          style={{
            marginLeft: 40,
            paddingBottom: 25,
            borderBottom: "2px solid rgba(0, 0, 0, 0.04)",
            marginBottom: 25
          }}
        >
          <div
            style={{
              fontSize: 11,
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: "600"
            }}
          >
            {this.cleanTitle(title)}
          </div>
          {this.renderFilters(title, filters)}
          { filters.length > appinfo.filterLimit &&
            <div style={{marginTop: 8, 
                        fontSize: 12, 
                        color: appinfo.SECONDARY_COLOR,
                        cursor: "pointer"}}
                  onClick={()=> this.setState({ [title] : !this.state[title]})}>
              { !this.state[title] ? "Show More" : "Show Less" }
            </div>
          }
        </div>
      );
    });
  };

  render() {
    const { hideFilters } = this.props;
    let style = { flex: 1, paddingTop: 80, width: 100 };

    if (hideFilters) {
      style = {
        ...style,
        display: "none"
      };
    }

    return (
      <div className="filter" style={style}>
        {this.renderFilterGroups()}
      </div>
    );
  }
}

FilterList.defaultProps = {
  initialData: {}
};

export default FilterList;
