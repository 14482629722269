import React from "react";
import appinfo from "./clientsJSON";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
import RootLoader from "./screens/root_loader";
import Home from "./screens/home";
import Search from "./screens/search";
import Product from "./screens/product";
import Credentials from "./screens/login/credentials.js";
import MobileOtp from "./screens/login/mobile_otp.js";
import Account from "./screens/account";
import Otp from "./screens/login/otp.js";
import Demo from "./screens/demo";
import Terms from "./screens/policy/terms";
import Privacy from "./screens/policy/privacy";
import Success from "./screens/demo/success.js";
import BrokenPage from "./components/broken_page";
import Deals from "./screens/deals";
import Bundle from "./screens/bundle";
import Cartpage from"./screens/Cartpage";
import Checkoutpage from "./screens/Checkout";
import Signup from "./screens/signup";
import Verify from "./screens/verify";
import Compare from "./screens/compare";
import Aboutus from "./screens/About-us";
import Policy from "./screens/privacy_policy";
import TermsAndConditions from "./screens/terms_and_conditions";
import Team from "./screens/Team";
import AddShipping from "./screens/shippingaddress/addshipping"
import {Helmet} from "react-helmet";
import Cancellation from "./screens/policy/cancellation";
import Shipping from "./screens/policy/shipping";
import Refund from "./screens/policy/refund";


const App = ({ store }) => (
  <div>
    <Helmet>
      <title>{appinfo.APP_FOR}</title>
      <meta name="description" content={appinfo.TITLE}/>
    </Helmet>    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)} >
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={RootLoader} />
            <Route path="/home" component={Home} />
            <Route path={`/product/:id`} component={Product} />
            <Route path={`/b/:id`} component={Bundle}/>
            <Route path="/search" component={Search} />
            {appinfo.Reseller_Zone&& <Route path="/credentials" component={Credentials} />}
            {appinfo.Reseller_Zone&& <Route path="/login" component={MobileOtp} />}
            {appinfo.Reseller_Zone&& <Route path="/account" component={Account} />}
            {appinfo.Reseller_Zone&&  <Route path="/otp" component={Otp} />}
            <Route path="/submitform" component={Demo} />
            <Route path="/success-contactus" component={Success} />
            <Route path="/success-product" component={Success} />
            <Route path="/success-deal" component={Success} />
            <Route path="/success-bundle" component={Success} />
            <Route path="/success-signup" component={Success} />
            <Route path="/success-audit" component={Success} />
            <Route path="/success" component={Success} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/shipping" component={Shipping} />
            <Route path="/cancellation" component={Cancellation} />
            <Route path="/return-and-refund" component={Refund} />
            <Route path="/deals" component={Deals} />
            {appinfo.Reseller_Zone&& <Route path="/cart" component={Cartpage} />}
            {appinfo.Reseller_Zone&&  <Route path="/checkout" component={Checkoutpage} />}
            {appinfo.Reseller_Zone&&  <Route path="/signup" component={Signup}/>}
            {appinfo.Reseller_Zone&&  <Route path="/verify" component={Verify}/>  } 
            <Route path="/compare" component={Compare}/>  
            <Route path="/about-us" component={Aboutus} />   
            <Route path="/terms-and-conditions" component={TermsAndConditions}/> 
            <Route path="/our-team" component={Team}/>
            <Route path="/privacy-policy" component={Policy}/>
            <Route path="/add-shipping-address" component={AddShipping}/>
            <Route  component={BrokenPage} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </div>
);

export default App;
