import { signupConstants } from "../constants";
import { signupService } from "../services";

export const signupActions = {
    signupAuto,
    otpVerify
}

function otpVerify(payload) {
    return dispatch => {
      dispatch(request());
      signupService.otpVerify(payload).then(
        message => {
          dispatch(success(message));
        },
        error => {
          dispatch(failure(error));
        }
      );
    };
    function request() {
      return { type: signupConstants.OTPSIGNUP_REQUEST };
    }
    function success(message) {
      return { type: signupConstants.OTPSIGNUP_SUCCESS, message };
    }
    function failure(error) {
      return { type: signupConstants.OTPSIGNUP_ERROR, error };
    }
  }

  function signupAuto(payload) {
    return dispatch => {
      dispatch(request());
      signupService.signupAuto(payload).then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        }
      );
    };
    function request() {
      return { type: signupConstants.SIGNUP_REQUEST };
    }
    function success(otp) {
      return { type: signupConstants.SIGNUP_SUCCESS, otp };
    }
    function failure(error) {
      return { type: signupConstants.SIGNUP_ERROR, error };
    }
  }