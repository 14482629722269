import React, {Component} from "react";
import {connect} from "react-redux";
import Container from "../container";
import appinfo from  "../../clientsJSON";
import Cookies from "universal-cookie";
import { utils } from "../../utils";
import { withRouter } from "react-router-dom";
import "./index.css"
import notyf from "../../utils/notification";
import { shippingActions } from "../../actions";


class AddShipping extends Component {
    constructor(props){
        super(props);
        const cookie =new Cookies();

        this.state = {
            loggedIn: cookie.get("loggedIn"),
            Name:"",
            mobileno:"",
            address:"",
            city:"",
            state:"",
            country:"",
            type:"",
            pincode:""
    }
}

handleChange = (event) => {
    this.setState({ [event.target.name] : event.target.value })
  }
onSubmit=()=>{
    const {dispatch} =this.props;
    if((this.state.Name&& this.state.address && this.state.state && this.state.moibileno&& this.state.city&&this.state.pincode) !==""){
    let payload={
        "name":this.state.Name,
        "mobile_no":this.state.mobileno,
        "street_1":this.state.address,
        "street_2":"",
        "city":this.state.city,
        "state":this.state.state,
        "country":this.state.country,
        "type":this.state.type,
        "pincode":this.state.pincode
    }
    dispatch(shippingActions.addAddress(payload))
   }
   else notyf.open({type:"error",message:"please fill the shipping details"})
  }

componentDidUpdate(prevProps){
    const {shipping,history}=this.props;
    let ShippingPropsChanged =shipping.status!==prevProps.shipping.status;

    if(ShippingPropsChanged){
        if(shipping.status==="ADD_ADDRESS_SUCCESS"){
            notyf.open({type:"success",message:"Address has been added successfully"})
            history.push("/checkout")
        }
        if(shipping.status==="ADD_ADDRESS_ERROR"){
            notyf.open({type:"error",message:shipping.error.message[0]})
        }
    }
}


render(){
    const {history} =this.props;
    return(
        <Container>
            <div className="container">
               <h1 >Add Your Shipping Address</h1>
               <p>Please enter your shipping details.</p>
               <div className="form">
                  <div className="fields">
                     <label className="field">
                     <span className="field__label">Name</span>
                     <input className="field__input" name="Name" type="text" value={this.state.Name} onChange={this.handleChange} required />
                     </label>
                   </div>
                <label className="field">
                 <span className="field__label" >Mobile no</span>
                 <input className="field__input" name="mobileno" type="number" value={this.state.mobileno} onChange={this.handleChange} required />
               </label>
              <label className="field">
               <span className="field__label">Address</span>
               <input className="field__input" name="address" type="text" value={this.state.address} onChange={this.handleChange}required/>
             </label>
           <label className="field">
             <span className="field__label">City</span>
               <input className="field__input" name="city"type="text" value={this.state.city} onChange={this.handleChange} required/>
          </label>
          <label className="field">
              <span className="field__label">State</span>
               <input className="field__input" name="state" type="text" value={this.state.state} onChange={this.handleChange} required/>
         </label>
         <label className="field">
            <span className="field__label">Country</span>
             <input className="field__input"name="country" type="text" value={this.state.country} onChange={this.handleChange} required/>
         </label>
         <label className="field">
           <span className="field__label" >Select your type Home/Office</span>
          <select className="field__input" name="type" value={this.state.type} onChange={this.handleChange} required>
               <option value="">-</option>
               <option>Home</option>
               <option>Office</option>
          </select>
        </label>
        <label className="field">
            <span className="field__label" >Pincode</span>
             <input className="field__input" name="pincode" type="text"  value={this.state.pincode} onChange={this.handleChange} required/>
       </label>
   </div>
    <button className="fieldbutton" onClick={()=>this.onSubmit()} style={{marginBottom:20,marginTop:10,backgroundColor:appinfo.PRIMARY_COLOR}}>
    Add Your shipping Address&nbsp;
    <i class="material-icons" size={20} style={{verticalAlign:"middle"}}>arrow_forward</i>
    </button>
    <button className="fieldbutton" onClick={()=>history.push("/checkout")} style={{marginBottom:20,marginTop:10,backgroundColor:appinfo.PRIMARY_COLOR}}>
     <i class="material-icons" size={20} style={{verticalAlign:"middle"}}>arrow_back</i>&nbsp;Back to Checkout
    </button>
      </div>
  </Container>
    )
}
}

function mapStateToProps(state){
    const {shipping} =state;
     return { shipping };
}

export default withRouter(connect(mapStateToProps)(AddShipping));