import { skudocConstants } from "../constants";
import { skudocService } from "../services";

export const skudocActions = {
  getDocDetails
};

function getDocDetails(inventory_id) {
  return dispatch => {
    skudocService.getDocDetails(inventory_id).then(res => {
      let docs = res.docs;
      let newResponse = { docs: []};

      docs.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      newResponse["docs"] = docs;
      
      dispatch(success(newResponse));
    });
  };
  function success(res) {
    return { type: skudocConstants.DOCDETAILS_SUCCESS, res };
  }
}
