import React, { Component } from "react";
import marked from "marked";
import "../styles.css";
import appinfo from "../../clientsJSON";

class ShowMore extends Component {
  state = {
    expand: false,
    doNothing: false
  };

  componentDidMount() {
    var div = document.getElementsByClassName("content")[0];

    if (div.offsetHeight < 220) {
      this.setState({ doNothing: true });
    }
  }

  render() {
    const { expand, doNothing } = this.state;
    const { md } = this.props;
    var renderer = new marked.Renderer();

    renderer.list = body => {
      return `<ul style="padding-left: 1.2em;">${body}</ul>`;
    };

    const html = marked(md, { renderer });
    var div = document.createElement("div");

    div.innerHTML = html.trim();
    return (
      <div
        className="content product-description"
        style={{
          lineHeight: 1.5,
          maxHeight: expand || doNothing ? 9999 : 220,
          overflow: "hidden",
          position: "relative"
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: div.innerHTML }} />
        {!expand &&
          !doNothing && (
            <>
              <p
                onClick={() => this.setState({ expand: true })}
                className="read-more"
              >
                <div
                  style={{
                    cursor: "pointer",
                    color: appinfo.SECONDARY_COLOR,
                    fontSize: "14px",
                    fontWeight: "600",
                    verticalAlign: "bottom",
                    backgroundColor:"#f9f9f9",
                    padding:"2%"
                  }}
                >
                  Show More
                </div>
              </p>
            </>
          )}
        {expand &&
          !doNothing && (
            <div
              style={{
                cursor: "pointer",
                color: appinfo.SECONDARY_COLOR,
                fontSize: "14px",
                fontWeight: "600"
              }}
              onClick={() => this.setState({ expand: false })}
            >
              Show Less
            </div>
          )}
      </div>
    );
  }
}

ShowMore.defaultProps = {
  md: ""
};

export default ShowMore;
