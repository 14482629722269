import { profileService } from "../services";
import { profileConstants } from "../constants";

export const profileActions = {
  whoami,
  getTenantDetails,
  updateUserDetails
};

function whoami() {
  return dispatch => {
    dispatch(request());
    profileService
      .whoami()
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };
  function request() {
    return { type: profileConstants.PROFILE_REQUEST };
  }
  function success(res) {
    return { type: profileConstants.PROFILE_SUCCESS, res };
  }
  function error(err){
      return { type: profileConstants.PROFILE_ERROR, err};
  }
}

function getTenantDetails(tenant_id) {
  return dispatch => {
    dispatch(request());
    profileService.getTenantDetails(tenant_id).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: profileConstants.TENANT_GET_REQUEST };
  }
  function success(user) {
    return { type: profileConstants.TENANT_GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: profileConstants.TENANT_GET_FAILURE, error };
  }
}

function updateUserDetails(payload) {
  return dispatch => {
    dispatch(request());
    profileService.updateUserDetails(payload).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: profileConstants.PROFILE_UPDATE_REQUEST };
  }
  function success(user) {
    return { type: profileConstants.PROFILE_UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: profileConstants.PROFILE_UPDATE_FAILURE, error };
  }
}
