import React, { Component } from "react";
import "./product.css";
import appinfo from "../../clientsJSON";
import { NONAME } from "dns";

class ProductPageLoader extends Component {
  render() {
        return (
            <div className="body">
            <div>
              <div className="imagesdata">
              </div>
            </div>
            <div className="body-info">
             <div style={{display: "flex", flexDirection: "column"}}>
              <div className="product-title-data">
              </div>
              <div className="product-subtitle-data">
              </div>
              <div className="product-description-data" style={{ whiteSpace: "pre-wrap" }}>
              </div>
              <div className="product-subtitle-data" style={{ fontSize: 12 }}>
              </div>
              <div className="product-price-data">
              </div>
            </div>
              <hr className="line" />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  onClick={this.requestRedirect}
                  className="demo-button"
                  style={{
                    marginTop: 25,
                    backgroundColor: appinfo.PRIMARY_COLOR,
                    color: "#ffffff",
                    fontWeight: 600,
                    border:"none"
                  }}
                >
                  REQUEST FOR QUOTE
                </button>
              </div>
            </div>
          </div>
        );
  }
}

export default ProductPageLoader;