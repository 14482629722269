import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container";
import appinfo from "../../clientsJSON";
import Cookies from "universal-cookie";
import { Loader } from "../../components";
import { checkoutActions, cartActions } from "../../actions";
import { withRouter } from "react-router-dom";
import { utils } from "../../utils";
import { shippingActions,discountActions } from "../../actions";
import notyf from "../../utils/notification";
class Checkoutpage extends Component {
    constructor() {
        super();
        const cookie = new Cookies();

        this.state = {
            loggedIn: cookie.get("loggedIn"),
            error: false,
            payment_id: "",
            success:false,
            shipping_id:"",
            totalAmount:"",
            totalMRP:"",
            coupon_code:"",
            couponapplied:false,
            processing:false

        };
    }

// All type of function are here
    changeAmount(e) {
        this.setState({ amount: e.target.value })
    }

    handleChange = (event) => {
        this.setState({shipping_id : event })
      }

    couponChange = (e) => {
        this.setState({coupon_code : e.target.value })
      }
  
    OnApplyCoupon=()=>{
        const { dispatch, cart} = this.props;
        let payload=[] ;
        let code=this.state.coupon_code
    if(code !=="")
    {
        for (let i = 0; i < cart.partsCount; i++)
        {
            payload [i]={
                "product_id":cart.inventories[i].product_id,
                "amount":cart.inventories[i].recent_selling_price}
        }
      dispatch(discountActions.getDiscountAmount(code,payload));
      this.setState({processing:true})
   }
   }

    onDeleteShipping=(id)=>{
        const {dispatch}=this.props;
        dispatch(shippingActions.deleteAddress(id))
    }

    createOrder = () => {
        const { dispatch, cart } = this.props;
        let payload = {};

        if (this.state.loggedIn == "true"&& this.state.shipping_id !== "") {
            payload["orders"] = [];
            payload["shipping_id"] = this.state.shipping_id;
            payload["points_to_redeem"] = 0;
            if (this.state.coupon_code!=="") {payload["discount_coupon_id"]=this.state.coupon_code;}
            // payload["payment_mode"] = "IB";
            for (let i = 0; i < cart.partsCount; i++) {
                payload["orders"][i] = {
                    inventory_id: cart.inventories[i].inventory_id,
                    quantity: cart.inventories[i].count
                }

            }
            dispatch(checkoutActions.getquotation(payload));
        }
        else notyf.open({type:"error",message:"If you proceed next,Please choose any one of your shipping address"})
    }

    createQuote = () => {
        const { dispatch, cart } = this.props;
        let payload = {};
        let type=appinfo.ROLE;
        if (this.state.loggedIn == "true") {
            payload["quotation"] = [];
            for (let i = 0; i < cart.partsCount; i++) {
                payload["quotation"][i] = {
                    inventory_id: cart.inventories[i].inventory_id,
                    qty: cart.inventories[i].count
                }
            }           
            dispatch(checkoutActions.getquotation(payload,type));
        }
    } 

    clearCart = () => {
        const {dispatch,history}=this.props;
        dispatch(cartActions.removeAll());
        history.push("/home")
    } 
//End Of Functions
  //Components Are Here
    componentDidMount() {
        const { dispatch} = this.props;
        dispatch(shippingActions.getAll());   
    } 

    componentWillReceiveProps(newProps) {
        const { dispatch, cart, shipping, checkout, history, profile,discount } = newProps;
        if(shipping.status==="GET_ALL_SUCCESS"){
            let total=0;
            let MRP=0;
            let tax=0;
            for (let i = 0; i < cart.partsCount; i++) {
                // tax = tax + ((cart.inventories[i].recent_selling_price * cart.inventories[i].count*cart.inventories[i].tax)/100);
                total = total + (cart.inventories[i].recent_selling_price * cart.inventories[i].count) ;
                MRP = MRP + (cart.inventories[i].msrp_price * cart.inventories[i].count);
            }
            this.setState({totalAmount:total})
            this.setState({totalMRP:MRP})
            dispatch(shippingActions.getAmount(total))
        }
        if(shipping.status==="GET_AMOUNT_SUCCESS"&& discount.status!=="COUPON_APPLY_SUCCESS"){
          let amount=(shipping.amount.amount+this.state.totalAmount);
          this.setState({totalAmount:amount})
        }
        if(discount.status==="COUPON_APPLY_SUCCESS"&&this.state.processing){
            let discountamount=discount.result.discount_amount.discount;
            let totalamount=this.state.totalAmount
            let total=(totalamount - discountamount);
           this.setState({totalAmount:total,couponapplied:true,processing:false})
        }
        if (
            checkout.status === "GET_QUOTATION_SUCCESS" &&
            checkout.status !== this.props.checkout.status &&
            appinfo.ROLE != 3
        ) {
            let total = this.state.totalAmount;
            // for (let i = 0; i < cart.partsCount; i++) {
            //     total = total + cart.inventories[i].recent_selling_price * cart.inventories[i].count
            // }
            let options = {
                "key": appinfo.ENDPOINTS.KEY,
                "amount": total * 100, // 2000 paise = INR 20, amount in paisa
                // "description": "Purchase Description",
                "order_id": checkout.result.order.transaction_id,
                "handler": function (response) {
                    // alert(response.razorpay_payment_id);
                    history.push("/account");
                    dispatch(cartActions.removeAll());
                },
                "prefill": {
                    "name": profile.about.myself.name,
                    "email": profile.about.myself.email,
                    "contact": profile.about.myself.mobile,
                },
                "notes": {
                    "address": shipping.result.address[0].pincode
                },
                "theme": {
                    "color": "#F37254"
                }
            };

            let rzp = new window.Razorpay(options);
            rzp.open()
            // dispatch(cartActions.removeAll());
            // history.push("/account");  
        }
        else if (checkout.status === "GET_QUOTATION_SUCCESS" && checkout.status !== this.props.checkout.status) {
            this.setState({ success: true });
        }
        else if (checkout.status === "GET_QUOTATION_ERROR" && checkout.status !== this.props.checkout.status) {
            this.setState({ error: true })
        }
    }

//End Of Components
    render() {
        const { dispatch, cart, shipping,discount } = this.props;
        let total=0;
        // let tax=0;
        // let inventories=[];
        for (let i = 0; i < cart.partsCount; i++) {
            // tax = tax + ((cart.inventories[i].recent_selling_price * cart.inventories[i].count*cart.inventories[i].tax)/100);
            // total = total + (cart.inventories[i].recent_selling_price * cart.inventories[i].count - ((cart.inventories[i].recent_selling_price * cart.inventories[i].count*cart.inventories[i].tax)/100))
            // total = total + (cart.inventories[i].recent_selling_price * cart.inventories[i].count/(1 + (cart.inventories[i].count*cart.inventories[i].tax/100)))
            total = total + (cart.inventories[i].recent_selling_price * cart.inventories[i].count)  
        }
      
        return (
            <Container>
                {appinfo.ROLE != 3 &&<h2 style={{ textAlign: 'center' }}>Order Creation</h2>}
                {appinfo.ROLE == 3 &&<h2 style={{ textAlign: 'center' }}>Quotation Creation</h2>}
                <div>
                <div style={{
                    position: "relative",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingTop: 2,
                    marginBottom:10,
                    paddingBottom: 2,
                    width: "70%", height: "auto", backgroundColor: "#ffffff"
                }}>
                    <div style={{ padding: 8 }}>
                        {cart.partsCount > 0 &&
                            <div>
                                {/* start of B2B quotation */}
                                {(appinfo.ROLE == 3) && <div><p style={{ textAlign: 'center' }}>By clicking REQUEST QUOTE, you are confirming that you're asking quotation for {cart.partsCount} items added in the cart</p>
                                <p style={{ textAlign: 'center' }}>
                                {(!this.state.success) &&
                                    <button style={{
                                        borderRadius: 40,
                                        backgroundColor: appinfo.PRIMARY_COLOR,
                                        color: 'white',
                                        padding: 8,
                                        textAlign: "center",
                                        cursor:"pointer"
                                }} onClick={() => this.createQuote()} >REQUEST QUOTE</button>} </p>       
                                </div>
                                }
                                {/* end of B2B quotation */}
                                {/* start of eCommerce flow */}
                                {(appinfo.ROLE != 3) && <div><p style={{ textAlign: 'center' }}>Place order for {cart.partsCount} items added in the cart</p>
                                <p><strong>Shipping Address:</strong></p>
                                <p>Choose your shipping address here</p>
                                {shipping.status == "GET_ALL_REQUEST"&& <Loader/>}
                                {typeof shipping.result.address != "undefined"
                                    && <div>{shipping.result.address.map(i=>{
                                        return(
                                       <div>
                                           <input type="radio" name="shipping_id" value={this.state.shipping_id} onChange={e=>this.handleChange(i.shipping_id)}></input>&nbsp;
                                           <label >
                                           <strong>{i.type} address</strong>
                                        
                                       
                                       <div>
                                        <p> <strong>{i.name}</strong></p> 
                                       <p>{i.street_1},</p>
                                       <p>{i.city},{i.state},{i.country}-{i.pincode}.</p>
                                        <p>Contact Number:{i.mobile_no}</p>
                                        </div>
                                        {/* <div style={{ display:"flex",justifyContent: "flex-end"}}> */}
                                        {/* <div>
                                           <button style={{ borderRadius: 10,  backgroundColor: appinfo.PRIMARY_COLOR,  color: 'white',padding: 8,border:"none",textAlign: "center",cursor:"pointer"}}>
                                            <i class="material-icons"  style={{verticalAlign:"middle",fontSize:15}}>edit</i>&nbsp;Edit  Shipping Address</button>
                                        </div> */}
                                        {/* <div>
                                        <button 
                                        onClick={()=>this.onDeleteShipping(i.shipping_id)}
                                        style={{ borderRadius: 10,  backgroundColor: appinfo.PRIMARY_COLOR,  color: 'white',padding: 8,border:"none", textAlign: "center",cursor:"pointer"}}>
                                            <i class="material-icons" size={14} style={{verticalAlign:"middle",fontSize:15}}>delete</i>&nbsp;Delete Shipping Address</button>
                                        </div> */}
                                        {/* </div> */}
                                        
                                        <hr></hr>
                                           </label>
                                        </div>)})}
                                   <div> <a href="/add-shipping-address"  style={{fontSize:15,textDecoration:"none",color:appinfo.PRIMARY_COLOR}}>Add your Shipping Address</a></div>
                                    </div>}
                                {/* <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}><p><strong>Amount</strong></p></div>
                                    <div style={{width:"10%",flexDirection:"row"}}><p>&#8377;{utils.fixtwodecimal(total)}</p></div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}><p><strong>GST</strong></p></div>
                                    <div style={{width:"10%",flexDirection:"row"}}><p>&#8377;{utils.fixtwodecimal(tax)}</p></div>
                                </div> */}
                                <p><strong>Order Summary</strong></p> 
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}>
                                        <p><strong>Subtotal</strong></p>
                                        <a href="/cart" style={{fontSize:12,textDecoration:"none",color:appinfo.PRIMARY_COLOR}}>Change quantity or add items or delete items</a>
                                    </div>
                            <div style={{width:"10%",flexDirection:"row"}}><p> ₹{utils.fixtwodecimal(this.state.totalMRP)}</p></div>
                                </div> 
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}>
                                        <p><strong>Price Discount</strong></p>
                                    </div>
                            <div style={{width:"10%",flexDirection:"row"}}><p>-₹{utils.fixtwodecimal(this.state.totalMRP-total)}</p></div>
                                </div> 
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}><p><strong>Shipping Charge</strong></p>
                                    {typeof shipping.amount !=="undefined" && <p style={{color:"red"}}>{shipping.amount.description}</p>}
                                    </div>
                                 {typeof shipping.amount !=="undefined" && <div style={{width:"10%",flexDirection:"row"}}><p>&#8377;{shipping.amount.amount}</p></div>}
                                </div>  
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}>
                                    <p><strong>Apply Coupon Here</strong></p>
                                     
                                    {!this.state.couponapplied &&
                                    <div>
                                     <input name="coupon_code" type="text" placeholder="Enter Code" onChange={this.couponChange} value={this.state.coupon_code}>
                                        </input><label>&nbsp;<button onClick={()=>this.OnApplyCoupon()}>Apply</button></label>
                                    </div>}
                                    {discount.status==="COUPON_APPLY_SUCCESS" && 
                                    <p style={{color:"#090"}}><i class="material-icons">verified</i>&nbsp;Coupon applied</p>}
                                    {discount.status==="COUPON_APPLY_ERROR" && 
                                    <p style={{color:"#c40000"}}><i class="material-icons">priority_high</i>&nbsp;Your Coupon code not valid</p>}
                                    </div>
                                    {typeof discount.result.discount_amount!=="undefined" && <div style={{width:"10%",flexDirection:"row"}}><p>-₹{discount.result.discount_amount.discount}</p></div>}
                                </div>
                                <hr></hr>                                 
                                <div style={{display:"flex"}}>
                                    <div style ={{width:"80%"}}><p><strong>Amount Payable</strong></p>
                                    {typeof discount.result.discount_amount!=="undefined" && <p  style={{color:"#090"}}>Total Savings: ₹{utils.fixtwodecimal(this.state.totalMRP-total) + utils.fixtwodecimal(discount.result.discount_amount.discount)}
                                    
                                    <p>
                                        <li>Price discount</li>
                                        <li>Coupon discount</li>
                                        <li>Inclusive of all type of taxes</li>
                                    </p></p>}
                                    </div>
                                    <div style={{width:"10%",flexDirection:"row"}}><p>&#8377;{utils.fixtwodecimal(this.state.totalAmount)}</p></div>
                                </div>
                                <hr></hr>                                     
                                <p style={{ textAlign: 'center' }}>
                                    <button style={{
                                        borderRadius: 40,
                                        backgroundColor: appinfo.PRIMARY_COLOR,
                                        color: 'white',
                                        padding: 8,
                                        border:"none",
                                        textAlign: "center",
                                        cursor:"pointer"
                                    }} onClick={() => this.createOrder()} >PLACE YOUR ORDER </button> </p></div>}
                                 {/* error eCommerce flow */}                                   
                                {(this.state.error) && <p style={{ textAlign: 'center', color: 'red' }}>Something went wrong</p>}
                                 {/* success eCommerce flow */}                                  
                                {(this.state.success) && <p style={{ textAlign: 'center', color: 'green' }}>Qutation sent successfully.
                                <button style={{
                                        borderRadius: 40,
                                        backgroundColor: appinfo.PRIMARY_COLOR,
                                        color: 'white',
                                        padding: 8,
                                        textAlign: "center",    
                                        cursor:"pointer"
                                    }} onClick={() => this.clearCart()} >GOTO HOME</button>
                                </p>}
                                {/* end */} 
                            </div>
                        }
                        {cart.partsCount == 0 &&
                            <div>
                                <p style={{ textAlign: 'center' }}>Please add items to the cart</p>
                                <div style={{ display:"flex",justifyContent: "center"}}>
                               <a href="/home" style={{textDecoration:"none"}}>
                               <button style={{ borderRadius: 10,  backgroundColor: appinfo.PRIMARY_COLOR,  color: 'white',padding: 8,border:"none",textAlign: "center",cursor:"pointer"}}>
                                 Add Items To Cart&nbsp;<i class="material-icons"  style={{verticalAlign:"middle",fontSize:15}}>add_shopping_cart</i> </button>
                                 </a>
                                 </div>
                            </div>}
                    </div>
                </div>
                {cart.partsCount > 0 && <p style={{ textAlign: 'center',color:"#888",fontSize:12 }}>
                  By clicking on the 'Place Your Order' button, you agree to {appinfo.APP_FOR} <a href="/privacy-policy"  style={{textDecoration:"none",color:appinfo.PRIMARY_COLOR}}>privacy notice</a> and <a href="/terms-and-conditions"  style={{textDecoration:"none",color:appinfo.PRIMARY_COLOR}}>conditions of use</a>.</p>}
            </div>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    const { cart, inventory, shipping, checkout, profile,discount } = state;
    return {
        cart,
        inventory,
        shipping,
        checkout,
        profile,
        discount
    };
}
export default withRouter(connect(mapStateToProps)(Checkoutpage));
//All licensed Reserved By MoonProduct.in