import React from "react";
import { connect } from "react-redux";
import { endpointActions, profileActions } from "../../actions";
import HomePageLoader from "../../components/homepage_loader";

class RootLoader extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(endpointActions.hello());
  }

  getCache = () => {
    const { history } = this.props;

    history.push("/home");
  };

  componentDidUpdate(prevProps) {

    let newProps = this.props;
    let propsChanged = prevProps.endpoint.status !== newProps.endpoint.status;


    if (newProps.endpoint.status === "HELLO_SUCCESS" && propsChanged) {
      //newProps.dispatch(userActions.login("sciencevivek", "xOl4kZ"));
      this.getCache();
    }
    // if (
    //   newProps.authentication.loggedIn &&
    //   !prevProps.authentication.loggedIn
    // ) {
    //   this.getCache();
    // }
  }

  render() {
    return <HomePageLoader />;
  }
}

function mapStateToProps(state) {
  const { endpoint, authentication, profile } = state;
  return {
    authentication,
    endpoint, 
    profile
  };
}

export default connect(mapStateToProps)(RootLoader);
