import { categoryConstants } from "../constants";
import { skuActions } from "./";
import { categoryService } from "../services";
import { httpError } from "../utils";

export const categoryActions = {
  getAll,
  getMenu
};

function getAll() {
  return dispatch => {
    dispatch(request());
    categoryService.getAll().then(
      res => {
        let categoryList = res.categories.filter(e => {
          return e.status != 0;
        });

        for (let i = 0; i < categoryList.length; i++) {
          dispatch(skuActions.getByCategoryId(categoryList[i].category_id));
        }

        setTimeout(() => {
          dispatch(success(categoryList));
        }, 2000);
      },
      err => dispatch(error(err))
    );
  };

  function request() {
    return { type: categoryConstants.GETALL_REQUEST };
  }
  function success(res) {
    return { type: categoryConstants.GETALL_SUCCESS, res };
  }
  function error(err) {
    return { type: categoryConstants.GETALL_ERROR, err };
  }
}

function getMenu() {
  return dispatch => {
    dispatch(request());
    categoryService
      .getMenu()
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };
  function request() {
    return { type: categoryConstants.GET_REQUEST };
  }
  function success(res) {
    return { type: categoryConstants.GET_SUCCESS, res };
  }
  function error(err) {
    return { type: categoryConstants.GET_ERROR, err };
  }
}