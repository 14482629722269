import React, { Component } from "react";

class SearchLoader extends Component {
  render() {
    let data =[];
    for(let i=0; i<6; i++){
    data[i] = (
        <div style={{marginLeft: "3%"}}>
          <div style={styles.container}>
          </div>
          <div
            style={{
              paddingTop: 13,
              marginTop: 5,
              backgroundColor: "#EDEDED",
              width: 165,
              height: 10,
            }}
          >
          </div>
          <div
            style={{
              paddingTop: 5,
              fontSize: 12,
              marginTop: 5,
              backgroundColor: "#EDEDED",
              width: 100,
              height: 10
            }}
          >
          </div>
          <div
            style={{
              paddingTop: 5,
              fontSize: 12,
              marginTop: 5,
              backgroundColor: "#EDEDED",
              width: 165,
              height: 10,
            }}
          >
          </div>
        </div>
      );
    }
    return (
        <div style={{display : "flex", flexWrap: "wrap"}}>
         {data}
         {data}
        </div>
    );
  }
}

const styles = {
  container: {
    height: 165,
    flex: 1,
    backgroundColor: "#EDEDED",
    marginTop: 19,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0 7px 0 rgba(210, 210, 210, 0.3)",
    cursor: "pointer",
    width: 165,
  }
};

export default SearchLoader;