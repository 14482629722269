import { catalogConstants } from "../constants";
import { catalogService } from "../services";

export const catalogActions = {
  getInventoryCatalog
};

function getInventoryCatalog(inventory_id) {
  return dispatch => {
    dispatch(request());
    catalogService.getInventoryCatalog(inventory_id)
    .then(res =>  dispatch(success(res)), err => dispatch(error(err)));
  };

  function request(){
    return { type: catalogConstants.GETI_CATALOG_REQUEST};
  }
  function success(res) {
    return { type: catalogConstants.GETI_CATALOG_SUCCESS, res };
  }
  function error(err) {
    return { type: catalogConstants.GETI_CATALOG_ERROR, err};
  }
}
