import React, { Component } from "react";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils";
import "../styles.css";

class CollapsableList extends Component {

    renderMakePayment = () => {
        return (
            <div style={{paddingLeft: 20, paddingTop: 10, paddingBottom: 10, 
                        display: "flex", flexDirection: "row"}}>
                <i className="material-icons red">warning</i> 
                <div style={{ fontSize: 16, 
                                fontFamily: "OpenSans", 
                                color: "red"}}>
                    You have not done payment for this order   
                </div>
            </div>
        );
    }

    renderTracker = (status) => { 
        let outOfRange = status > 159 ? true : false;
        let notConfirmed =  status < 151 || outOfRange ? true : false ;
        let notPacked = status < 157 || outOfRange ? true : false ;
        let notShipped = status < 158 || outOfRange ? true : false ;
        let notDelivered = status < 159 || outOfRange ? true : false ;
        return (
            <div style={{display: "flex", flexDirection: "row", 
            marginLeft: "5%", marginRight: "5%"}}>
                <div style={{
                                width: 11, 
                                height: 11, 
                                borderRadius: 6, 
                                backgroundColor: notConfirmed ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{
                                marginTop: 5,
                                height: 2,
                                width: 620 / 3,
                                backgroundColor: notConfirmed ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{   width: 11, 
                                height: 11, 
                                borderRadius: 6, 
                                backgroundColor: notPacked ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{
                                marginTop: 5,
                                height: 2,
                                width: 620 / 3,
                                backgroundColor: notPacked ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{   width: 11, 
                                height: 11, 
                                borderRadius: 6, 
                                backgroundColor: notShipped ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{
                                marginTop: 5,
                                height: 2,
                                width: 620 / 3,
                                backgroundColor: notShipped? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
                <div style={{   width: 11, 
                                height: 11, 
                                borderRadius: 6, 
                                backgroundColor: notDelivered ? "#cdcdcd" : appinfo.PRIMARY_COLOR
                            }}></div>
            </div>
        );
    }

    renderCollapsedItems = () => {

        const { items } =this.props;

        let content = [];

        for (var i = 0, len = items.length; i < len; i++) {

          let image = utils.imagePath(items[i].image_path.split(',')[0], "x300");
          content[i] = (
         
            <div style={{ display: "flex", flexDirection: "column", 
                         borderTop: "solid 1px #f8f8f8",
                         paddingTop:25, 
                         paddingBottom: 25}}>
            <div style={styles.collapsedContent}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{marginRight: 10}}>
                        <img src={image} alt="image" width="35px" height="35px"/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={styles.topic}>
                            { items[i].sku_name}
                        </div>
                        <div style={styles.brand}>
                            { items[i].brand}
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={styles.topic}>
                        Quantity
                    </div>
                    <div style={styles.value}>
                        {items[i].qty}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={styles.topic}>
                        Amount
                    </div>
                    <div style={styles.value}>
                        &#8377;{items[i].line_amount}
                    </div>
                </div>
            </div>
                { this.renderTracker(items[i].status) }
                <div style={{display: "flex", marginLeft: "5%", marginRight: "5%",
                            flexDirection: "row", justifyContent: "space-between"}}>
                    <div style={styles.status}>Confirmed</div>
                    <div style={styles.status}>Packed</div>
                    <div style={styles.status}>Shipped</div>
                    <div style={styles.status}>Delivered</div>
                </div>
            </div>
            );
        }
        return content;
    }


    render() {
        const { items, payment, paymentType } =this.props;
        let paymentCancelled = items[0].status == 161;
        
        return paymentCancelled ? 
                (
                    <div style={{textAlign: "center", paddingTop: 10, paddingBottom: 10}}> 
                        <div style={{ fontSize: 16, 
                                      fontFamily: "OpenSans", 
                                      color: "red"}}>
                            Payment Failed    
                        </div>
                    </div>
                )
            : ( payment === 'Payment not done' && paymentType !== "RZR_PAY" ?
            this.renderMakePayment() :
            this.renderCollapsedItems())

    }
}

export default CollapsableList;

const styles = {
    collapsedContent : {
        display: "flex", 
        flexDirection: "row", 
        paddingTop:25, 
        paddingBottom: 25, 
        paddingLeft: 25, 
        paddingRight: 25,
        justifyContent: "space-between", textAlign: "center"
    },
    topic : {
        opacity: 0.54,
        fontFamily: "OpenSans",
        fontSize: 12,
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom : 5
    },
    value : {
        fontFamily: "OpenSans",
        fontSize: 13,
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.54)"
    },
    brand: {
        fontFamily: "OpenSans",
        fontSize: 10,
        fontWeight: 600,
        color: appinfo.SECONDARY_COLOR
    },
    dot: {
        width: 11, 
        height: 11, 
        borderRadius: 6, 
        backgroundColor: "#cdcdcd"
    },
    line: {
        marginTop: 5,
        height: 2,
        width: 620 / 3,
        backgroundColor: "#cdcdcd"
    },
    status: {
        opacity: 0.54,
        fontFamily: "OpenSans",
        fontSize: 11,
        color: "rgba(0, 0, 0, 0.87)"
    }
}