import { orderConstants } from "../constants";
import { orderService } from "../services";
import { httpError } from "../utils"
import { endpointActions } from "../actions"

export const orderActions = {
  createOrder,
  getAll,
  paymentUpdate,
  paymentPending
};

function createOrder(order) {
  return dispatch => {
    dispatch(request());
    orderService
      .createOrder(order)
      .then(
        order_details => dispatch(success(order_details)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: orderConstants.CREATE_ORDER_REQUEST };
  }
  function success(order_details) {
    return { type: orderConstants.CREATE_ORDER_SUCCESS, order_details };
  }
  function failure(error) {
    httpError.showError(error);
    return { type: orderConstants.CREATE_ORDER_FAILURE, error };
  }
}

function getAll(refresh = false, callPaymentPending = false, transactionDetails={}) {
  return dispatch => {
    dispatch(request(refresh));
    orderService.getAll().then(
      order_list => {
        dispatch(success(order_list));
        if (callPaymentPending) {
          dispatch(paymentPending());
          dispatch(paymentUpdate(transactionDetails));
        }
      },
      error => dispatch(failure(error))
    );
  };

  function request(refresh) {
    return { type: orderConstants.GET_ALL_REQUEST, refresh };
  }
  function success(order_list) {
    return { type: orderConstants.GET_ALL_SUCCESS, order_list };
  }
  function failure(error) {
    httpError.showError(error);
    return { type: orderConstants.GET_ALL_FAILURE, error };
  }
}

function paymentPending() {
  return dispatch => {
    dispatch(request());
    orderService
      .paymentPending()
      .then(res => dispatch(success(res)), err => dispatch(error(err)));
  };

  function request() {
    return { type: orderConstants.PAYMENT_PENDING_REQUEST };
  }
  function success(res) {
    return { type: orderConstants.PAYMENT_PENDING_SUCCESS, res };
  }
  function error(err) {
    httpError.showError(err);
    return { type: orderConstants.PAYMENT_PENDING_ERROR, err };
  }
}

function paymentUpdate(transactionDetails) {
  return dispatch => {
    dispatch(request());
    orderService.paymentUpdate(transactionDetails).then(
      res => {
        dispatch(success(res));
      },
      error => {
        // dispatch(endpointActions.slack(error))
        dispatch(failure(error))
      }
    );
  };

  function request() {
    return { type: orderConstants.PAYMENT_UPDATE_REQUEST };
  }
  function success(res) {
    return { type: orderConstants.PAYMENT_UPDATE_SUCCESS, res };
  }
  function failure(error) {
    return { type: orderConstants.PAYMENT_UPDATE_FAILURE, error };
  }
}
