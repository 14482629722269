import { httpClient } from "../utils";
import pkg from "../../package.json";
import Store from "../reducers";
import appinfo from "../clientsJSON";

export const endpointService = {
  hello,
  termsAndConditions,
  policy
};

function hello() {
  let requestOptions = {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-client-version": pkg.version,
      "x-cloud-id":appinfo.X_CLOUD_ID
    },
    body: JSON.stringify({})
  };
  let url = Store.getState().endpoint.service_url;

  return fetch(`${url}/hello`, requestOptions).then(httpClient.handleResponse);
}
function termsAndConditions() {
  return httpClient.callApi("GET", `/terms-condition`);
}
function  policy(cloud_id) {
  return httpClient.callApi("GET", `/policy/privacy/${cloud_id}`);
  /***********In this Api will use as a return policy also u must change privacy into return or declare the type make two in one******************************/ 
}