import appinfo from "../clientsJSON";
import {httpClient} from "../utils";

export const shippingService ={
    addAddress,
    getAll,
    getAmount,
    getDefault,
    markDefault,
    editAddress,
    deleteAddress
};
function addAddress(address){
    return httpClient.callApi("POST",`/address/me`,address);
}
function getAll(){
    return httpClient.callApi("GET",`/address/me`);
}
function getDefault(){
    return httpClient.callApi("GET",`/address/me/default`);
}
function getAmount(payload) {
    return httpClient.callApi("GET", `/shipment/amount/${payload}`);
}
  
function markDefault(shipping_info) {
    return httpClient.callApi("GET", `/address/me/default`, shipping_info);
}
  
function editAddress(payload){
      return httpClient.callApi("PUT",`/address/me`,payload);
}

function deleteAddress(shippind_id){
    return httpClient.callApi("DELETE",`/address?filters=${shippind_id}`);
}
