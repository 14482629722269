import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { utils } from "../../utils";
import appinfo from "../../clientsJSON";
import Container from "../container";
import ReactImageMagnify from "react-image-magnify";
import Modal from 'react-responsive-modal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import WhatsApp from "../../images/whatsapp/WhatsApp.png";
import Play from "../../images/play/play.png";
// import ModalVideo from 'react-modal-video';
import {Helmet} from "react-helmet";
import ProductBox from "../../components/product";

import "./product.css";
import { 
    inventoryActions, 
    cartActions,
    compareActions,
    skudocActions,
    demoActions, 
    catalogActions,
    profileActions,
    searchActions
  } from "../../actions";
import { ShowMore, ProductPageLoader, Loader, BreadCrumbs } from "../../components";

class Product extends Component {
  constructor() {
    super();
    const cook = new Cookies();
    this.state = {
      imgSrc: "",
      largeimgSrc: "",
      from : "",
      name: "",
      email: "",
      phone: "",
      healthcare: "",
      facility: "",
      id: "" ,
      loggedIn : cook.get("loggedIn"),
      currentimage: 0,
      open: false,
      phoneError: false,
      processing: false,
      reference: "",
      videoModal: false
    };
  }

  componentDidMount() {
    const { dispatch, profile, match } = this.props;
   
    let urlData = match.params.id;
    // if(this.state.loggedIn == "true"){
      dispatch(skudocActions.getDocDetails(urlData));
    // }
    dispatch(catalogActions.getInventoryCatalog(urlData));

    if(profile.status != "PROFILE_SUCCESS" && this.state.loggedIn == "true"){
      dispatch(profileActions.whoami());
    }

    this.setState({ id : urlData });
  }

  onSelection = searchParams => {
    const { history } = this.props;
    const { setValueForUrl, removeValueFromUrl } = utils;
    let newSearchParams = setValueForUrl(searchParams, "filter", true);
    // newSearchParams = removeValueFromUrl(newSearchParams, ["id"]);

    history.push("/search?" + newSearchParams);
  };


  validatePhoneInput = () => {
    const { phone, phoneError } = this.state;
    let valid = isValidPhoneNumber(phone || "");

    this.setState({
      phoneError: !valid
    })

    return valid;
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    let property = document.getElementsByName(event.target.name)[0];
    property.style.border = "0.5px solid rgb(219,219,219)";
  };

  componentDidUpdate(nextProps){
    const { inventory, dispatch } = this.props;
    let hasInventoryChanged = inventory !== nextProps.inventory;
    if(hasInventoryChanged && inventory.status == "GET_BY_INVENTORYID_SUCCESS"){
      let payload={
        search:`product_name: \"${inventory.inventories[0].product_name}\"`,
        filter:{
          category_id:inventory.inventories[0].category_id,
          sku_id:inventory.inventories[0].sku_id,
          // product_id:inventory.inventories[0].product_id,
          status:1
        }
      }
      dispatch(searchActions.searchV2(payload));
    }

    const { demo, history } = this.props;
    let hasdemoChanged = demo !== nextProps.demo;

    if( hasdemoChanged && demo.status === "DEMO_SUCCESS" ){
      this.setState({ processing: false, open: false });
      if( this.state.from === "QUOTE" ) {
        history.push("/success-product", {page: "demo", message : appinfo.CONTENTS.successPageMessage });  
      } else if( this.state.from === "DEAL" ) {
        history.push("/success-deal", {page: "demo", message : appinfo.CONTENTS.successPageMessage });  
      } else {
        window.open(this.state.url, "_blank");
      }
    } else if ( hasdemoChanged && demo.status === "DEMO_ERROR" ) {
      this.setState({  processing : false });
    }
  }

  requestRedirect = ( from, url ) => {
    const { history, profile, dispatch } = this.props;

      this.setState({ 
          from: from , 
          open: this.state.loggedIn !== "true" ? true : false, 
          url : url
        });
      if( this.state.loggedIn === "true") {
        let payload = {
          approver_id: 2,
          type: 1,
          message: JSON.stringify({
            email: profile.about.myself.email,
            name: profile.about.myself.name,
            phone: profile.about.myself.mobile,
            healthcarename: this.state.healthcare,
            facility: this.state.facility,
            requestedtype: 1,
            website: "",
            msg : "",
            id: this.state.id,
            fromPage : from,
            reference: this.state.reference,
            mobile_no: profile.about.myself.mobile
          }),
          status: 1
        }
        dispatch(demoActions.demo(payload));
      }


  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  changeImage = (image, index) => {
    this.setState({
      imgSrc: utils.imagePath(image, "x300"),
      largeimgSrc: utils.imagePath(image, "x600"),
      currentimage: index
    });
  };

  handleSubmit = () => {

      const { dispatch } =this.props;

      if (
        this.state.name != "" &&
        this.state.email != "" &&
        this.validatePhoneInput() &&
        /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)
      ) {    
          let property = document.getElementsByName("name")[0];
          let property1 = document.getElementsByName("phone")[0];
          let property2 = document.getElementsByName("email")[0];
          property.style.border = "0.5px solid rgb(219,219,219)";
          property2.style.border = "0.5px solid rgb(219,219,219)";

            let payload = {
              approver_id: 2,
              type: 1,
              message: JSON.stringify({
                email: this.state.email,
                name: this.state.name,
                phone: this.state.phone,
                healthcarename: this.state.healthcare,
                facility: this.state.facility,
                requestedtype: 1,
                website: "",
                msg : "",
                id: this.state.id,
                fromPage : this.state.from,
                reference: this.state.reference,
                mobile_no:this.state.phone,
              }),
              status: 1
            };
          dispatch(demoActions.demo(payload));
          this.setState({ processing: true });
      
      } else {
        if (this.state.name == "") {
          let property = document.getElementsByName("name")[0];
          property.style.border = "0.7px solid rgb(255,0,0)";
          property.placeholder = "Please enter valid Name";
        }
        if (!this.validatePhoneInput()) {
          let property = document.getElementsByName("phone")[0];
          property.placeholder = "Please enter valid Phone Number";
        }
        if (this.state.email == "" || ! /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)) {
          let property = document.getElementsByName("email")[0];
          property.style.border = "0.7px solid rgb(255,0,0)";
          property.placeholder = "Please enter valid Email ID";
        }
      }

  }

  renderSpecifications(initial) {
    const { inventory } = this.props;
    const inventoryToShow = inventory.inventories[0];
    let additional_info = inventoryToShow.additional_info;
    let components = [];

    for (var i = initial, len = additional_info.length; i < len; i += 2) {
      components[components.length] = (
        <div style={{ marginTop: 24 }}>
          <div
            className="product-price"
            style={{ color: "#000000", fontSize: 14, fontWeight: 600 }}
          >
            {(additional_info[i].value) && additional_info[i].key}
          </div>
          <div
            className="product-price"
            style={{
              color: "#000000",
              fontSize: 14,
              marginTop: 4,
              fontWeight: 300
            }}
          >
            {additional_info[i].value}
          </div>
        </div>
      );
     }

    return <>{components}</>;
  }

  renderSimilarProducts = () => {
    const { search, match } = this.props;
    if(search.status == "SEARCH_SUCCESS" && search.total > 5){
      let similarProductsCode=[];
      for(let i=0;i<6;i++){
        if(search.results[i]._source.inventory_id != match.params.id){
          similarProductsCode[i] = <ProductBox 
          url ={`${appinfo.WEBSITE}/product/${search.results[i]._source.inventory_id}/${search.results[i]._source.inventory_name.replace(/\s+/g, '-').toLowerCase()}`} data={search.results[i]._source}/>
          // onClick = {() => window.open(`${appinfo.WEBSITE}/product/${search.results[i]._source.inventory_id}/${search.results[i]._source.inventory_name.replace(/\s+/g, '-').toLowerCase()}`)} />;
        }
       }
    return (
        <div style={{ display: "flex",flexDirection:"column" }} >
            <div
              style={{
                color: "#203047",
                fontSize: 18,
                fontWeight: "700",
              }}
              className="specification-technical"
            >
              SIMILAR PRODUCTS
            </div>
              <div style={{display:"flex",flexWrap:"wrap",marginTop:0,marginRight:"auto",marginBottom:20,marginLeft:"auto"}}>
                {similarProductsCode}
              </div>
        </div>
    );
    }   
  }
 renderbreadcrumbs =() =>{
   const {inventory,searchParams,location}=this.props;
   const{getValueFromUrl}=utils;
   let category_name=getValueFromUrl(location.search,"category_name",true);
   let sku_name=getValueFromUrl(location.search,"sku_name");
   let product_name=getValueFromUrl(location.search,"product_name");
   if(category_name||sku_name ||product_name=="null"){
    return <BreadCrumbs searchParams={location.search} onSelection={this.onSelection}/>}
    else{
   let array = Object.keys(inventory.inventories);
   
     return (
       array.map((val,i) => (
         
       <div key={`key-${i}`} className="bread-crumbs"  >
     <p style={{fontSize: 15,
     color: appinfo.SECONDARY_COLOR,
     textDecoration: "none",
      cursor: "pointer"}}>
    <a  style={{
        fontSize: 15,
        color:appinfo.PRIMARY_COLOR,
        textDecoration:"none",
        borderRadius:5,
        height:24,
        
        padding:2}}href={`/search?&fromMenu=true&category_id=${inventory.inventories[0].category_id}&sku_name=${inventory.inventories[0].sku_name}&filter=true`}>{inventory.inventories[val].sku_name}</a></p>
     <p style={{fontSize: 15,
     color: appinfo.PRIMARY_COLOR,
     textDecoration: "none",
      cursor: "pointer"}}>&nbsp;/
      <a href={`/search?&fromMenu=true&category_id=${inventory.inventories[0].category_id}&sku_name=${inventory.inventories[0].sku_name}&product_name=${inventory.inventories[0].product_name}&filter=true`}style={{
        fontSize: 15,
        color:appinfo.PRIMARY_COLOR,
        textDecoration:"none",
        padding: "0px 5px"}}>&nbsp;{inventory.inventories[val].product_name}</a></p>
         
        </div>
       )
       ))}
       
     
   
 }
  renderProductInfo = () => {
    const { inventory, cart, dispatch, compare } = this.props;
    const inventoryToShow = inventory.inventories[0];
    let images = [];
    if (
      inventory.inventories.length == 0 &&
      inventory.status == "GET_BY_INVENTORYID_SUCCESS"
    ) {
      return (
        <div
          style={{
            display: "flex",
            height: 200,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          No such inventory
        </div>
      );
    } 
    else if (inventory.status == "GET_BY_INVENTORYID_REQUEST") {
      return <ProductPageLoader />;
    } 
    else if (inventory.status == "GET_BY_INVENTORYID_SUCCESS") {
      let inCart = cart.inventories.filter( e => {return e.inventory_id === inventoryToShow.inventory_id});
      let inCompare = compare.compare.filter( e => {return e.inventory_id === inventoryToShow.inventory_id});
      document.title = inventoryToShow.inventory_name + " - " + appinfo.APP_FOR;

      let imageNameProduct = [];
      let left = this.renderSpecifications(0);
      let right = this.renderSpecifications(1);
      if (inventoryToShow.image_path != null) {
        imageNameProduct = inventoryToShow.image_path.split(",");
        for (let i = 0; i < imageNameProduct.length; i++) {
          if (imageNameProduct[i] != "") {
            let ProductImage = utils.imagePath(imageNameProduct[i], "x300");
            images[images.length] = (
              <div
                className="image-container"
                onClick={() => this.changeImage(imageNameProduct[i], i)}
                style={{
                  marginLeft: 16,
                  cursor: "pointer",
                  border:
                    this.state.currentimage == i ? ("1px solid" + appinfo.PRIMARY_COLOR) : "none"
                }}
              >
                <img alt={inventoryToShow.inventory_name} style={{ width: 60, height: 60 }} src={ProductImage} />
              </div>
            );
          }
        }
      }

      let imgSrc = utils.imagePath(imageNameProduct[0], "x300");
      let largeimgSrc = utils.imagePath(imageNameProduct[0], "x600");
      const { skudoc } = this.props;
      let docs = skudoc.docs;
      let cloud_id = appinfo.X_CLOUD_ID;
      let token = btoa(`{"cloud_id":${cloud_id}}`);
      let endpoint = appinfo.ENDPOINTS.URI;
      let filename = "";
      let url = `${endpoint}/`;
      let urlID=`${appinfo.ENDPOINTS.URI}/product/${inventoryToShow.inventory_id}/${inventoryToShow.inventory_name.replace(/\s+/g, '-').toLowerCase()}`;
      let whatsapp=`https://wa.me/?text=${appinfo.whatsapp} ${inventoryToShow.inventory_name} ${appinfo.WEBSITE}/product/${inventoryToShow.inventory_id}/${inventoryToShow.inventory_name.replace(/\s+/g, '-').toLowerCase()}`;
      let video = [];
      let videoToAdd = [];
      if (docs.length >= 1) {
        filename = docs[0].value;
        url += `image/${filename}?token=${token}`;
        videoToAdd = docs.filter(val =>  { return val.document_type === 3});
        video = videoToAdd.map(add => {
          let link = "";
          if(add.value.indexOf("?v=") != -1){
            link = add.value.split("?v=");
          } else {
            link = add.value.split("/");
          }
          return (
            <div
              className="image-container"
              onClick={() => this.setState({ 
                  videoModal: !this.state.videoModal,
                  videoLink: link[link.length-1]})}
              style={{
                marginLeft: 16,
                cursor: "pointer",
                border: "none"
              }}
            >
              <img style={{ width: 60, height: 60 }} src={Play} />
            </div>
          );
        }) 
      }
      
      return (
        <div className="container">
          <div className="body-product">
            <div className="image-product" style={{zIndex:1}}>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: inventoryToShow.inventory_name,
                    isFluidWidth: true,
                    src: this.state.imgSrc || imgSrc
                  },
                  largeImage: {
                    src: this.state.largeimgSrc || largeimgSrc,
                    width: 1000,
                    height: 1000,
                    zIndex:1
                  }
                }}
              />

              <div className="images">
                {images}
                {video}
              </div>
            </div>
            <div className="body-info-product">
              <h1 className="product-title">
                {inventoryToShow.inventory_name}
              </h1>
              <div className="product-subtitle" style={{color:appinfo.PRIMARY_COLOR, textTransform:"uppercase"}}>
                {/* {inventoryToShow.sku_name}, */}
                {inventoryToShow.modal}, {inventoryToShow.sku_name}, {inventoryToShow.brand}
              </div>
              {(appinfo.ROLE == 1) && (
              <div className="product-price" style={{paddingTop:32,display: "flex",alignItems:"center"}}>
                <div style={{fontSize:28,verticalAlign:"sub"}}>₹{inventoryToShow.recent_selling_price }</div>
                <div style={{textDecoration:"line-through",padding:"0px 10px 0px 10px"}}>{" MPR ₹" +inventoryToShow.msrp_price}</div>
                <div style={{fontSize:14,verticalAlign:"sub"}}>{(inventoryToShow.msrp_price-inventoryToShow.recent_selling_price)*100/inventoryToShow.msrp_price }% off</div>
              </div> )}              
              {(inventoryToShow.deal_id != null && appinfo.ROLE == 3 && inventoryToShow.recent_selling_price > 0 && inventoryToShow.msrp_price > 0) && (
              <div className="product-price" style={{paddingTop:12}}>
                ₹{inventoryToShow.recent_selling_price} - ₹{inventoryToShow.msrp_price}
              </div> )}
              {(inventoryToShow.deal_id != null && appinfo.ROLE == 3) && (
              <div className="" style={{marginTop:16,backgroundColor:'#D2D2D2',color:appinfo.PRIMARY_COLOR,fontWeight:600,height:40,fontSize:14,padding:10}}>
                On deal! Requesting quote will help you buy at low price
              </div>)}
              <div style={{ display: "flex", flexDirection: "row" }}>
              {/* Start of CTA Logic*/}
              {/* B2B Deals*/}
              {(appinfo.ROLE == 3) && (inventoryToShow.deal_id != null) && !(this.state.loggedIn === "true") && (
                <button
                  onClick={()=>this.requestRedirect("DEAL")}
                  className="demo-button"
                  style={{
                    marginTop: 25,
                    backgroundColor: appinfo.PRIMARY_COLOR,
                    color: "#ffffff",
                    fontWeight: 600,
                    cursor: "pointer"
                  }}
                >
                  JOIN DEAL
                </button>                
              )}               
              {/* B2B Request for Quote*/}
              {(appinfo.ROLE == 3) && !(this.state.loggedIn === "true") && !(inventoryToShow.deal_id != null) && (
                <button
                onClick={()=>this.requestRedirect("QUOTE")}
                className="demo-button"
                style={{
                  marginTop: 25,
                  backgroundColor: appinfo.PRIMARY_COLOR,
                  color: "#ffffff",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                >
                REQUEST FOR QUOTE
                </button>               
              )}
              {/* B2B Request for Quote & LoggedIn*/}
              {(appinfo.ROLE == 3) && (this.state.loggedIn === "true") &&(
                (inCart.length == 0) ? 
                (<button
                onClick={() => dispatch(cartActions.addToCart(inventoryToShow)) }
                className="demo-button"
                style={{
                  marginTop: 25,
                  backgroundColor: appinfo.PRIMARY_COLOR,
                  color: "#ffffff",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
              >
                ADD TO CART
              </button>)
               : (
                <button
                onClick={() => dispatch(cartActions.removeFromCart(inventoryToShow.inventory_id)) }
                className="demo-button"
                style={{
                  marginTop: 25,
                  backgroundColor: appinfo.PRIMARY_COLOR,
                  color: "#ffffff",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
              >
                REMOVE FROM CART
               </button>   )               
              )}            
              {/* qty */}
              {(appinfo.ROLE != 3) && (inventoryToShow.available_qty != 0) &&(
                (inCart.length == 0) ? 
                (<button
                onClick={() => dispatch(cartActions.addToCart(inventoryToShow)) }
                className="demo-button"
                style={{
                  marginTop: 25,
                  backgroundColor: appinfo.PRIMARY_COLOR,
                  color: "#ffffff",
                  fontWeight: 600,
                  border:"none",
                  cursor: "pointer"
                }}
              >
                ADD TO CART
              </button>)
               : (
                 <div>
                 
                <button
                onClick={() => dispatch(cartActions.removeFromCart(inventoryToShow.inventory_id)) }
                className="demo-button"
                style={{
                  marginTop: 25,
                  backgroundColor: appinfo.PRIMARY_COLOR,
                  color: "#ffffff",
                  fontWeight: 600,
                  border:"none",
                  cursor: "pointer"
                }}
              >
                REMOVE FROM CART
               </button><p style={{color:"#090"}}><i class="material-icons" style={{verticalAlign:"middle"}}>verified</i>&nbsp;Items Added To your Cart!!</p> </div>  )               
              )}
              {/* qty */}
              {(appinfo.ROLE != 3) && (inventoryToShow.available_qty == 0) && 
                (<p
                  onClick={() => dispatch(cartActions.addToCart(inventoryToShow)) }
                  style={{
                    marginTop: 40,
                    color: "red",
                    fontWeight: 600,
                    border:"none",
                  }}
                >
                  N0 STOCK
                </p>)}                                             
              {/* End of CTA Logic*/}
                <a href={encodeURI(whatsapp)} target="_blank"><img src={WhatsApp} style={{height:32,marginTop:28,marginLeft:8}}/></a>

                {docs.length >= 1 && (
                  <a
                    className="product-subtitle"
                    style={{
                      fontSize: 13,
                      paddingTop: 0,
                      marginTop: 33,
                      marginLeft: 20,
                      cursor: "pointer"
                    }}  
                    onClick={()=>{this.requestRedirect("CATALOGUE", url)}} 
                    target="_blank"
                  >
                    DOWNLOAD CATALOGUE
                  </a>
                )}          
              </div>
              {/* compare */}
              { 
              (inCompare.length == 0) ? ( 
                (compare.compare.length < 3) ? (
                  <div style={{padding:"16px 0px 16px 0px"}}>
                    <button onClick={() => dispatch(compareActions.addToCompare(inventoryToShow))} style={{color:appinfo.PRIMARY_COLOR,borderRadius:5,padding:2,border:"none",textDecoration:"none",cursor:"pointer",textTransform:"uppercase"}}>compare The Product </button>
                  </div>) :
                  (
                  <div>
                    <p onClick={() => {
                      dispatch(compareActions.removeFromCompare(compare.compare[0].inventory_id))
                      dispatch(compareActions.addToCompare(inventoryToShow))}
                    } style={{color:appinfo.PRIMARY_COLOR,textDecoration:"none",cursor:"pointer"}}>compare</p>
                  </div>)
                ) : (
              <div>
                 <a href="/compare" style={{textDecoration:"none"}}>
                <button style={{color:"#FFFFFF",borderRadius:5,padding:2,backgroundColor:appinfo.PRIMARY_COLOR,border:"none",textDecoration:"none",cursor:"pointer",textTransform:"uppercase"}}>
                 go to compare page&nbsp;<i class="material-icons" style={{verticalAlign:"middle",fontSize:15}}>arrow_forward</i>
                 </button></a>
               
                <p onClick={() => dispatch(compareActions.removeFromCompare(inventoryToShow.inventory_id))} style={{color:appinfo.PRIMARY_COLOR,textDecoration:"none",cursor:"pointer"}}>remove</p>
              </div>)
              }
              
              {/* <hr className="line" /> */}
              <ShowMore md={inventoryToShow.description} />
              <div className="product-subtitle" style={{ fontSize: 12 }}></div>
              <div className="product-price"></div>
              {/* <hr className="line" /> */}
            </div>
          </div>

          <div className="specification">
            <div className="specification-technical">
              <div
                className="product-price"
                style={{ color: "#000000", fontSize: 16 }}
              >
                {left.props.children.length > 1 ||
                right.props.children.length > 1
                  ? "GENERAL SPECIFICATION"
                  : ""}
              </div>

              <div className="specification-detail">
                <div className="left-specs">{left}</div>
                <div className="specification-detail-right left-specs">
                  {right}
                </div>
              </div>
              <div
                className="product-subtitle"
                style={{ fontSize: 12, marginTop: 24, marginBottom: 60 }}
              >
              </div>
            </div>

            {/* <div className="specification-technical">
              <div
                className="product-price"
                style={{ color: "#000000", fontSize: 16 }}
              >
                 TECHNICAL SPECIFICATION 
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginTop: 32 }}>
                  <div
                    className="product-price"
                    style={{ color: "#000000", fontSize: 14, fontWeight: 600 }}
                  >
                     URH389042H4 
                  </div>
                  <div
                    className="product-price"
                    style={{
                      color: "#000000",
                      fontSize: 14,
                      marginTop: 4,
                      fontWeight: 300
                    }}
                  >
                     Catalog number 
                  </div>
                </div>
                <div style={{ marginTop: 32, marginLeft: 106 }}>
                  <div
                    className="product-price"
                    style={{ color: "#000000", fontSize: 14, fontWeight: 600 }}
                  >
                     ReM
                  </div>
                  <div
                    className="product-price"
                    style={{
                      color: "#000000",
                      fontSize: 14,
                      marginTop: 4,
                      fontWeight: 300
                    }}
                  >
                     Brand name 
                  </div>
                </div>
              </div>
              <div
                className="product-subtitle"
                style={{ fontSize: 12, marginTop: 24 }}
              >
                SEE MORE
              </div>
            </div> */}
          </div>
        </div>
      );
    }
  };

  renderModal = () => {
    return (
        <Modal 
          showCloseIcon={false}
          open={this.state.open} 
          onClose={this.onCloseModal} 
          center={true}>
                        
              <h4 style={{color: "rgba(32, 48, 71, 0.54)",
                          marginBlockStart: "4px", marginBlockEnd: "4px"}}>
                Fill the Below form to { this.state.from == "QUOTE" && (<span>send the request</span>)}{ this.state.from == "CATALOGUE" && (<span>download catalogue</span>)}{ this.state.from == "DEAL" && (<span>join the deal</span>)}
              </h4>
              <input
                type="text"
                className="input mod"
                placeholder="Name"
                name="name"
                onChange={this.handleChange}
              />
              <PhoneInput
                country="IN"
                name="phone"
                className={this.state.phoneError ? "input line mod input-error" : "input mod line"}
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={phone => this.setState({ phone })} />
              <input
                type="email"
                className="input mod"
                placeholder="Email ID"
                name="email"
                onChange={this.handleChange}
              /> 
                <select className="input-select mod" 
                        onChange={(event)=>{this.setState({facility: event.target.value})}}
                        selectedValue={this.state.facility}>
                  <option value="">Type of Facility</option>
                  <option value="clinic">Clinic</option>
                  <option value="diagnostic centre">Diagnostic Centre</option>
                  <option value="hospital">Hospital</option>
                  <option value="nursing home">Nursing Home</option>
                  <option value="others">Others</option>
                </select>

                <input
                  type="text"
                  className="input mod"
                  placeholder="Name of Medical Facility"
                  name="healthcare"
                  onChange={this.handleChange}
                />
                <input
                  type="text"
                  className="input"
                  placeholder="Referral code ( optional )"
                  name="reference"
                  onChange={this.handleChange}
                />

                { this.state.processing ? 
                  <Loader />
                  :
                  <button style={{backgroundColor:appinfo.PRIMARY_COLOR}}  className="demo-button-demo"
                            onClick={()=>this.handleSubmit()}>
                    { this.state.from == "QUOTE" && (<span>SEND REQUEST</span>)}{ this.state.from == "CATALOGUE" && (<span>DOWNLOAD CATALOGUE</span>)}{ this.state.from == "DEAL" && (<span>JOIN DEAL</span>)}
                  </button>
                }

        </Modal>
        );
  }

  renderTechnicalSpecifications = () => {
    const { catalog } = this.props;

    if (
      catalog.status != "GETI_CATALOG_SUCCESS" ||
      (catalog.status == "GETI_CATALOG_SUCCESS" &&
        catalog.result.inventory_catalog.length == 0)
    ) {
      return <div />;
    } else {
      let result_data = catalog.result.inventory_catalog;
      result_data.sort(function(a, b) {
        var dateA = new Date(a.updated_at);
        var dateB = new Date(b.updated_at);
        return dateA - dateB;                              //sorts by date ascending
      });
      let return_data = result_data.map(inv_catalog => {
        return (
          <div className="technical-card">
            {!["", null, undefined].includes(inv_catalog.image) && (
              <div className="technical-card-image">
                <img
                  src={utils.imagePath(inv_catalog.image, "x600")}
                  className="image-tech"
                />
              </div>
            )}
            <div className="technical-card-title">{inv_catalog.title}</div>
            <div className="technical-card-desc">{inv_catalog.desc}</div>
          </div>
        );
      });
      return <>{return_data}</>;
    }
  };

  render() {
    const { inventory, location, dispatch, match } = this.props;
    
    let canonical=`${appinfo.WEBSITE}/product/${match.params.id}`;
    let urlData = match.params.id;
    if (inventory.inventories.length == 0 && inventory.status == undefined) {
      if (urlData !== "") {
        dispatch(inventoryActions.getByInventoryId(urlData));
      } else {
        return (
          <div
            style={{
              paddingTop: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            No Products were Selected
          </div>
        );
      }
    }
    return (
      <Container>     
      <div>
        {inventory.status == "GET_BY_INVENTORYID_SUCCESS" && <div>
        {/* <Helmet>
          <link rel="canonical" href={canonical}/>
          <meta name="description" content={inventory.inventories[0].description}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={inventory.inventories[0].inventory_name} />
          <meta property="og:description" content={inventory.inventories[0].description} />
        </Helmet> */}
        <Helmet>
        <title>{appinfo.APP_FOR}</title>
      <meta name="description" content={appinfo.TITLE}/>
        </Helmet>       
                  {/* <meta property="og:url" content={urlID}/>
          <meta property="og:image" content={imgSrc} /> */}       
      </div>}   
      <div> {this.renderbreadcrumbs()}</div>
      
      
        {this.renderProductInfo()}
        <div>{this.renderTechnicalSpecifications()}</div>
        {this.renderModal()}
        {/* <ModalVideo 
            channel='youtube' 
            isOpen={this.state.videoModal} 
            videoId={this.state.videoLink} 
            onClose={()=>this.setState({videoModal: false})} 
        /> */}
        <div>
        {this.renderSimilarProducts()}
        </div>
      </div>
    </Container>
    );
  }
}

function mapStateToProps(state) {
  const { catalog, cart, demo, inventory, profile, skudoc ,compare, search} = state;
 

  return {
    catalog,
    cart,
    compare,
    demo,
    inventory,
    profile,
    skudoc,
    search
  };
}

export default connect(mapStateToProps)(Product);
