import React, { Component } from "react";
import { utils } from "../../utils";
import appinfo from "../../clientsJSON";

class FeaturedProduct extends Component {
    render() {
        const { image, inventory_name, sku_name, brand, onClick, style, classname,url } = this.props;
        
        let imgSrc=utils.imagePath(image.split(",")[0], "x300");
        let redirection=window.screen.width >768 ? "_blank":"_self"
       
        return (
          <div>
            <a  style={{textDecoration:"none"}}href={url} target={redirection}>
            <div style={{cursor: "pointer", display: style}} onClick={onClick} className={classname}>
              <div style={{
                height: window.screen.width<1366 ? 200 : 200,
                width: window.screen.width<1366 ? 200 : 200,
                backgroundColor: "white",
                marginTop: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 4px 25px 0px rgba(22, 39, 1, 0.1)"
              }}>
                <img src={imgSrc} width={ window.screen.width<1366 ? "200px" : "200px" } height={ window.screen.width<1366 ? "200px" : "200px"} />
            </div>
            <div style={{paddingTop: 13,color: appinfo.SECONDARY_COLOR,fontSize: 16,fontWeight: "600"}}>
                {(inventory_name.length>=21 ? inventory_name.substring(0,20) + "..." : inventory_name)}
            </div>
            <div style={{paddingTop: 5, fontSize: 14,color: "rgba(32, 48, 71, 0.54)",fontWeight: "600"}}>
              {(sku_name.length>=21 ? sku_name.substring(0,20) + "..." : sku_name)}
            </div>
            <div style={{ paddingTop: 5, fontSize: 14, color: "rgba(32, 48, 71, 0.54)", fontWeight: "600"}}>
              {(brand.length>=21 ? brand.substring(0,20) + "..." : brand)}
            </div>
        </div>
          </a>  </div> );
    }
}

export default FeaturedProduct ;