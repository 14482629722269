import React, { Component } from "react";
import "./homepage.css";
import { Loader } from "../";
import appinfo from "../../clientsJSON";

class HomePageLoader extends Component {
  render() {
      return (
        <div style={{ backgroundColor: appinfo.PRIMARY_COLOR }}>
          <div className="header">
            <img
              src={appinfo.logo}
              alt={appinfo.APP_FOR}
              height="40"
              style={{ cursor: "pointer" }}
            />
            <form >
              <div className="search">
                <input
                  className="search-input"
                  placeholder="Search ..."
                />
                <button className="button" style={{backgroundColor:appinfo.PRIMARY_COLOR}}>
                  <i className="material-icons md-18 white-icon">search</i>
                </button>
              </div>
            </form>
            <button className="cart-button" style={{border:"none",backgroundColor:"#FFF",color:appinfo.PRIMARY_COLOR,cursor:"pointer"}}>
              <i className="material-icons">shopping_cart</i>
            </button>
          </div>
          <Loader />
        </div>
      );
  }
}

export default HomePageLoader;