import React, {Component} from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { utils } from "../../utils";
import appinfo from "../../clientsJSON";
import Container from "../container";

import { compare } from "../../reducers/compare.reducer";

import { withRouter } from "react-router-dom";
import {compareActions} from "../../actions/compare.actions";

class Compare extends Component{
    oncompareItems = () => {
        const { compare,dispatch} =this.props;
        let array = Object.keys(compare.compare);
        return (
            array.map((item, i) => (
              <div key={`key-${i}`} className="Table" style={{padding:20}}>
                  <div className="Table-row" style={{textAlign:"center"}}></div>
                    <div className="Table-row" style={{width:"50%"}}>
                        <img src={utils.imagePath(compare.compare[item].image_path.split(",")[0], "x300", "x300")} width="80"/>
                    </div><br></br>
                    <div className="Table-row"><h2>{compare.compare[item].inventory_name}</h2></div>
                    <div className="Table-row"><p>{compare.compare[item].brand}</p></div>
                    {/* <div className="Table-row"><p>{compare.compare[item].description}</p></div> */}
                    <button style={{padding:4,border:"none",backgroundColor:"white",color:appinfo.PRIMARY_COLOR,cursor:"pointer"}} onClick={()=> dispatch(compareActions.removeFromCompare(compare.compare[i].inventory_id))}>Remove</button> 
                    <div className="Table-row">
                        {(compare.compare[item].additional_info) && compare.compare[item].additional_info.map((s,index) => (<div><p class ="compare" key={index}><strong>{s.key}</strong> {s.value}</p>
                        </div>))}
                    </div>
              </div>
            ))
          )
      }    
    render(){
        const { compare } = this.props;  
        return (
            <Container>
                <div style={{Padding:40}}>
                    <h1 style={{textAlign:"center"}}>Compare Products</h1>
                    <div className="Table">
                        <table style={{width:"100%"}}>
                            {compare.compare.length >0 && <div style={{display:"flex"}}>{this.oncompareItems()}</div>}
                            {compare.compare.length == 0 && <div style={{textAlign:"center"}}>Please add products to compare</div>}
                        </table>
                    </div>
                 </div>
            </Container>);
    }
}

function mapStateToProps(state) {
    const { compare } = state;
    return{
        compare
    };
}

export default withRouter(connect(mapStateToProps)(Compare)) ;
