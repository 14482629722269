import React, { Component } from "react";

class BrokenPage extends Component {
  render() {
      return (
        <div 
          style={{
              textAlign: "center", 
              alignContent: "center",
              margin: "10%"
          }}>
            OOPS!!! Page you are looking for doesn't exist
        </div>
      );
  }
}

export default BrokenPage;