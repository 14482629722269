export const profileConstants = {
    PROFILE_REQUEST: "PROFILE_REQUEST",
    PROFILE_ERROR: "PROFILE_ERROR",
    
    PROFILE_SUCCESS: "PROFILE_SUCCESS",

    TENANT_GET_REQUEST: "TENANT_GET_REQUEST",
    TENANT_GET_SUCCESS: "TENANT_GET_SUCCESS",
    TENANT_GET_FAILURE: "TENANT_GET_FAILURE",

    PROFILE_UPDATE_REQUEST: "USER_PROFILE_UPDATE_REQUEST",
    PROFILE_UPDATE_SUCCESS: "USER_PROFILE_UPDATE_SUCCESS",
    PROFILE_UPDATE_FAILURE: "USER_PROFILE_UPDATE_FAILURE",
};
  