import { skuConstants } from "../constants";

const initialState = {
  skus_cid: []
};

export function sku(state = initialState, action) {
  switch (action.type) {
    case skuConstants.GET_BY_CATEGORYID_REQUEST:
      return {
        ...state,
        status: "GET_BY_CATEGORYID_REQUEST"
      };
    case skuConstants.GET_BY_CATEGORYID_SUCCESS:
      return {
        ...state,
        status: "GET_BY_CATEGORYID_SUCCESS",
        skus_cid: [...state.skus_cid, action.res]
      };
    default:
      return state;
  }
}
