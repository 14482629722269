import { orderConstants } from '../constants';

const initialState = {
    order_list: []
}

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.CREATE_ORDER_REQUEST:
        return {
            ...state,
            status: 'CREATE_ORDER_REQUEST'
        };
    case orderConstants.GET_ALL_REQUEST:
        return {
            ...state,
            status: action.refresh ? 'REFRESH_ALL_REQUEST' : 'GET_ALL_REQUEST'
        }
    case orderConstants.PAYMENT_UPDATE_REQUEST:
        return {
            ...state,
            status: 'PAYMENT_UPDATE_REQUEST'
        };
    case orderConstants.PAYMENT_PENDING_REQUEST:
        return {
            ...state,
            status: 'PAYMENT_PENDING_REQUEST'
        }
    case orderConstants.CREATE_ORDER_SUCCESS:
        return {
            ...state,
            order_details: action.order_details,
            status: 'CREATE_ORDER_SUCCESS'
        }
    case orderConstants.GET_ALL_SUCCESS:
        return {
            ...state,
            order_list: action.order_list.orders.reverse(),
            status: 'GET_ALL_SUCCESS'
        }
    case orderConstants.PAYMENT_UPDATE_SUCCESS:
        return {
            ...state,
            status: 'PAYMENT_UPDATE_SUCCESS'
        };
    case orderConstants.PAYMENT_PENDING_SUCCESS:
        let createdOrderId = state.order_details.order.order_id;
        let successfullOrders = action.res.orders.filter((order) => {
            return order.order_id == createdOrderId
        });
        return {
            ...state,
            order_list: [...successfullOrders, ...state.order_list],
            status: 'PAYMENT_PENDING_SUCCESS'
        };
    case orderConstants.CREATE_ORDER_FAILURE:
        return {
            ...state,
            status: 'CREATE_ORDER_FAILURE',
            error: action.error
        }
    case orderConstants.GET_ALL_FAILURE:
        return {
            ...state,
            status: 'GET_ALL_FAILURE',
            error: action.error
        }
    case orderConstants.PAYMENT_UPDATE_FAILURE:
        return {
            ...state,
            status: 'PAYMENT_UPDATE_FAILURE'
        };
    case orderConstants.PAYMENT_PENDING_ERROR:
        return {
            ...state,
            status: 'PAYMENT_PENDING_ERROR'
        };
    default:
        return state
  }
}