import { httpClient } from "../utils";

export const profileService = {
  whoami,
  getTenantDetails,
  updateUserDetails
};

function whoami() {
    return httpClient.callApi("GET", `/whoami`);
}

function getTenantDetails(tenant_id) {
  return httpClient.callApi("GET", `/tenant/me`);
}

function updateUserDetails(details) {
  return httpClient.callApi("PUT", `/tenant/me`, details);
}