import { httpClient } from "../utils";

export const searchService = {
  search,
  searchV2,
  searchV3
};

function search(payload, page) {
  return httpClient.callApi("POST", `/search?page=${page}`, payload);
}

function searchV2(payload, page) {

  return httpClient.callApi("POST", `/v2/search?page=${page}`, payload);
}
function searchV3(payload){
  return httpClient.callApi("POST" ,`/v2/search`,payload);
}
