import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container";
import Profile from "../profile";
import TrackOrders from "../track_orders";
import appinfo from "../../clientsJSON";
import "../styles.css";
import { orderActions } from "../../actions";


class Account extends Component {
  state = {
        show : "track"
    }
  
    render() {
        const { show } =this.state;
      return (
        <Container menu={false}>
            <div>
                <div style={{textAlign: "center",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontSize: 18,
                            opacity: 0.8,
                            fontWeight: 600,
                            marginTop: 50,
                            marginBottom: 40
                            }}>
                    Account Settings
                </div>
                <div className="account">

                    <div className="account-links"> 
                            <div className="account-link"
                                style={{color: show == "track" ? appinfo.SECONDARY_COLOR : "rgba(0, 0, 0, 0.54)"}}
                                onClick={() => this.setState( {show: "track"})}
                            >
                                Track Orders
                            </div>   
                            <div className="account-link"
                                style={{color: show == "profile" ? appinfo.SECONDARY_COLOR : "rgba(0, 0, 0, 0.54)"}}
                                onClick={ () => this.setState({ show: "profile"})}    
                            >
                                Profile
                            </div>                     
                    </div>
                    <div className="account-hrline"></div>
                    <div className="account-tab">
                            {
                                show === "profile" ?
                                    <Profile />   :
                                    <TrackOrders />

                            }
                    </div>
                </div>
            </div>
        </Container>
      );
    }
}
function mapStateToProps(state) {
    const { order } = state;
  
    return { 
      order
    };
  }
  
  export default connect(mapStateToProps)(Account);
