import {addConstants} from "../constants";
import {addService} from "../services";
export const addActions ={
    getAll
};

function getAll() {
  return dispatch => {
    addService
      .getAll()
      .then(res => dispatch(success(res)), error => dispatch(failure(error)));
  };

  function success(res) {
    return { type:addConstants.GETALL_SUCCESS, res };
  }
  function failure() {
    return {type:addConstants.CREATE_ERROR};
  }
}
