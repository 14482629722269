import { catalogConstants } from "../constants";

const initialState = {
  result: []
};

export function catalog(state = initialState, action) {
  switch (action.type) {
    case catalogConstants.GETI_CATALOG_REQUEST:
      return {
        ...state,
        status: "GETI_CATALOG_REQUEST"
      };
    case catalogConstants.GETI_CATALOG_SUCCESS:
      return {
        ...state,
        status: "GETI_CATALOG_SUCCESS",
        result: action.res
      };
    case catalogConstants.GETI_CATALOG_ERROR:
      return {
        ...state,
        status: "GETI_CATALOG_ERROR",
        result: action.err
      };
    default:
      return state;
  }
}
