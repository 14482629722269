import store from "../reducers";
import { userActions } from "../actions";
import Cookies from "universal-cookie";

export const httpError = {
  showError
};

function showError(error) {

  if (error.action) {
    if (typeof error.action.code !== "undefined") {
      switch (error.action.code) {
        case -1:
          store.dispatch(userActions.logout());
          const cookie = new Cookies();
          cookie.remove("loggedIn");
          cookie.set("loggedIn", "false", { path: "/" });
          // window.location.reload();
          break;
        default:
          console.log("httperror",error.message);
      }
    }
  } else if (error.code >501 && error.code <=504) {
    alert("Heavy Traffic. Try after sometime.");
  } else {
    if(error.message.indexOf("JSON Parse error") !== -1 ){
      alert("Server Down. Try after some Time");
    }
  }
}
