import { categoryConstants } from "../constants";

const initialState = {
  categories: []
};

export function category(state = initialState, action) {
  switch (action.type) {
    case categoryConstants.GETALL_REQUEST:
      return {
        ...state,
        status: "GETALL_REQUEST"
      };
    case categoryConstants.GETALL_SUCCESS:
      return {
        ...state,
        status: "GETALL_SUCCESS",
        categories: action.res
      };
    case categoryConstants.GETALL_ERROR:
      return {
        ...state,
        status: "GETALL_ERROR"
      };
      case categoryConstants.GET_REQUEST:
      return {
        ...state,
        status: "GET_REQUEST"
      };
    case categoryConstants.GET_SUCCESS:
      return {
        ...state,
        status: "GET_SUCCESS",
        categories: action.res.menus
      };
    case categoryConstants.GET_ERROR:
      return {
        ...state,
        status: "GETALL_ERROR"
      };
    default:
      return state;
  }
}
