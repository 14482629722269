import { httpClient } from "../utils";
import Store from "../reducers";

let url = Store.getState().endpoint.service_url;

export const userService = {
  login,
  signup,
  mobileotp,
  otpverification,
  otplogin,
  logout
};

function login(username, password) {
  let requestOptions = httpClient.post({ username, password });
  return fetch(`${url}/login`, requestOptions).then(response => {
    let json = response.json();

    if (!(response.status === 200 || response.status === 201)) {
      return json.then(res => {
        return Promise.reject(res);
      });
    }

    return json;
  });
}

function signup(payload){
  return httpClient.callApi("POST", `/signup`, payload);
}
function mobileotp(payload){
  return httpClient.callApi("POST", `/otp`, payload);
}

function otpverification(payload){
  return httpClient.callApi("POST", `/verify-otp`, payload);
}

function otplogin(payload){
  return httpClient.callApi("POST", `/otp-login`, payload);
}

function logout() {}
