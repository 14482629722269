import { httpClient } from "../utils";

export const signupService = {
    otpVerify,
    signupAuto,
}

function otpVerify(payload){
    return httpClient.callApi("POST", `/otp-anonymous`, payload);
  }

function signupAuto(payload){
    return httpClient.callApi("POST", `/create-account`, payload);
  }

