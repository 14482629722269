import {searchConstants} from "../constants";
const initialState ={
    Featured: []
};
export function featured(state= initialState,action){
    switch(action.type){
        case searchConstants.FEATURED_REQUEST:
            return{
                ...state,
                status:"FEATURED_REQUEST"
            };
        case searchConstants.FEATURED_SUCCESS:
                return {
                  ...state,
                  status: "FEATURED_SUCCESS",
                  Featured:[...state.Featured, action.res]
              
              };
         case searchConstants.FEATURED_ERROR:
             return{
                 ...state,
                 status:"FEATURED_ERROR"
             }  
             default:
                return state; 
}
}