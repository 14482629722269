import {shippingConstants} from "../constants";
import {shippingService} from "../services";
import {httpError} from "../utils"
export const shippingActions ={
    addAddress,
    getAll,
    getAmount,
    getDefault,
    markDefault,
    editAddress,
    deleteAddress
};

function addAddress(address){
    return dispatch => {
        dispatch(request());
        shippingService.addAddress(address).then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.ADD_ADDRESS_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.ADD_ADDRESS_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.ADD_ADDRESS_ERROR,error};
    }
}
function getAll(){
    return dispatch => {
        dispatch(request());
        shippingService.getAll().then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.GET_ALL_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.GET_ALL_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.GET_ALL_ERROR,error};
    }
}
function getAmount(payload){
    return dispatch => {
        dispatch(request());
        shippingService.getAmount(payload).then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.GET_AMOUNT_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.GET_AMOUNT_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.GET_AMOUNT_ERROR,error};
    }
}
function getDefault(){
    return dispatch => {
        dispatch(request());
        shippingService.getDefault().then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.GET_DEFAULT_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.GET_DEFAULT_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.GET_DEFAULT_ERROR,error};
    }
    
}
function markDefault(shipping_info){
    return dispatch =>{
        shippingService.markDefault(shipping_info).then(() => dispatch(getAll()), error => console.log(error));
    };
}


function editAddress(payload){
    return dispatch => {
        dispatch(request());
        shippingService.editAddress(payload).then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.EDIT_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.EDIT_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.EDIT_ERROR,error};
    }
}


function deleteAddress(shipping_id){
    return dispatch => {
        dispatch(request());
        shippingService.deleteAddress(shipping_id).then(res => dispatch(success(res)), error => dispatch(failure(error)));

    };
    function request(){
        return {type: shippingConstants.DELETE_REQUEST};
    }
    function success(res){
        return {type: shippingConstants.DELETE_SUCCESS,res};
    }
    function failure(error){
        return {type:shippingConstants.DELETE_ERROR,error};
    }
}