import { brandsConstants } from "../constants";
import { brandsService } from "../services";

export const brandsActions = {
  getBrands
};

function getBrands() {
  return dispatch => {
    dispatch(request());
    brandsService.getBrands()
    .then(res =>  dispatch(success(res)), err => dispatch(error(err)));
  };

  function request(){
    return { type: brandsConstants.GETBRANDS_REQUEST};
  }
  function success(res) {
    return { type: brandsConstants.GETBRANDS_SUCCESS, res };
  }
  function error(err) {
    return { type: brandsConstants.GETBRANDS_ERROR, err};
  }
}
