import React, { Component } from "react";
import appinfo from "../../clientsJSON";
import "../styles.css";

class TeamPopup extends Component {
    state = {
        show : true,
    }

    render() {
        const { show }  = this.state;
        return (
            <div style={{ display: show ? "flex" : "none",
                        backgroundColor: appinfo.SECONDARY_COLOR}}
                 className="teampopup"
                        >
                <div className="teampopup-content">
                    Sample - 
                    <a href="#" 
                       target="_blank" className="teampopup-link">Know more</a>
                </div>
                <div className="teampopup-close"
                    onClick={ () => this.setState({ show: false})}>
                    <i className="material-icons md-18 white-icon">close</i>
                </div>
            </div>
        );
    }
}

export default TeamPopup;