import { profileConstants } from "../constants";

const initialState = {
    about: [],
    profile: [],
    tenant: []
}

export function profile(state = initialState, action) {
  switch (action.type) {
    case profileConstants.PROFILE_REQUEST:
      return {
        ...state,
        status: "PROFILE_REQUEST"
      };
    case profileConstants.PROFILE_SUCCESS:
      return {
        ...state,
        status: "PROFILE_SUCCESS",
        about: action.res
      };
    case profileConstants.PROFILE_ERROR:
      return {
        ...state,
        status: "PROFILE_ERROR",
        about: action.err
      };
    case profileConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        status: "PROFILE_UPDATE_REQUEST"
      };
    case profileConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        status: "PROFILE_UPDATE_SUCCESS",
        profile: action.user,
      };
    case profileConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        status: "PROFILE_UPDATE_ERROR",
        profile : action.error,
      };
    case profileConstants.TENANT_GET_REQUEST:
      return {
        ...state,
        status: "TENANT_GET_REQUEST"
      };
    case profileConstants.TENANT_GET_SUCCESS:
      return {
        ...state,
        status: "TENANT_GET_SUCCESS",
        tenant: action.user,
      };
    case profileConstants.TENANT_GET_FAILURE:
      return {
        ...state,
        status: "TENANT_GET_ERROR",
        tenant : action.error,
      };
    default:
      return state;
  }
  
}
