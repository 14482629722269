import { endpointService } from "../services";
import { endpointConstants } from "../constants";

export const privacyActions = {
    policy
  };

function policy(cloud_id){
    return dispatch =>{
      dispatch(request());
      endpointService.policy(cloud_id).then(res=>dispatch(success(res)), err=> dispatch(error(err)));
    };
    function request(){
      return {type:endpointConstants.GET_POLICY_REQUEST};
    }
    function success(res){
      return {type:endpointConstants.GET_POLICY_SUCCESS};
    }
    function error(err){
      return {type:endpointConstants.GET_POLICY_ERROR};
    }
  }