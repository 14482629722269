import React, {Component} from "react";
import {connect} from "react-redux";
import appinfo from "../../clientsJSON";
import Container from "../container";
import {cartActions} from "../../actions/cart.actions";
import { utils } from "../../utils";
import Cookies from "universal-cookie";
import { cartConstants, inventoryConstants } from "../../constants";
import { cart } from "../../reducers/cart.reducer";
import { inventory } from "../../reducers/inventory.reducer";
import { inventoryActions } from "../../actions";
import container from "../container";
import { styles, white } from "ansi-colors";
import { withRouter } from "react-router-dom";
import cartpage from ".";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf({
    types: [
      {
        type: 'success',
        background: 'green',
        duration:3000,
        dismissible:true,
        position: {
            x: 'center',
            y: 'top',
          },
      },
      {
        type: 'error',
        background: 'indianred',
        duration: 3000,
        dismissible: true,
        position: {
            x: 'center',
            y: 'top',
          },
      }
    ]});

class Cartpage extends Component{
    componentDidMount(){
        const { cart} =this.props;
      }
render(){
    const { cart,dispatch,history } = this.props;
    const cookie = new Cookies();
    this.state={
        loggedIn: cookie.get("loggedIn")
    }
    let inventories=[];
    let total=0;
    for (let i=0;i<cart.partsCount;i++){ 
    let imgsrc= utils.imagePath(utils.parseImagepath(cart.inventories[i].image_path, "x300"));  
    inventories[i]=(
        total=total+cart.inventories[i].recent_selling_price * cart.inventories[i].count,
    <div className="cart-container" style={{
                position: "relative",
                marginLeft: "auto",
                marginRight:"auto",
                paddingTop:2,
                paddingBottom:2,height: "auto", backgroundColor: "#ffffff"}}>
        <div className="product" style={{margin:"16px 4%",display:"flex"}}>   
            <img src={imgsrc} style={{width:128,height:128,margin:"4"}}/>
            <div className="cart-content" style={{padding:8,textAlign:"left"}}>
                <p style={{color: appinfo.PRIMARY_COLOR,fontWeight:800}}>{cart.inventories[i].inventory_name}</p>
                <p>{cart.inventories[i].brand}</p>
                <button style={{padding:4,border:"none",backgroundColor:"white",color:appinfo.PRIMARY_COLOR,cursor:"pointer"}} onClick={()=> dispatch(cartActions.removeFromCart(cart.inventories[i].inventory_id))}>Remove</button>
            </div>
            <div className="spacer"></div>
            <div className="cart-content" style={{display:"flex"}}>
                <div className="Quantity" style={{flexDirection:"row"}}>
                    <p style={{opacity: 0.54}}>Quantity</p>
                    <button  style={{border:"none",margin:4,backgroundColor:appinfo.PRIMARY_COLOR,color:"white",cursor:"pointer",padding:4,fontWeight:900,borderRadius:16}} onClick={()=> {
                        if(cart.inventories[i].count > 1){
                            dispatch(cartActions.updateCart(cart.inventories[i].inventory_id,-1))
                        }                                         
                    }}>-</button>
                    <input style={{textAlign:"center",border:"none",maxWidth:20,fontWeight:900,} }type="number" value={cart.inventories[i].count}/>
                    {(cart.inventories[i].inventory_type == "FLEXI" || (cart.inventories[i].inventory_type == "STRICT" && cart.inventories[i].count < cart.inventories[i].available_qty)) &&
                    <button  style={{border:"none",margin:"0px 4px",backgroundColor:appinfo.PRIMARY_COLOR,color:"white",cursor:"pointer",padding:4,fontWeight:900,borderRadius:16}} onClick={()=>{
                        dispatch(cartActions.updateCart(cart.inventories[i].inventory_id,1))
                    }}>+</button>}
                    {(cart.inventories[i].inventory_type == "STRICT" && cart.inventories[i].count >= cart.inventories[i].available_qty) &&
                    <button  style={{border:"none",margin:"0px 4px",backgroundColor:appinfo.PRIMARY_COLOR,color:"white",cursor:"pointer",padding:4,fontWeight:900,borderRadius:16}} onClick={()=>{notyf.open({type:'error',message:`Only ${cart.inventories[i].available_qty} quantity available`})}}>+</button>}                    
                </div>
                {/* {appinfo.ROLE != 3 && <div className="price">
                    <p style={{opacity: 0.54}}>Tax</p>
                    <p style={{margin:"22px 0px"}} className="price-content">&#8377;{utils.fixtwodecimal(cart.inventories[i].recent_selling_price*cart.inventories[i].tax)/100}</p>
                </div>}
                {appinfo.ROLE != 3 && <div className="price">
                    <p style={{opacity: 0.54}}>Price</p>
                    <p style={{margin:"22px 0px"}} className="price-content">&#8377;{utils.fixtwodecimal(cart.inventories[i].recent_selling_price - ((cart.inventories[i].recent_selling_price*cart.inventories[i].tax)/100))}</p>
                </div>}          */}
                {appinfo.ROLE != 3 &&<div className="price" style={{flexDirection:"row"}}>
                    <p style={{opacity:0.54}}>Total</p>
                    <p style={{margin:"22px 0px"}} className="price-content"> &#8377;{cart.inventories[i].count*cart.inventories[i].recent_selling_price}</p> 
                </div>}
            </div>
        </div>
    </div>
     );}

    return (
    <Container>
        <div className="Cart" style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#1a1b1c",
            width:"100%",
            marginLeft:"auto",
            marginRight:"auto"
        }}>
            <h2>Your Cart</h2>
        {cart.partsCount== 0 ? "Your cart is currently empty." :
        <div>
            <div style={{
                marginLeft: "auto",
                marginRight: "auto",
                width:"fit-content",
                textAlign:"left",
                backgroundcolor: "#ffffff",
                fontSize:14,
                padding:8}}>{cart.partsCount} items in Cart</div>
         
        <div>{inventories.map((s,index) => <div key={index}>{s}</div>)}</div>
        {/* total */}
        { appinfo.ROLE != 3 && <p style={{paddingLeft:20}}>Total: &#8377;{utils.fixtwodecimal(total)}</p>}
        </div>}
        {/* logged in user */}
        {this.state.loggedIn == "true" &&
        <div className="proceedtocheckout">  
            <button style={{ 
                    height:39,
                    borderRadius: 10,
                    backgroundColor:appinfo.PRIMARY_COLOR,
                    color:'white',
                    margin:16,
                    fontWeight:600,
                    padding:"0px 22px 0px 22px",
                    border:"none",
                    cursor:"pointer"
                }} onClick={() =>history.push("/checkout",{page: "checkoutpage"})}> CONTINUE&nbsp;<i class="material-icons"  style={{verticalAlign:"middle",fontSize:15}}>arrow_forward</i> </button>
                </div>
        }
        {/* Not logged in user */}
        {this.state.loggedIn != "true" &&
        <div className="proceedtocheckout">
            <button style={{
                    height:34,
                    borderRadius: 10,
                    backgroundColor:appinfo.PRIMARY_COLOR,
                    color:'white',
                    margin:16,
                    fontWeight:600,
                    cursor:"pointer",
                    padding:"0px 22px 0px 22px",
                    border:"none"
                }} onClick={() =>history.push("/login")}> CONTINUE&nbsp;<i class="material-icons"  style={{verticalAlign:"middle",fontSize:15}}>arrow_forward</i></button>
                </div>
        }        
    </div>
    </Container>
    );
}
}
function mapStateToProps(state) {
    const {cart } = state;
    console.log("vivek",cart);
    return {
      cart
    };
  }
  export default withRouter(connect(mapStateToProps)(Cartpage));