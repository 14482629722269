import { brandsConstants } from "../constants";

const initialState = {
    result: []
};

export function brands(state = initialState, action) {
  switch (action.type) {
    case brandsConstants.GETBRANDS_REQUEST:
      return {
        ...state,
        status: "GETBRANDS_REQUEST"
      };
    case brandsConstants.GETBRANDS_SUCCESS:
      return {
        ...state,
        status: "GETBRANDS_SUCCESS",
        result: action.res
      };
    case brandsConstants.GETBRANDS_ERROR:
      return {
        ...state,
        status: "GETBRANDS_ERROR",
        result: action.err
      };
    default:
      return state;
  }
}
