import {endpointConstants} from "../constants";

export function privacy(state={},action){
    switch(action.type){
        case endpointConstants.GET_POLICY_REQUEST:
            return{
              ...state,
              status:"GET_POLICY_REQUEST",
            }
          case endpointConstants.GET_POLICY_SUCCESS:
            return{
              ...state,
              status:"GET_POLICY_SUCCESS",
            }
        
            case endpointConstants.GET_POLICY_ERROR:
              return{
                ...state,
                status:"GET_POLICY_ERROR",
              };
              default:
                return state;
    }
}