import { dealsConstants } from "../constants";

const initialState = {
  result: []
};

export function deals(state = initialState, action) {
  switch (action.type) {
    case dealsConstants.GET_DEALS_REQUEST:
      return {
        ...state,
        status: "GET_DEALS_REQUEST"
      };
    case dealsConstants.GET_DEALS_SUCCESS:
      return {
        ...state,
        status: "GET_DEALS_SUCCESS",
        result: action.res
      };
    case dealsConstants.GET_DEALS_ERROR:
      return {
        ...state,
        status: "GET_DEALS_ERROR",
        result: action.err
      };
    default:
      return state;
  }
}
