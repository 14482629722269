import React from "react";
import Container from "../container"
import appinfo from "../../clientsJSON";
import "./index.css"

const Aboutus=()=>(
    
    <div>
      
     <Container>
      
      <div className="section">
        <div className="AboutMe-section">
          <h1 style={{color:appinfo.PRIMARY_COLOR}}>About us</h1>
          <p>
            {appinfo.ABOUT_US.map( e => {
                      return (
                        <div style={e.key === "topic" ? styles.topic : styles.para}> 
                          {e.value} 
                        </div>
                      );
                    })
                  }
                  </p>
        </div>
        {/* <div class="Education-section">  
          <h1 style={{color:appinfo.PRIMARY_COLOR}}>Why Zepnur?</h1>
          <p style={{color:appinfo.SECONDARY_COLOR}}>{
                    appinfo.Why_Zepnur.map( e => {
                      return (
                        <div style={e.key === "topic" ? styles.topic : styles.para}> 
                          {e.value} 
                        </div>
                      );
                    })
                  }</p>
        </div> */}
      </div>
      </Container>
    </div>
)
const styles ={
  para:{
    display:"flex",
    flex:1,
    flexDirection:"column",
    marginTop: "10px",
    justifyContent:"center",
    fontSize: "14px",
},
}
export default Aboutus;
