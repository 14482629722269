import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import Login from "./index.js";
import { userActions } from "../../actions/index.js";
import appinfo from "../../clientsJSON";

class Credentials extends Component {
    constructor(){
        super();
        this.state = {
            username: "",
            password: ""
        }
    }

    onChangeText = (event, key) => {
        this.setState ({ [key] : event.target.value })
    }


    handleSubmit = () => {

        const { dispatch } = this.props;

        if( this.state.username != "" &&
            this.state.password != "" ){

                let property1 = document.getElementsByClassName("error-otp")[0];
                property1.innerHTML = "";
                property1.innerText = "";
    
                let payload = {
                    username : this.state.username,
                    password : this.state.password
                }

                dispatch(userActions.login(payload.username, payload.password));
        }
        else{

            if( this.state.username == ""){
                let property = document.getElementsByName("username")[0];
                property.style.border = "0.7px solid rgb(255,0,0)";
                property.placeholder = "Please enter valid email";
            }else{
                let property = document.getElementsByName("password")[0];
                property.style.border = "0.7px solid rgb(255,0,0)";
                property.placeholder = "Please enter valid Password";   
            }

        }

    }

    componentDidUpdate(){

        const { authentication, history, cart } = this.props;
        
        const cookie = new Cookies;

        if(authentication.loggedIn && authentication.user.code == 200){
            
            cookie.set("loggedIn","true",{path: "/"});

            if(cart.partsCount > 0){
                history.push("/checkout");
            } else {
                history.push("/home");
            }

        }
        else if(authentication.loggedIn == false && authentication.error.code == 403){
            
            let property1 = document.getElementsByClassName("error-otp")[0];
            property1.innerHTML = "Incorrect username and password combimation";
            property1.innerText = "Incorrect username and password combimation";

            let property = document.getElementsByName("username")[0];
            property.style.border = "0.7px solid rgb(255,0,0)";

            let property2 = document.getElementsByName("password")[0];
            property2.style.border = "0.7px solid rgb(255,0,0)";

        }
        else if(authentication.loggedIn ==  false){
            alert(authentication.error.message);
            window.location.reload();
        }

    }

    render() {

        const { history } = this.props;

        return (
        <Login history={history}>
                <div className="login-child credential">
                    <div className="login-title">
                    Login to your {appinfo.APP_FOR} account   
                    </div>
                    <div className="error-otp"></div>
                    <div className="login-otp-input">
                        <input 
                            type="text"
                            name="username"
                            placeholder="Email"
                            className="mobile-input"
                            onChange={(event)=>{this.onChangeText(event, "username")}}
                        />
                        <input 
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="mobile-input pass"
                            onChange={(event)=>{this.onChangeText(event, "password")}}
                        />
                    </div>
                    {/* <div className="resend-otp">
                        Forgot Password
                    </div> */}
                    <div className="login-mobile-button less-space" style={{backgroundColor:appinfo.PRIMARY_COLOR}} onClick={()=>{this.handleSubmit()}}>
                        <div style={{paddingTop: "11px"}}>LOGIN</div>
                    </div>
                    <div className="login-redirect" style={{color:appinfo.PRIMARY_COLOR}} onClick={()=>{history.push("/login")}}>
                        LOGIN WITH MOBILE NUMBER
                    </div>
                </div>   
        </Login>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, cart } = state;
  
    return {
      authentication, cart
    };
  }
  
  export default connect(mapStateToProps)(Credentials);