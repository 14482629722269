import { demoConstants } from "../constants";
import { demoService } from "../services";

export const demoActions = {
    demo
};

function demo(payload) {
    return dispatch => {
      dispatch(request());
      demoService
        .demo(payload)
        .then(res => dispatch(success(res)), err => dispatch(error(err)));
    };
    function request() {
      return { type: demoConstants.DEMO_REQUEST };
    }
    function success(res) {
      return { type: demoConstants.DEMO_SUCCESS, res };
    }
    function error(err) {
      return { type: demoConstants.DEMO_ERROR, err };
    }
  }