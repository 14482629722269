import React, { Component } from "react";
import "../../screens/styles.css";
import CallLogo from "../../images/icon-with-circle/icon-with-circle@3x.png";
import appinfo from "../../clientsJSON";
class MayIHelpYou extends Component {
    constructor() {
        super();
        this.state = {
            hover: -1
        }
    }

    render() {
        return (
            <div>
                <div className="call-logo"
                    onMouseEnter={() => { this.setState({ hover: 1 }) }}
                    onMouseLeave={() => { this.setState({ hover: -1 }) }}>
                    <a href={"tel:"+appinfo.PHONE}
                        style={{ textDecoration: "none" }}>
                        <img src={CallLogo} width="70px" height="70px" color={appinfo.SECONDARY_COLOR} />
                    </a>
                </div>
                <div className="call-logo-tool-tip"
                    style={{ display: window.screen.width < 768 ? "none" : this.state.hover == -1 ? "none" : "block" }}
                >
                    <div style={{
                        paddingTop: "20px",
                        opacity: 0.7,
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "center",
                    }}>
                        Need help?
                </div>
                    <div style={{
                        paddingTop: "6px",
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "center",
                    }}>
                        Call <b>{appinfo.PHONE}</b>
                    </div>
                </div>
            </div>
        );
    }
}

export default MayIHelpYou;