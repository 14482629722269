import {discountConstants} from "../constants";

const initialState ={
    result: []
};

export function discount(state=initialState,action){
    switch (action.type) {
        case discountConstants.DETAILS_REQUEST:
            return{
                ...state,
                status:"COUPON_DETAILS_REQUEST"
            }
        case discountConstants.DETAILS_SUCCESS:
            return{
                ...state,
                details:action.res,
                status:"COUPON_DETAILS_SUCCESS"
            }
        case discountConstants.DETAILS_ERROR:
            return {
                ...state,
                error:action.error,
                status:"COUPON_DETAILS_ERROR"
            }
        case discountConstants.APPLY_REQUEST:
            return {
                ...state,
                status:"COUPON_APPLY_REQUEST"
            }
        case discountConstants.APPLY_SUCCESS:
            return {
                ...state,
                result:action.res,
                status:"COUPON_APPLY_SUCCESS"
            }
        case discountConstants.APPLY_ERROR:
            return {
                ...state,
                error:action.error,
                status:"COUPON_APPLY_ERROR"
            };
        default:
            return state;
    }
}