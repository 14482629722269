import React, {Component} from "react";
import { connect } from "react-redux";
import Container from "../container";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils";
import {Helmet} from "react-helmet";
import ReactImageMagnify from "react-image-magnify";
import WhatsApp from "../../images/whatsapp/WhatsApp.png";
import { ShowMore, ProductPageLoader, Loader, BreadCrumbs } from "../../components";
import { demoActions } from "../../actions";
import Cookies from "universal-cookie";
import Modal from 'react-responsive-modal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

class Bundle extends Component {
    constructor() {
        super();
        const cook = new Cookies();
        this.state = {
          imgSrc: "",
          largeimgSrc: "",
          from : "",
          name: "",
          email: "",
          phone: "",
          healthcare: "",
          facility: "",
          id: "" ,
          loggedIn : cook.get("loggedIn"),
          currentimage: 0,
          open: false,
          phoneError: false,
          processing: false,
          reference: ""
        };
      }
    changeImage = (image, index) => {
        this.setState({
          imgSrc: utils.imagePath(image, "x300"),
          largeimgSrc: utils.imagePath(image, "x600"),
          currentimage: index
        });
      };
    renderPackageProducts = (inventoryToShow) => {
        let stackList = [];
        for (let i=0;i<inventoryToShow.products.length;i++){
            let imgSrc = utils.imagePath(inventoryToShow.products[i].image_path, "x300");
            stackList[i] = (
                <div className="packageProducts" style={{backgroundColor:'white',marginTop:32,marginBottom:32,marginRight:'auto',marginLeft:'auto',padding:16,maxWidth:980}}>
                    <div>
                        <img src={imgSrc}/>
                    </div>
                    <div style={{padding:16}}>
                        <p style={{color:appinfo.SECONDARY_COLOR,fontWeight:600}}>{inventoryToShow.products[i].inventory_name}</p>
                        <p style={{color:'#2030478a'}}>{inventoryToShow.products[i].sub_title}</p>
                        <p style={{color:'#000000de'}}>{inventoryToShow.products[i].description}</p>
                    </div>
                </div>
            );
        }
            return (
                <div>{stackList.map((s,index) => <div key={index}>{s}</div>)}</div>
            )
    }
    renderPackageInfo = (inventoryToShow) => {
        const { match } = this.props;
        let images = [];
          document.title = inventoryToShow.inventory_name + " -" + appinfo.APP_FOR;
          let imageNameProduct = [];
          if (inventoryToShow.image_path != null) {
            imageNameProduct = inventoryToShow.image_path.split(",");
            for (let i = 0; i < imageNameProduct.length; i++) {
              if (imageNameProduct[i] != "") {
                let ProductImage = utils.imagePath(imageNameProduct[i], "x300");
                images[images.length] = (
                  <div
                    className="image-container"
                    onClick={() => this.changeImage(imageNameProduct[i], i)}
                    style={{
                      marginLeft: 16,
                      cursor: "pointer",
                    }}
                  >
                    <img style={{ width: 60, height: 60 }} src={ProductImage} />
                  </div>
                );
              }
            }
          }
    
          let imgSrc = utils.imagePath(imageNameProduct[0], "x300");
          let largeimgSrc = utils.imagePath(imageNameProduct[0], "x600");
          let cloud_id = appinfo.X_CLOUD_ID;
          let token = btoa(`{"cloud_id":${cloud_id}}`);
          let endpoint = appinfo.ENDPOINTS.URI;
          let filename = "";
          let url = `${endpoint}/`;
          let urlID=`${appinfo.ENDPOINTS.URI}/product/${inventoryToShow.inventory_id}/${inventoryToShow.inventory_name.replace(/\s+/g, '-').toLowerCase()}`;
          let whatsapp=`https://wa.me/?text=${appinfo.whatsapp} ${inventoryToShow.inventory_name} ${appinfo.WEBSITE}/product/${inventoryToShow.inventory_id}/${inventoryToShow.inventory_name.replace(/\s+/g, '-').toLowerCase()}`;
    
          return (
            <>
            <Helmet>
              <title>Hi{inventoryToShow.inventory_name}</title>
              <meta name="description" content={inventoryToShow.description}/>
              <meta property="og:type" content="website" />
              <meta property="og:title" content={inventoryToShow.inventory_name} />
              <meta property="og:url" content={urlID}/>
              <meta property="og:description" content={inventoryToShow.description} />
              <meta property="og:image" content={imgSrc} />
            </Helmet>
              <div className="body-product">
                <div className="image-product" style={{zIndex:1}}>
                <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: inventoryToShow.inventory_name,
                        isFluidWidth: true,
                        src: imgSrc
                      },
                      largeImage: {
                        src: largeimgSrc,
                        width: 1000,
                        height: 1000,
                        zIndex:1
                      }
                    }}
                  />
    
                  <div className="images">
                    {/* {images} */}
                  </div>
                </div>
                <div className="body-info-product">
                  <h1 className="product-title">
                    {inventoryToShow.inventory_name}
                  </h1>
                  <div className="product-subtitle">
                    {inventoryToShow.products.length} Products per package
                  </div>
                  <div className="product-price" style={{paddingTop:12}}>
                    {/* ₹ */}
                    {inventoryToShow.recent_selling_price}
                  </div>
                  {(inventoryToShow.mask_price == 0) && (
                  <div className="" style={{marginTop:16,backgroundColor:'#D2D2D2',color:appinfo.SECONDARY_COLOR,fontWeight:600,height:40,fontSize:14,padding:10}}>
                    Joining will help you to buy this package at low price
                  </div>)}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  {(inventoryToShow.mask_price == 0)   ? (
                    <button
                      onClick={()=>this.requestRedirect("DEAL",url)}
                      className="demo-button"
                      style={{
                        marginTop: 25,
                        backgroundColor: appinfo.PRIMARY_COLOR,
                        color: "#ffffff",
                        fontWeight: 600,
                        cursor: "pointer",
                        border:"none"
                      }}
                    >
                      JOIN DEAL
                    </button> ) : (
                    <button
                      onClick={()=>this.requestRedirect("QUOTE",url)}
                      className="demo-button"
                      style={{
                        marginTop: 25,
                        backgroundColor: appinfo.PRIMARY_COLOR,
                        color: "#ffffff",
                        fontWeight: 600,
                        cursor: "pointer",
                        border:"none"
                      }}
                    >
                      REQUEST TO BUY
                    </button> )
                  }
                    <a href={encodeURI(whatsapp)} target="_blank"><img src={WhatsApp} style={{height:32,marginTop:28,marginLeft:8}}/></a>
                  </div>
                  {/* <hr className="line" /> */}
    
                  <ShowMore md={inventoryToShow.description} />
    
    
                  <div className="product-subtitle" style={{ fontSize: 12 }}></div>
                  <div className="product-price"></div>
                  {/* <hr className="line" /> */}
                </div>
              </div>
            </>
          );
        
      };
    renderPackageList =(inventoryToShow) => {
        return (
            <div style={{marginTop:32,marginBottom:32,marginRight:'auto',marginLeft:'auto',padding:16,maxWidth:980}}>
                <p style={{color:'#203047',fontWeight:'bold'}}>Products included in this package ({inventoryToShow.products.length})</p>
                {this.renderPackageProducts(inventoryToShow)}
            </div>
        )
    }
    requestRedirect = ( from, url) => {
        const { history, profile, dispatch } = this.props;
          this.setState({ 
              from: from , 
              open: this.state.loggedIn !== "true" ? true : false, 
              url : url
            });
          if( this.state.loggedIn === "true") {
            let payload = {
              approver_id: 2,
              type: 1,
              message: JSON.stringify({
                email: profile.about.myself.email,
                name: profile.about.myself.name,
                phone: profile.about.myself.mobile,
                healthcarename: this.state.healthcare,
                facility: this.state.facility,
                requestedtype: 1,
                website: "",
                msg : "PACKAGE BUY",
                id: this.state.id,
                fromPage : from,
                reference: this.state.reference,
                mobile_no: profile.about.myself.mobile,
              }),
              status: 1
            }
            dispatch(demoActions.demo(payload));
          }
      };
      renderModal = () => {
        return (
            <Modal 
              showCloseIcon={false}
              open={this.state.open} 
              onClose={this.onCloseModal} 
              center={true}>
                            
                  <h4 style={{color: "rgba(32, 48, 71, 0.54)",
                              marginBlockStart: "4px", marginBlockEnd: "4px"}}>
                    Fill the Below form to { this.state.from == "QUOTE" && (<span>send the request</span>)}{ this.state.from == "CATALOGUE" && (<span>download catalogue</span>)}{ this.state.from == "DEAL" && (<span>join the deal</span>)}
                  </h4>
                  <input
                    type="text"
                    className="input mod"
                    placeholder="Name"
                    name="name"
                    onChange={this.handleChange}
                  />
                  <PhoneInput
                    country="IN"
                    name="phone"
                    className={this.state.phoneError ? "input line mod input-error" : "input mod line"}
                    placeholder="Enter phone number"
                    value={this.state.phone}
                    onChange={phone => this.setState({ phone })} />
                  <input
                    type="email"
                    className="input mod"
                    placeholder="Email ID"
                    name="email"
                    onChange={this.handleChange}
                  /> 
                    <select className="input-select mod" 
                            onChange={(event)=>{this.setState({facility: event.target.value})}}
                            selectedValue={this.state.facility}>
                      <option value="">Type of Facility</option>
                      <option value="clinic">Clinic</option>
                      <option value="diagnostic centre">Diagnostic Centre</option>
                      <option value="hospital">Hospital</option>
                      <option value="nursing home">Nursing Home</option>
                      <option value="others">Others</option>
                    </select>
    
                    <input
                      type="text"
                      className="input mod"
                      placeholder="Name of Medical Facility"
                      name="healthcare"
                      onChange={this.handleChange}
                    />
                    <input
                      type="text"
                      className="input"
                      placeholder="Referral code ( optional )"
                      name="reference"
                      onChange={this.handleChange}
                    />
    
                    { this.state.processing ? 
                      <Loader />
                      :
                      <button style={{backgroundColor:appinfo.PRIMARY_COLOR}} className="demo-button-demo"
                                onClick={()=>this.handleSubmit()}>
                        { this.state.from == "QUOTE" && (<span>SEND REQUEST</span>)}{ this.state.from == "CATALOGUE" && (<span>DOWNLOAD CATALOGUE</span>)}{ this.state.from == "DEAL" && (<span>JOIN DEAL</span>)}
                      </button>
                    }
    
            </Modal>
            );
      }
      onCloseModal = () => {
        this.setState({ open: false });
      };
      handleSubmit = () => {

        const { dispatch } =this.props;
  
        if (
          this.state.name != "" &&
          this.state.email != "" &&
          this.validatePhoneInput() &&
          /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)
        ) {    
            let property = document.getElementsByName("name")[0];
            let property1 = document.getElementsByName("phone")[0];
            let property2 = document.getElementsByName("email")[0];
            property.style.border = "0.5px solid rgb(219,219,219)";
            property2.style.border = "0.5px solid rgb(219,219,219)";
  
              let payload = {
                approver_id: 2,
                type: 1,
                message: JSON.stringify({
                  email: this.state.email,
                  name: this.state.name,
                  phone: this.state.phone,
                  healthcarename: this.state.healthcare,
                  facility: this.state.facility,
                  requestedtype: 1,
                  website: "",
                  msg : "PACKAGE BUY",
                  id: this.state.id,
                  fromPage : this.state.from,
                  reference: this.state.reference,
                  mobile_no: this.state.phone,
                }),
                status: 1
              };
            dispatch(demoActions.demo(payload));
            this.setState({ processing: true });
        
        } else {
          if (this.state.name == "") {
            let property = document.getElementsByName("name")[0];
            property.style.border = "0.7px solid rgb(255,0,0)";
            property.placeholder = "Please enter valid Name";
          }
          if (!this.validatePhoneInput()) {
            let property = document.getElementsByName("phone")[0];
            property.placeholder = "Please enter valid Phone Number";
          }
          if (this.state.email == "" || ! /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)) {
            let property = document.getElementsByName("email")[0];
            property.style.border = "0.7px solid rgb(255,0,0)";
            property.placeholder = "Please enter valid Email ID";
          }
        }
  
    }
    validatePhoneInput = () => {
        const { phone, phoneError } = this.state;
        let valid = isValidPhoneNumber(phone || "");
    
        this.setState({
          phoneError: !valid
        })
    
        return valid;
      }
      handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        let property = document.getElementsByName(event.target.name)[0];
        property.style.border = "0.5px solid rgb(219,219,219)";
      };
      componentDidUpdate(nextProps){
        const { demo, history } = this.props;
        let hasdemoChanged = demo !== nextProps.demo;
    
        if( hasdemoChanged && demo.status === "DEMO_SUCCESS" ){
          this.setState({ processing: false, open: false });
          if( this.state.from === "QUOTE" ) {
            history.push("/success-bundle", {page: "demo", message : appinfo.CONTENTS.successPageMessage });  
          } else {
            window.open(this.state.url, "_blank");
          }
        } else if ( hasdemoChanged && demo.status === "DEMO_ERROR" ) {
          this.setState({  processing : false });
        }
      }
    
    render(){
        const { match } = this.props;
        let bundleToShow=appinfo.bundle[match.params.id];
        return (
            <Container menu={false}>
                {this.renderPackageInfo(bundleToShow)}
                {this.renderModal()}
                {this.renderPackageList(bundleToShow)}
            </Container>
        )
    }
}
function mapStateToProps(state) {
    const { catalog, demo, inventory, profile, skudoc } = state;
  
    return {
      catalog,
      demo,
      inventory,
      profile,
      skudoc
    };
  }
  
  export default connect(mapStateToProps)(Bundle);
