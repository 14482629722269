import {shippingConstants} from "../constants";
const initialState ={
    result: []
};
export function shipping(state=initialState,action){
   switch (action.type) {
       case shippingConstants.ADD_ADDRESS_REQUEST:
           return{
               ...state,
               status:"ADD_ADDRESS_REQUEST"
           };

        case shippingConstants.GET_ALL_REQUEST:
            return{
                ...state,
                status:"GET_ALL_REQUEST"
            };
        case shippingConstants.GET_AMOUNT_REQUEST:
            return{
                ...state,
                status:"GET_AMOUNT_REQUEST"
            };
        case shippingConstants.GET_DEFAULT_REQUEST:
            return{
                ...state,
                status:"GET_DEFAULT_REQUEST"
            };
        case shippingConstants.EDIT_REQUEST:
                return{
                    ...state,
                    status:"EDIT_SHIPPING_REQUEST"
                };
        case shippingConstants.DELETE_REQUEST:
                    return{
                        ...state,
                        status:"DELETE_SHIPPING_REQUEST"
                    };
        case shippingConstants.ADD_ADDRESS_SUCCESS:
            return{
                ...state,
                status:"ADD_ADDRESS_SUCCESS"
            };
        case shippingConstants.GET_ALL_SUCCESS:
            // let address=[...action.res.address];
            // let sortedaddress= address.reverse();
            // let defaultIndex = sortedaddress.findIndex(e => {
            //     return e.default ==1;
            // });
            // if(defaultIndex >=0){
            //     sortedaddress =utils.array_move(sortedaddress,defaultIndex,0);

            // }
            return{
                ...state,
                // address: sortedaddress,
                status:"GET_ALL_SUCCESS",
                result: action.res
            };
        case shippingConstants.GET_AMOUNT_SUCCESS:
                    return {
                      ...state,
                      amount: action.res.message,
                      status: "GET_AMOUNT_SUCCESS"
                    };
        case shippingConstants.GET_DEFAULT_SUCCESS:
                    return {
                      ...state,
                      default: action.res.address || [],
                      status: "GET_DEFAULT_SUCCESS"
                    };
       case shippingConstants.EDIT_SUCCESS:
                return{
                    ...state,
                     result: action.res,
                    status:"EDIT_SHIPPING_SUCCESS"
                };
     case shippingConstants.DELETE_SUCCESS:
                 return{
                        ...state,
                        deleteaddress: action.res,
                        status:"DELETE_SHIPPING_SUCCESS"
                 };               
        case shippingConstants.ADD_ADDRESS_ERROR:
                    return {
                      ...state,
                      error: action.error,
                      status: "ADD_ADDRESS_ERROR"
                    };
        case shippingConstants.GET_ALL_ERROR:
                    return {
                      ...state,
                      error: action.error,
                      status: "GET_ALL_ERROR"
                    };
        case shippingConstants.GET_DEFAULT_ERROR:
                    return {
                      ...state,
                      error: action.error,
                      status: "GET_DEFAULT_ERROR"
                    };
        case shippingConstants.EDIT_ERROR:
                  return{
                            ...state,
                            error: action.error,
                            status:"EDIT_SHIPPING_ERROR"
                        };
      case shippingConstants.DELETE_ERROR:
                            return{
                                   ...state,
                                   error: action.error,
                                   status:"DELETE_SHIPPING_ERROR"
                            };  
              
   
       default:
           return state;
   }
}