export const endpointConstants = {
    HELLO_REQUEST: "ENDPOINT_HELLO_REQUEST",
    HELLO_ERROR: "ENDPOINT_HELLO_ERROR",
    TERMS_CONDITIONS_REQUEST: "ENDPOINT_TERMS_CONDITIONS_REQUEST",
    HELLO_SUCCESS: "ENDPOINT_HELLO_SUCCESS",
    TERMS_CONDITIONS_SUCCESS: "ENDPOINT_TERMS_CONDITIONS_SUCCESS",
    TERMS_CONDITIONS_ERROR: "ENDPOINT_TERMS_CONDITIONS_ERROR",
    GET_POLICY_SUCCESS:"ENDPOINT_GET_POLICY_SUCCESS",
    GET_POLICY_ERROR:"ENDPOINT_GET_POLICY_ERROR",
    GET_POLICY_REQUEST:"ENDPOINT_GET_POLICY_REQUEST",
};
  