import React from "react";
import Container from "../container"
import appinfo from "../../clientsJSON";
import "./index.css"

const Team=()=>(    
      <div>
        <Container>
            <div style={{backgroundColor:"#E0E5EC",padding:"60px 0px"}}>
            <h1  style={{color:appinfo.PRIMARY_COLOR ,textAlign:"center",margin:0}}>{appinfo.TeamName}</h1>
          <div className="team">
          <p style={{color:appinfo.SECONDARY_COLOR}}> {
                    appinfo.OUR_MS.map( e => {
                      return (
                        <div style={e.key === "topic" ? styles.topic : styles.para}> 
                          {e.value} 
                        </div>
                      );
                    })
                  }</p>
          </div>
          <div className="team">
          <p style={{color:appinfo.SECONDARY_COLOR}}> {
                    appinfo.OUR_CMO.map( e => {
                      return (
                        <div style={e.key === "topic" ? styles.topic : styles.para}> 
                          {e.value} 
                        </div>
                      );
                    })
                  }</p>
          </div></div>
        </Container>
    </div>
)
const styles ={
    para:{
      display:"flex",
      flex:1,
      padding:"5px 20px",
      flexDirection:"column",
     
      justifyContent:"center",
      fontSize: "14px",
      

  },
  topic:{
    display:"flex",
    flex:1,
    color: appinfo.SECONDARY_COLOR,
    flexDirection:"column",
    padding:"10px 20px",
    fontWeight:"bold",
    alignContent:"center",
    justifyContent:"center",
    fontSize: "16px",
}
  }

export default Team;