import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "../container"; 
import appinfo from "../../clientsJSON";
import {privacyActions} from "../../actions"
import { Loader } from "../../components";

class Policy extends Component{
    componentDidMount(){
        const { dispatch } =this.props;
        dispatch(privacyActions.policy(appinfo.X_CLOUD_ID));
      }
    
    render(){
      const{privacy}=this.props;
      if(privacy.status =="GET_POLICY_SUCCESS"){
    return(
        <Container>
             <div style={styles.container}>
                <div style={styles.title}>Privacy Policy</div>
                <div style={styles.innerContainer}>
                  
                </div>
                </div>
            </Container>

    );}
  else{
    return(
      <Loader/>
    );
  }
  }
}
const styles = {
  container:{
     display:"flex",
     flexDirection:"column",
     flex:1,
     marginBottom: 60
  },
  title:{
      color: appinfo.PRIMARY_COLOR,
      paddingTop:"20px",
      fontSize: "24px",
      margin: "auto",

  },
  innerContainer:{
      marginTop: "10px",
      paddingLeft:"70px",
      paddingRight: "70px",
  },
  para:{
      display:"flex",
      flex:1,
      flexDirection:"column",
      marginTop: "10px",
      justifyContent:"center",
      fontSize: "14px",
  },
  topic:{
      display:"flex",
      flex:1,
      flexDirection:"column",
      marginTop: "10px",
      color: appinfo.SECONDARY_COLOR,
      fontWeight:"bold",
      alignContent:"center",
      justifyContent:"center",
      fontSize: "16px",
  },
  subPara:{
      display:"flex",
      flex:1,
      flexDirection:"column",
      alignContent:"center",
      marginTop: "10px",
      justifyContent:"center",
      fontSize: "14px",
  }
};

function mapStateToProps(state) {
    const { privacy } = state;
  
    return {
       privacy
    };
  }
  
  const connectedPolicy = connect(mapStateToProps)(Policy);
  export default connectedPolicy;