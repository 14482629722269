import { signupConstants } from "../constants";

const initialState = {
    result: []
  };

export function signup(state = initialState, action) {
    switch (action.type) {
        case signupConstants.OTPSIGNUP_REQUEST:
            return {
                ...state,
                status:"OTP_VERIFICATION_REQUEST"
            }
        case signupConstants.OTPSIGNUP_SUCCESS:
                return {
                    ...state,
                    status:"OTP_VERIFICATION_SUCCESS",
                    result:action.message
                }  
        case signupConstants.OTPSIGNUP_ERROR:
                return {
                    ...state,
                    status:"OTP_VERIFICATION_ERROR",
                    result : action.error,
                }                           
        case signupConstants.SIGNUP_REQUEST:
            return {
                ...state,
                status:"SIGNUP_AUTO_REQUEST"
            }
        case signupConstants.SIGNUP_SUCCESS:
                return {
                    ...state,
                    status:"SIGNUP_AUTO_SUCCESS",
                    result: action.message,
                } 
        case signupConstants.SIGNUP_ERROR:
                return {
                    ...state,
                    status:"SIGNUP_AUTO_ERROR",
                    result : action.error,
                } 
        default:
                return state;
    }
}