import React, { Component } from "react";
import { connect } from "react-redux";
import {signupActions, userActions} from "../../actions";
import appinfo from "../../clientsJSON";
import Cookies from "universal-cookie";
import notyf from "../../utils/notification"
class Verify extends Component {
        constructor() {
          super();
          this.state = {
            otp: "",
            account:false
          };
        }

    resendotp = () => {
      const { dispatch, history } = this.props;
      let payload = {
        mobile_no : history.location.state.phone
      }
      dispatch(signupActions.otpVerify(payload));
        let property = document.getElementsByClassName("resend-otp")[0];
        property.innerHTML = "";
        property.innerText = "";
        
    }
    
    handleSubmit = () => {
        const { dispatch,history } = this.props;
        let payload= {
            "tenant_name":history.location.state.email,
            "name":history.location.state.name,
            "user_name":history.location.state.email,
            "mobile":history.location.state.phone,
            "email":history.location.state.email,
            "street_1":history.location.state.street1,
            "street_2":history.location.state.street2,
            "otp":this.state.otp,
            "type":"Partner",
            "lat":"1.0000",
            "lon":"1.0000",
            "pincode":history.location.state.pincode,
            "city":history.location.state.city,
            "state":history.location.state.state,
            }
        if(this.state.otp > 0){
          dispatch(signupActions.signupAuto(payload));
        } else {
          let otp=document.getElementsByName("otp")[0];
          otp.style.borderColor="red";
        }
    } 
    handleLogin = () => {
      const { dispatch,history } = this.props;
      let payload1 = {
        mobile_no : history.location.state.phone,
        otp: this.state.otp
    }
        dispatch(userActions.otplogin(payload1));  
  }     
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        let property = document.getElementsByName(event.target.name)[0];
        property.style.border = "0.5px solid rgb(219,219,219)";
      };  
    componentWillReceiveProps(newProps){
      const {signup,history,user,dispatch, cart}=newProps;
      const cookie = new Cookies();
      if(signup.status == "SIGNUP_AUTO_SUCCESS"){
        this.setState({account:true})
      }
      if(user.status == "OTPLOGIN_SUCCESS" && user.otp.code == 200 ){
            
        cookie.set("loggedIn","true",{path: "/"});
        // Need to add it here
        if(cart.partsCount > 0){
          history.push("/checkout");
      } else {
          history.push("/home");
      }

      }
      else if( user.status == "OTPLOGIN_ERROR" ){
          
        notyf.open({type:"error",message:user.otp.message});
          window.location.reload();

      }      
    }  
    componentDidUpdate(){
      
    }    
    render(){
        const { history,signup,user } = this.props;
        return (
            <div style={{ display: "flex" }}>
              <div className="demo" style={{backgroundColor:appinfo.PRIMARY_COLOR,flex:1,position:"relative",minHeight:720}}>
                <div className="insidedemo" style={{position:"absolute"}}>
                    <img
                    onClick = {() => history.push("/home")}
                    className="titleimage"
                    src={appinfo.logowhite}
                    alt={"Title"}
                    /> 
                    <div style={{color:"white",textAlign:"center",padding:20,marginTop:200}}>
                      <div>{appinfo.CONTENTS.submitFormPage}
                          <div style={{display:"flex",padding:20}}>
                          {
                            appinfo.stats.map( (indStats,index) => {
                              return (
                                <div key={index} style={{padding:8}}>
                                  <div style={{ fontSize: 22, fontWeight: "bold" }}>{indStats.value}{indStats.adder}</div>
                                  <div style={{ fontSize: 16, fontWeight: "600" }}>
                                    {indStats.key}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>                      
                    </div>                 
                </div>
              </div>
              <div style={{flex:1,display:"flex",flexDirection:"column",padding:20,marginTop:240}}> 
                {!this.state.account && <div>
                    <input name="otp" type="text" className="input" placeholder="Enter OTP" onChange={this.handleChange}/>
                  {(signup.status == "SIGNUP_AUTO_ERROR") && <div><p style={{color:"red"}}>{signup.result.message}</p><p onClick={()=>history.push("/login")} style={{color:appinfo.PRIMARY_COLOR,fontSize:12,cursor:"pointer"}}>Already have account? Login</p></div>}
                  <div style={{color:appinfo.PRIMARY_COLOR}} className="resend-otp" onClick={()=>{this.resendotp()}}>
                        Resend OTP
                  </div>
                  <p><button onClick={this.handleSubmit} style={{height:34,border:"none",color:"white",backgroundColor:appinfo.PRIMARY_COLOR,borderRadius:16,padding:"0px 22px 0px 22px",cursor:"pointer",fontWeight:600}}>SUBMIT</button></p> 
                </div> }
                {this.state.account && <div>
                  {/* {(user.status == "OTPLOGIN_SUCCESS" && user.otp.code == 200) && <div></div>} */}
                  <p className ="signupsuccess" style={{color:"green"}}>Success! New Account Created!</p> <button onClick={this.handleLogin} style={{height:34,border:"none",color:"white",backgroundColor:appinfo.PRIMARY_COLOR,borderRadius:16,padding:"0px 22px 0px 22px",cursor:"pointer",fontWeight:600}}>START BUYING</button>
                </div> }                              
              </div> 
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { signup, user, cart } = state;
    return {
        signup, user, cart
    };
  }
  
export default connect(mapStateToProps)(Verify);