import React, { Component } from "react";
import { connect } from "react-redux";
import DateTimePicker from 'react-datetime-picker'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Cookies from "universal-cookie";
import phone from "../../images/phone-success/phone-success.png";
import mail from "../../images/mail-success/mail-success.png";
import "./demo.css";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils";
import { demoActions, userActions, profileActions } from "../../actions";
import 'react-phone-number-input/style.css'
import { MayIHelpYou } from "../../components";

class Demo extends Component {
  constructor() {
    super();
    const cook = new Cookies();
    this.state = {
      name: "",
      email: "",
      phone: "",
      healthcare: "",
      msg: "",
      id: cook.get("id"),
      checked: 1,
      facility: "",
      website: "",
      date: null,
      fromPage: cook.get("fromPage"),
      loggedIn : cook.get("loggedIn"),
      phoneError: false,
      reference: "",
      mobile_no: "",
    };
  }

  componentDidMount(){
    const { profile, dispatch } = this.props;
    document.title = appinfo.TITLE;
    if(profile.status != "PROFILE_SUCCESS" && this.state.loggedIn == "true"){
      dispatch(profileActions.whoami());
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    let property = document.getElementsByName(event.target.name)[0];
    property.style.border = "0.5px solid rgb(219,219,219)";
  };

  validatePhoneInput = () => {
    const { phone, phoneError } = this.state;
    let valid = isValidPhoneNumber(phone || "");

    this.setState({
      phoneError: !valid
    })

    return valid;
  }

  componentDidUpdate(prevProps){
    const { demo, history, user } = this.props;

    let message = appinfo.CONTENTS.successPageMessage;

    if(this.props.demo != prevProps.demo){
      if(demo.status=="DEMO_SUCCESS" ){
        if(this.state.fromPage == "CONTACT_US"){
          history.push("/success-contactus", {page: "demo", message : message });           
        } else if(this.state.fromPage == "BOOK_NOW") {
          history.push("/success-audit", {page: "demo", message : message });           
        }  else {
          history.push("/success", {page: "demo", message : message });            
        }                
      }else if(demo.status=="DEMO_ERROR"){
        alert (demo.results.message)
      }
    }
    if(this.props.user != prevProps.user){
      if(user.status == "SIGNUP_SUCCESS"){
        history.push("/success-signup", {page: "signup", message : message });                    
      }else if(user.status=="SIGNUP_ERROR"){
        alert (user.result.message);
      }
    }
  }

  onChange = date => {
    this.setState({ date });
  }

  handleDemoSubmit = () => {
    const { dispatch, history, profile } = this.props;
    let pageData = history.location.state;

    if(this.state.loggedIn != "true"){
      if (
        this.state.name != "" &&
        this.state.email != "" &&
        this.validatePhoneInput() &&
        /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)
      ) {    
          let property = document.getElementsByName("name")[0];
          let property1 = document.getElementsByName("phone")[0];
          let property2 = document.getElementsByName("email")[0];
          property.style.border = "0.5px solid rgb(219,219,219)";
          property2.style.border = "0.5px solid rgb(219,219,219)";

          if(pageData && pageData.page == "demo"){
            
            let payload = {
              approver_id: 2,
              type: 1,
              message: JSON.stringify({
                email: this.state.email,
                name: this.state.name,
                phone: this.state.phone,
                healthcarename: this.state.healthcare,
                facility: this.state.facility,
                requestedtype: this.state.checked,
                website: this.state.website,
                msg: this.state.msg,
                id: this.state.id,
                date_to_contact: this.state.date,
                fromPage : this.state.fromPage,
                reference: this.state.reference,
                mobile_no:this.state.phone
              }),
              status: 1
            };
          dispatch(demoActions.demo(payload));
          }else{

          let payload = {
            email: this.state.email,
            name: this.state.name,
            mobile_no: this.state.phone,
          }
          dispatch(userActions.signup(payload));
        }
      
      } else {
        if (this.state.name == "") {
          let property = document.getElementsByName("name")[0];
          property.style.border = "0.7px solid rgb(255,0,0)";
          property.placeholder = "Please enter valid Name";
        }
        if (!this.validatePhoneInput()) {
          let property = document.getElementsByName("phone")[0];
          property.placeholder = "Please enter valid Phone Number";
        }
        if (this.state.email == "" || ! /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)) {
          let property = document.getElementsByName("email")[0];
          property.style.border = "0.7px solid rgb(255,0,0)";
          property.placeholder = "Please enter valid Email ID";
        }
      }
    }
    else if(this.state.loggedIn == "true"){
      let payload = {
        approver_id: 2,
        type: 1,
        message: JSON.stringify({
          email: profile.about.myself.email,
          name: profile.about.myself.name,
          phone: profile.about.myself.mobile,
          healthcarename: this.state.healthcare,
          facility: this.state.facility,
          requestedtype: this.state.checked,
          website: this.state.website,
          msg: this.state.msg,
          id: this.state.id,
          date_to_contact: this.state.date,
          fromPage : this.state.fromPage,
          reference: this.state.reference,
          mobile_no:profile.about.myself.mobile
        }),
        status: 1
      };
      dispatch(demoActions.demo(payload));
    }
  };


  render() {
    const { mergeObjects } = utils;
    const { history } = this.props;
    const { phoneError } = this.state;
    let pageData = history.location.state;

    return (
      <div style={{ display: "flex" }}>
        <div className="demo" style={{backgroundColor:appinfo.SECONDARY_COLOR}}>
          {/* <img src="#" alt="Demo" className="backgroundimage" /> */}
          <div className="insidedemo">
            <img
              onClick = {() => history.push("/home")}
              className="titleimage"
              src={appinfo.logowhite}
              alt={"Title"}
            />
            <div className="quotedata">
              {
                  this.state.fromPage == "CONTACT_US"
                    ? (appinfo.CONTENTS.contactPage.map(clist => {
                      return (
                        <div>
                          <div style={{ fontSize: 22, fontWeight: "bold" }}>{clist.key}</div> 
                          <div style={{ fontSize: 16 }}>
                            {clist.value}
                          </div><br></br>
                        </div>
                      );                      
                    }))
                    : (<div>{appinfo.CONTENTS.submitFormPage}
                        <div className="statssection">
                        {
                          appinfo.stats.map( indStats => {
                            return (
                              <div>
                                <div style={{ fontSize: 22, fontWeight: "bold" }}>{indStats.value}{indStats.adder}</div>
                                <div style={{ fontSize: 16, fontWeight: "600" }}>
                                  {indStats.key}
                                </div>
                              </div>
                            );
                          })
                        }
        
                      </div>
                    </div>
                      )
                }
            </div>
          </div>
        </div>
        <div className="demoright" >
          <div className="phoneemail" >
            <div className="mobile">
            <a href={"tel:"+appinfo.PHONE} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
            <img src={phone} className="phone" />
            &nbsp;{appinfo.PHONE} &nbsp;
            </a>
            </div>
            <div className="email">
            <a href={"mailto:"+appinfo.EMAIL} style={{textDecoration: "none", color: "rgb(0,0,0,0.38)"}}>
            <img src={mail} className="phone"/>
            &nbsp;{appinfo.EMAIL}
            </a>
            </div>
          </div>
          { pageData && pageData.page == "demo" ?
          (<div className="formcontainer">
            <div className="titledemo" >
              {
                  this.state.id == "null"
                    ? "CONTACT US"
                    : this.state.fromPage == "BOOK_NOW" ? "BOOK A PROCURE PLAN" : this.state.fromPage == "CONTACT_US" ? "CONTACT US" : "REQUEST FOR QUOTE"
                }
            </div>
            <form>
              { this.state.loggedIn != "true" ? (<div>
              <input
                type="text"
                className="input"
                placeholder="Name"
                name="name"
                onChange={this.handleChange}
              />
              <PhoneInput
                country="IN"
                name="phone"
                className={phoneError ? "input input-error" : "input"}
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={phone => this.setState({ phone })} />
              <input
                type="email"
                className="input"
                placeholder="Email ID"
                name="email"
                onChange={this.handleChange}
              /></div>) : (<div></div>)}

              { this.state.fromPage == "CONTACT_US" &&
              (<div><div className="radio-group">
                Which one are you ? 
                <div>
                <input 
                  type="radio" 
                  name="individualType" 
                  value="1" 
                  defaultChecked
                  onClick={()=>{this.setState({checked: "1"})}}
                  style={{marginLeft: 20}}/>&nbsp; Buyer
                </div>
                <div>
                <input 
                  type="radio" 
                  name="individualType" 
                  value="2"
                  onClick={()=>{this.setState({checked: "2"})}} 
                  style={{marginLeft: 20}}/>&nbsp; Manufacturer
                  </div>
                </div></div>)
              }
                
                { this.state.checked == "1" ? 
                (<div>
                  <select className="input-select" 
                        onChange={(event)=>{this.setState({facility: event.target.value})}}
                        selectedValue={this.state.facility}>
                  <option value="">Type of Facility</option>
                  <option value="clinic">Clinic</option>
                  <option value="diagnostic centre">Diagnostic Centre</option>
                  <option value="hospital">Hospital</option>
                  <option value="nursing home">Nursing Home</option>
                  <option value="others">Others</option>
                </select>

                <input
                type="text"
                className="input"
                placeholder="Name of Medical Facility"
                name="healthcare"
                onChange={this.handleChange}
                />
                { this.state.fromPage !== "CONTACT_US" &&
                  <div className="date">
                    <div className="date-label">When can you be contacted ?&nbsp;</div>
                    <DateTimePicker 
                      onChange={this.onChange}
                      value={this.state.date}
                      calendarIcon={null}
                      className="date-time-pick"
                      disableClock={true}
                    />
                  </div> 
                }
                </div>) :
                (<div>
                  <input
                    type="text"
                    className="input"
                    placeholder="Company Name"
                    name="healthcare"
                    onChange={this.handleChange}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Website URL"
                    name="website"
                    onChange={this.handleChange}
                  />
                </div>
                )}

              <textarea
                className="input-area"
                placeholder={
                  this.state.id == "null"
                    ? "What products are you interested in?"
                    : "Anything else you want to let us know?"
                }
                name="msg"
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="input"
                placeholder="Referral code ( optional )"
                name="reference"
                onChange={this.handleChange}
              />
            </form>
            <button
              className="demo-button-demo"
              onClick={this.handleDemoSubmit}
              style={{backgroundColor:appinfo.PRIMARY_COLOR,border:appinfo.PRIMARY_COLOR,border:"none"}}
            >
               {
                  this.state.id == "null" || this.state.fromPage == "BOOK_NOW"
                    ? "Submit"
                    : "Send Request"
                }
            </button>
          </div>)
          :
          (
            <div className="formcontainer extra-gap">
            <div className="titledemo" >
              Create an account
            </div>
            <form>
              <input
                type="text"
                className="input"
                placeholder="Name"
                name="name"
                onChange={this.handleChange}
              />
              <PhoneInput
                country="IN"
                name="phone"
                className={phoneError ? "input input-error" : "input"}
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={phone => this.setState({ phone })} />
              <input
                type="email"
                className="input"
                placeholder="Email ID"
                name="email"
                onChange={this.handleChange}
              /> 
              </form>
              <button
              className="demo-button-demo extra-padding"
              style={{backgroundColor:appinfo.PRIMARY_COLOR}}
              onClick={this.handleDemoSubmit}
            >
               SIGNUP
            </button>
            
          </div>
          )
          }
        </div>
        {/* <MayIHelpYou /> */}
      </div>
    );
  }
}




function mapStateToProps(state) {
  const { demo,  profile, user } = state;
  return {
    demo,
    profile,
    user
  };
}

export default connect(mapStateToProps)(Demo);
