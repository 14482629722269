import { dealsConstants } from "../constants";
import { dealsService } from "../services";

export const dealsActions = {
  getDeals
};

function getDeals() {
  return dispatch => {
    dispatch(request());
    dealsService.getDeals()
    .then(res =>  dispatch(success(res)), err => dispatch(error(err)));
  };

  function request(){
    return { type: dealsConstants.GET_DEALS_REQUEST};
  }
  function success(res) {
    return { type: dealsConstants.GET_DEALS_SUCCESS, res };
  }
  function error(err) {
    return { type: dealsConstants.GET_DEALS_ERROR, err};
  }
}
