import {checkoutConstants} from "../constants";

const initialState ={
    result: []
}
export function checkout(state= initialState,action){
    switch (action.type) {
        case checkoutConstants. GET_QUOTATION_REQUEST:
            return{
                ...state,
                status: "GET_QUOTATION_REQUEST"
            };
          case checkoutConstants.GET_QUOTATION_SUCCESS:
            return {
              ...state,
              status: "GET_QUOTATION_SUCCESS",
              result: action.res
            };
          case checkoutConstants.GET_QUOTATION_ERROR:
            return {
              ...state,
              status: "GET_QUOTATION_ERROR",
              result: action.err
            };
          default:
            return state;
        }
}
