// Redux
import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {privacy} from "./privacy.reducer";
import { authentication } from "./authentication.reducer";
import { brands } from "./brands.reducer";
import { cart } from "./cart.reducer";
import { catalog } from "./catalog.reducer";
import { category } from "./category.reducer";
import { deals } from "./deals.reducer";
import { demo } from "./demo.reducer";
import { endpoint } from "./endpoint.reducer";
import { inventory } from "./inventory.reducer";
import { profile } from "./profile.reducer";
import { order } from "./order.reducer";
import { sku } from "./sku.reducer";
import { skudoc } from "./skudoc.reducer";
import { search } from "./search.reducer";
import { user } from "./user.reducer";
import {checkout} from "./checkout.reducer";
import {shipping} from "./shipping.reducer";
import {add} from "./add.reducer";
import {signup} from "./signup.reducer";
import { compare } from "./compare.reducer";
import {featured} from "./Featured.reducer";
import {discount} from "./discount.reducer";
// import { createLogger } from 'redux-logger';
// const loggerMiddleware = createLogger()
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authentication","user","cart","compare"]
};

// Middlewares
const middleware = () => {
  return applyMiddleware(ReduxThunk);
  // return applyMiddleware(ReduxThunk,loggerMiddleware);
};


const pReducer = persistReducer(
  persistConfig,
  combineReducers({
    authentication,
    add,
    brands,
    cart,
    catalog,
    category,
    compare,
    checkout,
    deals,
    demo,
    endpoint,
    privacy,
    inventory,
    profile,
    order,
    search,
    shipping,
    sku,
    skudoc,
    signup,
    user,
    featured,
    discount
  })
);

export default createStore(pReducer, middleware());