import { skuConstants } from "../constants";
import { skuService } from "../services";
import { httpError } from "../utils";
import { dispatch } from "rxjs/internal/observable/range";

export const skuActions = {
  getByCategoryId
};

function getByCategoryId(category_id) {
  return dispatch => {
    skuService.getByCategoryId(category_id).then(
      res => {
        let result = {
          category_id,
          skus: res.skus
        };
        dispatch(success(result));
      },
      err => {}
    );
  };

  function success(res) {
    return { type: skuConstants.GET_BY_CATEGORYID_SUCCESS, res };
  }
}
