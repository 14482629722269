import React, { Component } from "react";
import { connect } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import Login from "./index.js";
import { userActions } from "../../actions/index.js";
import "./index.css";
import appinfo from "../../clientsJSON";

class MobileOtp extends Component {
    constructor(){
        super();
        this.state = {
            phone: "",
            error: false,
        }
    }

    onChangeText = (key, value) => {
        this.setState ({ [key] : value })
    }

    handleLogin = () => {

        const { dispatch } = this.props;
        const { phone } = this.state;

        if(isValidPhoneNumber(phone || "")){
                this.onChangeText("error", false);
                let property1 = document.getElementsByClassName("error")[0];
                property1.innerHTML = "";
                property1.innerText = "";
    
                let payload = {
                    mobile_no: this.state.phone
                }
                dispatch(userActions.mobileotp(payload));
        }
        else{
            this.onChangeText("error", true);
            let property = document.getElementsByName("phone")[0];
            property.placeholder = "Please enter valid Phone Number";
        }

    }

    componentWillReceiveProps(newProps){
        const {user,history}=newProps;

        if( user.status == "MOBILEOTP_SUCCESS"){

            history.push("/otp",{mobile_no: this.state.phone});

        }else if( user.status == "MOBILEOTP_ERROR"){

            // if(user.otp.code == 404 ){

                let property1 = document.getElementsByClassName("error")[0];
                property1.innerHTML = "This is not the registered mobile number";
                property1.innerText = "This is not the registered mobile number";

            // }
            // else if(user.otp.response_code == 500 ){

            //     alert(user.otp.message);
            //     window.location.reload();

            // }
        }
    }

    render() {
        const { phone, error } = this.state;
        const { history } = this.props;

        return (
        <Login history={history}>
            <div className="login-child">
                <div className="login-title">
                    Login to your {appinfo.APP_FOR} account    
                </div>
                <div className="login-mobile-input" style={{display: "flex", justifyContent: "center"}}>
                    <PhoneInput
                        country="IN"
                        name="phone"
                        className={error ? "input input-error" : "input"}
                        placeholder="Enter phone number"
                        value={this.state.phone}
                        onChange={phone => this.setState({ phone })} />
                </div>
                <div className="error"></div>
                <div style={{backgroundColor:appinfo.PRIMARY_COLOR}} className="login-mobile-button" onClick={()=>{this.handleLogin()}}>
                    <div style={{paddingTop: "11px"}}>LOGIN</div>
                </div>
                <div style={{color:appinfo.PRIMARY_COLOR}} className="login-redirect" onClick={()=>{history.push("/credentials")}}>
                    LOGIN WITH EMAIL
                </div>
            </div>
        </Login>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state;
    return {
      user
    };
  }
  
  export default connect(mapStateToProps)(MobileOtp);