import { cartConstants } from "../constants";

export const cartActions = {
  addToCart,
  removeFromCart,
  removeAll,
  updateCart
};

function addToCart(payload) {
    return {
        type: cartConstants.ADD_SUCCESS,
        payload
      };
}

function removeFromCart(inventory_id) {
    return {
        type: cartConstants.REMOVE_SUCCESS,
        inventory_id
      };
}

function removeAll(){
  return {
    type: cartConstants.REMOVE_All_SUCCESS
  };
}

function updateCart(inventory_id,count){
  return {
    type: cartConstants.UPDATE_SUCCESS,
    inventory_id,
    count
  }
}



