export const addConstants = {
    GETALL_REQUEST: 'ADD_GETALL_REQUEST',
    GETALL_ERROR: 'ADD_GETALL_ERROR',
    CREATE_REQUEST: 'ADD_CREATE_REQUEST',
    CREATE_ERROR: 'ADD_CREATE_ERROR',

    CREATE_SUCCESS: 'ADD_CREATE_SUCCESS',
    GETALL_SUCCESS: 'ADD_GETALL_SUCCESS'

};
