import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "./index.js";
import { userActions } from "../../actions/index.js";
import Cookies from "universal-cookie";
import appinfo from "../../clientsJSON";
import notyf from "../../utils/notification";

class Otp extends Component {
    constructor(){
        super();
        this.state = {
            otp: "",
            phone: ""
        }
    }

    resendotp = () => {

        const { dispatch } = this.props;
        
        let payload = {
            mobile_no : this.state.phone
        }
        
        dispatch(userActions.mobileotp(payload));

        let property = document.getElementsByClassName("resend-otp")[0];
        property.innerHTML = "";
        property.innerText = "";
        
    }

    onChangeText = (event, key) => {
        this.setState ({ [key] : event.target.value })
    }

    componentDidMount(){

        const { history } = this.props;
        this.setState({ phone : history.location.state.mobile_no});

    }

    handleSubmit = () => {

        const { dispatch } = this.props;

        if( this.state.otp != "" && 
            /^[0-9]+$/.test(this.state.otp) &&
            this.state.otp.length == 6 ){

                let property1 = document.getElementsByClassName("error-otp")[0];
                property1.innerHTML = "";
                property1.innerText = "";
    
                let payload = {
                    mobile_no: this.state.phone,
                    otp: this.state.otp
                }

                dispatch(userActions.otpverification(payload));
        }
        else{

            let property1 = document.getElementsByClassName("error-otp")[0];
            property1.innerHTML = "Entered OTP is Incorrect";
            property1.innerText = "Entered OTP is Incorrect";

        }

    }

    componentDidUpdate(){

        const { user, dispatch, history, cart } = this.props;

        const cookie = new Cookies();

        if( user.status == "OTPVERIFY_SUCCESS" && user.otp.code == 200 ){
            
            let payload = {
                mobile_no : this.state.phone,
                otp: this.state.otp
            }
            dispatch(userActions.otplogin(payload));

        }else if( user.status == "OTPVERIFY_ERROR" ){

            let property1 = document.getElementsByClassName("error-otp")[0];
            property1.innerHTML = "Entered OTP is Incorrect";
            property1.innerText = "Entered OTP is Incorrect";

        }
        if(user.status == "OTPLOGIN_SUCCESS" && user.otp.code == 200 ){
            
            cookie.set("loggedIn","true",{path: "/"});
            // Need to add it here
            if(cart.partsCount > 0){
                history.push("/checkout");
            } else {
                history.push("/home");
            }
        }
        else if( user.status == "OTPLOGIN_ERROR" ){
            
            notyf.open({type:"error",message:user.otp.message});
            window.location.reload();

        }

    }

    render() {

        const { history } = this.props;

        return (
        <Login history={history}>
                <div className="login-child otp">
                    <div className="login-title">
                        Phone Verification    
                    </div>
                    <div className="otp-number">
                        <div> We have sent an SMS with an OTP </div>
                        <div> to your phone &nbsp; <span className="number">{this.state.phone} </span></div>
                    </div>
                    <div className="error-otp"></div>
                    <div className="login-otp-input">
                        <input 
                            type="number"
                            name="otp"
                            placeholder="Enter OTP"
                            className="mobile-input"
                            onChange={(event)=>{this.onChangeText(event, "otp")}}
                        />
                    </div>
                    <div style={{color:appinfo.PRIMARY_COLOR}} className="resend-otp" onClick={()=>{this.resendotp()}}>
                        Resend OTP
                    </div>
                    <div className="login-mobile-button less-space" style={{backgroundColor:appinfo.PRIMARY_COLOR}} onClick={()=>{this.handleSubmit()}}>
                        <div style={{paddingTop: "11px"}}>SUBMIT</div>
                    </div>
                    <div className="login-redirect" style={{color:appinfo.PRIMARY_COLOR}} onClick={()=>{history.push("/credentials")}}>
                        LOGIN WITH EMAIL
                    </div>
                </div>   
            
        </Login>
        );
    }
}

function mapStateToProps(state) {
    const { user, cart } = state;
  
    return {
      user, cart
    };
  }
  
  export default connect(mapStateToProps)(Otp);