export const userConstants = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  
  SIGNUP_REQUEST : "USER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS : "USER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE : "USER_SIGNUP_FAILURE",

  MOBILEOTP_REQUEST : "MOBILEOTP_REQUEST",
  MOBILEOTP_SUCCESS : "MOBILEOTP_SUCCESS",
  MOBILEOTP_ERROR : "MOBILEOTP_FAILURE",

  OTPVERIFY_REQUEST : "OTPVERIFY_REQUEST",
  OTPVERIFY_SUCCESS : "OTPVERIFY_SUCCESS",
  OTPVERIFY_ERROR : "OTPVERIFY_ERROR",

  OTPLOGIN_REQUEST : "OTPLOGIN_REQUEST",
  OTPLOGIN_SUCCESS : "OTPLOGIN_SUCCESS",
  OTPLOGIN_ERROR : "OTPLOGIN_ERROR",

  LOGOUT: "USERS_LOGOUT"
};
