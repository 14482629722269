import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import Cookies from "universal-cookie";
// import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';

import { utils } from "../../utils";
import { inventoryActions,searchActions, brandsActions, dealsActions } from "../../actions";
import appinfo from "../../clientsJSON";
import Container from "../container";
import FeaturedProduct from "../../components/featured_product";

import BannerBgLarge from "../../images/banner-bg/banner-bg@3x.png";
import BannerBgMedium from "../../images/banner-bg/banner-bg@2x.png";
import BannerBgSmall from "../../images/banner-bg/banner-bg.png";
import VideoBanner from "../../images/banner-carousel/banner-carousel.png";
import Play from "../../images/play/play.png";
import WavePattern from "../../images/wave-pattern/wave-pattern.png";
import Contact from "../../images/why-choose-us/contact.png";
import Delivery from "../../images/why-choose-us/delivery.png";
import ProductPortfolio from "../../images/why-choose-us/product-portfolio.png";
import SaveMoney from "../../images/why-choose-us/save-money.png";
import { Carousel } from "react-responsive-carousel";
import style from "react-responsive-carousel/lib/styles/carousel.min.css";
import {addActions} from "../../actions/index.js";
import {addConstants} from "../../constants";
import {add} from "../../reducers/add.reducer";
import { maxHeaderSize } from "http";
import Slider from "react-slick";
import Product from "../../components/product";
import SearchLoader from "../../components/search_loader";


class Home extends Component {
  constructor() {
    super();
    this.state = {
      category: [],
      display: "none",
      isOpen: false,
      button: "SEE MORE",
      didViewCountUp: false,
      currentIndex: 0,
      totalSlides: 5,
      rightCarouselArrowEnabled: true,
      leftCarouselArrowEnabled: true
    };
    this.myCountUp = null;
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }


  componentDidMount(){
    const {dispatch, inventory, deals,featured} = this.props;
    document.title = appinfo.TITLE;
    // if(inventory.status!="GET_BY_ID_SUCCESS"){
    //   for(let i=0; i<appinfo.featuredProducts.length; i++){
    //     dispatch(inventoryActions.getById(appinfo.featuredProducts[i]));
    //   }
    // }
    if(featured.status !="FEATURED_SUCCESS"){
      for(let i=0; i<appinfo.featuredProductsApi1.length; i++){
        dispatch(searchActions.searchV3(appinfo.featuredProductsApi1[i]));
      }
    }

    dispatch(addActions.getAll());
    dispatch(dealsActions.getDeals());
    dispatch(brandsActions.getBrands());
  }
  
  onChangeText = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  requestRedirect = () => {
    const { history } = this.props;
    const cook = new Cookies();
    cook.set("fromPage", "BOOK_NOW", { path: "/" });
    cook.set("id", "", {path: "/"});
    history.push("/submitform", { page: "demo" });
    window.scrollTo(0, 0);
  };


  toggleFeatures= () => {
    let toDisplay = this.state.display;
    let button = this.state.button;
    document.getElementsByClassName("more-button")[0].innerHTML = button=="SEE MORE"?"SEE LESS": "SEE MORE";;
    document.getElementsByClassName("more-button")[0].innerText = button=="SEE MORE"?"SEE LESS": "SEE MORE";
    for(let i=0; i<appinfo.featuredProducts.length-4; i++){
      document.getElementsByClassName("featured")[i].style.display= toDisplay=="block" ?"none":"block";
    }
    document.getElementsByClassName("more")[0].style.marginTop = toDisplay=="none" ?" 1000px": "230px";
    this.setState({
      display: toDisplay=="none" ?"block" : "none",
      button: button=="SEE MORE"?"SEE LESS": "SEE MORE"
    });
  }

  handleSubmit = e => {
    const { searchQuery } = this.state;
    const { history } = this.props;

    e.preventDefault();

    if (!utils.isEmpty(searchQuery)) {
      history.push("/search", { searchQuery: searchQuery });
    }
  };

  performSearchV2 = () => {
    const { dispatch, history } = this.props;

      let payload = {
        search: "*"
      };
      dispatch(searchActions.searchV2(payload));
      history.push("/search?searchQuery=*&id=null", {searchPayload: payload});

  };

  responsive = {
    0: { items: 1 },
    600: { items: 2},
    768: { items: 3 },
    1024: { items: 4 },
  };

  galleryItems() {
    const { category, history, dispatch } = this.props;
    let array = Object.keys(category.categories);
    return (
      array.map((item, i) => (
        <div key={`key-${i}`} className="specialities-card"
          onClick={()=>{
            let id = category.categories[item].category_id;
            let payload = {filter: {category_id: id}, search: `category_name: \"${item}\"`};
            dispatch(searchActions.searchV2(payload));
            history.push(`/search?fromMenu=true&category_id=${id}&level=1&category_name=${item}&id=${id}`);
            window.scrollTo(0, 0);
          }}>
          <div className="specialities-card-image"><img src={utils.imagePath(category.categories[item].category_image, "x300")} width="200px" height="200px" /></div>
          <div className="specialities-card-title"  style={{color:appinfo.PRIMARY_COLOR}}>{item}</div>
        </div>
      ))
    )
  };
  bundleItems() {
    const { history } = this.props;
    const bundleList =[];
    for (let i=1;i<6;i++){
      bundleList[i-1]= (        
        <div style={{cursor:"pointer"}} onClick = {()=>{
          history.push(`b/${appinfo.bundle[i].inventory_id}`);
          window.scrollTo(0, 0);
        }}>
          <div className="specialities-card-image"><img src={utils.imagePath(appinfo.bundle[i].image_path, "x300")} width="250px" height="250px" /></div>
          <div className="specialities-card-title">{appinfo.bundle[i].inventory_name}</div>
        </div>
      );
    }
    return bundleList;
  }

  renderDealss = () => {
    const { deals, dispatch }  = this.props;
    // return null;
    if ( deals.status === "GET_DEALS_ERROR" || 
        ( deals.status === "GET_DEALS_SUCCESS" && deals.result.deals.length === 0 )
        ) {
            return <h4 style={{textAlign: "center", marginTop: 80}}>
                        Sorry !! No Deals Found
                    </h4>;
        }
    else if ( deals.status === "GET_DEALS_SUCCESS" && deals.result.deals.length != 0 ){
        let dealRender = [];
        deals.result.deals.map( dl => {
            dl.associations.map( e => {
                if(e.status === 1){
                    let price_deals="";
                    if(e.inventory.recent_selling_price !== 0 && e.inventory.msrp_price !== 0){
                        price_deals="₹"+ e.inventory.recent_selling_price;
                    }
                    dealRender[dealRender.length]= {
                        inventory_id: e.inventory_id,
                        inventory_name: e.inventory.inventory_name,
                        sku_image: e.inventory.image_path,
                        product_name: e.inventory.modal,
                        brand: e.inventory.brand,
                        recent_selling_price:e.inventory.recent_selling_price,
                        msrp_price:e.inventory.msrp_price
                    }
                }
            });                    
        });
        return dealRender.map( val => {
            let inv_name = val.inventory_name.split(" ").join("-");
            let url=`product/${val.inventory_id}/${inv_name.replace(/\s+/g, '-').toLowerCase()}`;
            //let onClick = () =>  window.open(`product/${val.inventory_id}/${inv_name.replace(/\s+/g, '-').toLowerCase()}`);
            return <Product data={val} 
            url={url}/>;
        });
    } else {
        // return <SearchLoader />;
    }
}  
renderFeaturedProductApi =()=>{
  const { featured, history, dispatch} = this.props;
  let featuredItem=[];
  let redirection=window.screen.width >768 ? "_blank":"_self" 
  if(featured.status =="FEATURED_SUCCESS"){
    let array=Object.keys(featured.Featured[0].search_result.hits.hits);
  //   return(
  //     array.map((item,i)=>(
  //       <div style={{margin:"0px auto"}}>
  //       <a style={{textDecoration:"none"}} href={"/product/"+featured.Featured[0].search_result.hits.hits[item]._id+"/"+featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.replace(/\s+/g, '-').toLowerCase()} target={redirection}>
  //       <div className="product-card" key={`key-${i}`} style={{display:"block", height: window.screen.width<1366 ? 200 : 200, width: window.screen.width<1366 ? 200 : 200, marginTop: 40, alignItems: "center", justifyContent: "center",cursor:"pointer"}} 
  //       onClick={()=>{dispatch(inventoryActions.getByInventoryId(featured.Featured[0].search_result.hits.hits[item]._id)); window.scrollTo(0,0)}}>
  //      <img src={utils.imagePath(featured.Featured[0].search_result.hits.hits[item]._source.sku_image.split(",")[0], "x300", "x300")}width={ window.screen.width<1366 ? "200px" : "200px" } height={ window.screen.width<1366 ? "200px" : "200px"} />
  //      </div> 
  //      <div style={{padding:4}} className="product-card">
  //       <div style={{color: appinfo.SECONDARY_COLOR,fontSize: 14,fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.inventory_name}</div>
  //       <div style={{fontSize: 14,color: "rgba(32, 48, 71, 0.54)",fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.sku_name.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.sku_name.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.sku_name}</div>
  //       <div style={{ fontSize: 14, color: "rgba(32, 48, 71, 0.54)", fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.brand.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.brand.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.brand}</div>
  //      </div>
  //      </a>
  //      </div>
  // ))


// )
   for(let item=0;item<5;item++){
    featuredItem[item]=(
             <div style={{margin:"0px auto"}}>
        <a style={{textDecoration:"none"}} href={"/product/"+featured.Featured[0].search_result.hits.hits[item]._id+"/"+featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.replace(/\s+/g, '-').toLowerCase()} target={redirection}>
        <div className="product-card"  style={{display:"block", height: window.screen.width<1366 ? 200 : 200, width: window.screen.width<1366 ? 200 : 200, marginTop: 40, alignItems: "center", justifyContent: "center",cursor:"pointer"}} 
        onClick={()=>{dispatch(inventoryActions.getByInventoryId(featured.Featured[0].search_result.hits.hits[item]._id)); window.scrollTo(0,0)}}>
       <img src={utils.imagePath(featured.Featured[0].search_result.hits.hits[item]._source.sku_image.split(",")[0], "x300", "x300")}width={ window.screen.width<1366 ? "200px" : "200px" } height={ window.screen.width<1366 ? "200px" : "200px"} />
       </div> 
       <div style={{padding:4}} className="product-card">
        <div style={{color: appinfo.PRIMARY_COLOR,fontSize: 14,fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.inventory_name.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.inventory_name}</div>
        <div style={{fontSize: 14,color: "rgba(32, 48, 71, 0.54)",fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.sku_name.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.sku_name.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.sku_name}</div>
        <div style={{ fontSize: 14, color: "rgba(32, 48, 71, 0.54)", fontWeight: "600"}}>{featured.Featured[0].search_result.hits.hits[item]._source.brand.length>=21 ? featured.Featured[0].search_result.hits.hits[item]._source.brand.substring(0,20) + "..." : featured.Featured[0].search_result.hits.hits[item]._source.brand}</div>
       </div>
       </a>
       </div>)
      
   }
   return featuredItem;
  }
}

  renderFeaturedProducts = () => {
    const { inventory, history, dispatch } = this.props;
    if((inventory.status=="GET_BY_ID_SUCCESS") && (inventory.inventories.length==appinfo.featuredProducts.length)){
      let products = [];
      for(let i=0; i<appinfo.featuredProducts.length ;i++){
        let single = [];
        let j=i;
        for(; j<appinfo.featuredProducts.length && j< i+4; j++){
          let data = inventory.inventories.find(e=> e[0].inventory_id==appinfo.featuredProducts[j]);
         let url =`product/${data[0].inventory_id}/${data[0].inventory_name.replace(/\s+/g, '-').toLowerCase()}`
          single[single.length] = (<FeaturedProduct 
                                             url={url} 
                                          onClick={()=>{dispatch(inventoryActions.getByInventoryId(data[0].inventory_id));
                                                        // history.push("product/"+data[0].inventory_id+"/"+data[0].inventory_name.replace(/\s+/g, '-').toLowerCase())
                                                        window.scrollTo(0,0)}}
                                          style={ j< 4 ? "block": "none"}
                                          classname={ j>=4 ? "featured": "non-featured"}
                                          image={data[0].image_path} 
                                          sku_name={data[0].sku_name}
                                          inventory_name={data[0].inventory_name}
                                          brand={data[0].brand}
                                          />);
        }
        i=j-1;
        products[products.length]=(
          
        <div className="feature-lines">{single}</div>);
      }
      return products;
    }  
  }

  createBanner =()=> {
    const {add, history}=this.props;
    let ad=[];
    let img=""
    if (add.status == "GETALL_SUCCESS"){
     for(let i=0;i<add.adds.length;i++){
       if(window.screen.width >= 768){
        img=utils.imagePath(add.adds[i].image);
       } else {
        img=utils.imagePath(add.adds[i].image, "x300");
       }
       ad[i]=(
        <a href={add.adds[i].header} target="_blank">
          <div key={i} style={{maxHeight:545,cursor:"pointer"}} className="bannerCarousel">
            <img src={img} style={{overflowY:"hidden"}}/>
          </div>
          {/* onClick={() => history.push(add.adds[i].header)} */}
        </a>
       );
   }return ad;}};
 

  hasImage = (brands) => {
    let res = brands.map( vendor => {
      if(vendor.image_path !== null && vendor.image_path !== ""){
        return vendor.image_path;
      } 
    });
    let result = res.filter( e => e !== undefined );
    return result.length != 0 ? true : false;
  }

  signInLive = (history, authentication, user) => {
    if (this.state.loggedIn == "true" || authentication.loggedIn || user.loggedIn) {
      return (
        <button
        className="demo-button"
        style={{
          color: "#ffffff",
          backgroundColor: appinfo.PRIMARY_COLOR,
          borderColor:appinfo.SECONDARY_COLOR,
          boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.18)",
          marginTop: 24,
          marginRight: 18,
          fontWeight: "600",
          maxWidth: 160
        }}
        onClick={()=>{history.push("/deals")}}
      >
        {appinfo.deals}
      </button> 
      )
    } else {
      return (
        <button
        className="demo-button"
        style={{
          color: "#ffffff",
          backgroundColor: appinfo.PRIMARY_COLOR,
          boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.18)",
          marginTop: 24,
          borderColor:appinfo.SECONDARY_COLOR,
          marginRight: 18,
          fontWeight: "600",
          maxWidth: 160
        }}
        onClick={()=>{history.push("/signup")}}
      >
        SIGN UP
      </button>
      )
    }}
  
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1};
    const { brands, history, authentication, user, add , category, dispatch} = this.props;

    let renderstat =[];
    for(let i=0; i<appinfo.stats.length; i=i+5){
      let component=[];
      for(let j=i; (j<appinfo.stats.length) && (j<i+5); j++){
        let division = (<CountUp key={j} start={0} end={this.state.didViewCountUp ? appinfo.stats[j].value : 0}
                          suffix={appinfo.stats[j].adder}
                          ref={(countUp)=>{this.myCountUp = countUp;}}>
                           {({ countUpRef, start }) => (
                            <div  className="space-zep-in-numbers"
                                  style={{paddingLeft: "50px",
                                        paddingRight: "50px",
                                        }}>
                              <div style={{color: "black",
                                          fontWeight: "400",
                                          fontSize: "20px",
                                          alignItems: "stretch",
                                          justifyContent: "center"
                                        }}
                                        className={"counter"+i}>
                                        
                                            <div>
                                              <span ref={countUpRef}/>
                                            </div>
                              </div>
                              <div style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                {appinfo.stats[j].key}
                              </div>
                            </div>
                          )}
                        </CountUp>);
        component[component.length]=division;
      }
      renderstat[renderstat.length]=(<div className="home-company-numbers">
                                        {component}
                                     </div>
                                      );
    }

    let whyChooseUs = [ProductPortfolio, SaveMoney, Delivery, Contact];

    return (
      <Container>
      {/* <!-- og Tags -->   */}
        <title>{appinfo.APP_FOR}</title>
        <meta name="description" content={appinfo.TITLE}/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={appinfo.APP_FOR} />
        <meta property="og:url" content={appinfo.WEBSITE}/>
        <meta property="og:description" content="Sign up with us and get access to high-quality brands and products, while enjoying savings of up to 20%."/>
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta name="description" content="No more dealing with multiple vendors. No more compromising on quality to make savings. Sign up with us and get access to high-quality brands and products, while enjoying savings of up to 20%."/>      
        <div style={{ backgroundColor: "#ffffff"}}>
          <div style={{ maxHeight: 545, position: "relative" }} className="home-background">
            {/* start of template 1 banner */}
            {appinfo.template==1 &&(
            <div>
              <img className="home-image" src={BannerBgLarge} srcSet={`${BannerBgSmall} 300w, ${BannerBgMedium} 768w, ${BannerBgLarge} 1280w`}/>
              <div className="home-section1">
                <div className="home-section1-inline">
                  <h1 style={{ fontSize: 24, color: appinfo.PRIMARY_COLOR, fontWeight: "bold"}}>
                    { appinfo.CONTENTS.HomePage.startSection.title }
                  </h1>
                  <div style={{marginTop: 14, width: 118, height: 3, backgroundColor: appinfo.PRIMARY_COLOR}}/>
                  <div style={{fontSize: 20, lineHeight: 1.25, color: "rgba(0, 0, 0, 0.87)", marginTop: 17}}>
                    { appinfo.CONTENTS.HomePage.startSection.description }
                  </div>
                  <div className="ctas">
                    {this.signInLive(history, authentication,user)}
                    <button
                      className="demo-button"
                      style={{marginTop: 24, maxWidth: 160}}
                      onClick={() => {this.performSearchV2()}}
                      >
                      SEE PRODUCTS
                    </button>
                  </div>
                  <div className="see-video" onClick={()=>{this.setState({isOpen: true})}}>WATCH VIDEO</div>
                </div>
                  {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bMWhNCPzuWA' onClose={()=>this.setState({isOpen: false})} /> */}
               
                <div 
                  style={{  flex: 1, 
                            backgroundImage: `url(${VideoBanner})`, 
                            backgroundRepeat: "no-repeat" }} 
                  className="blank">
                    <div style={{marginLeft: "226px", marginTop: "278px", cursor: "pointer"}}
                        onClick={()=>{this.setState({isOpen: true})}}
                    >
                      <img src={Play} className="play" width="48px" height="48px"/>
                    </div>
                </div>
              </div>
            </div>)}
            {/* Start of template 2 */}
            {appinfo.template==2 && (
            <div>
              {add.status!=undefined && add.status=="GETALL_SUCCESS" &&
              <div style={{position:"relative"}}>
                <Carousel autoPlay showStatus={false} scroll_duration={0.2} infiniteLoop showThumbs={false} responsive={this.responsive}>
                  {this.createBanner()}
                </Carousel>
              </div>}  
            </div>)
            }  
            {/* End of template 2 */}    
        </div> 
        {/* Start of Generic Modal */}       
        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bMWhNCPzuWA' onClose={()=>this.setState({isOpen: false})} /> */}
        {/* Start of company explanation with video */}
        {appinfo.home_video && <div className="">
          <div className="section" style={{backgroundColor:appinfo.PRIMARY_COLOR}}>
            <div className="container section-content sun">
                <ul>
                    <li>
                        <p className="bold" style={{padding:"16px 16px",color:"white",fontSize:18}}>Choose us! Check out our video to know more.</p>
                        <p></p>
                    </li>
                    <li>
                      <div style={{ backgroundImage: `url(${VideoBanner})`, backgroundRepeat: "no-repeat", width:300,height:150, margin:"0px auto" }}>
                          <div style={{cursor: "pointer",textAlign:"center"}} onClick={()=>{this.setState({isOpen: true})}}>
                            <img src={Play} className="play" width="48px" height="48px" style={{margin:"52px 0px"}}/>
                          </div>
                      </div>                      
                    </li>
                </ul>
            </div>
          </div>          
        </div>}  
        {/********************   Categories  ******************/}      
        {appinfo.home_categories && category != undefined && category.status == "GET_SUCCESS" && category.categories.length != 0 ? (
          <div className="mpcard mpcenter" style={{backgroundColor:"white"}}>
            <h2 style={{color:appinfo.PRIMARY_COLOR}}>{ appinfo.CONTENTS.HomePage.specialties.title }</h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/>             
            <div className="mpcardContent">             
              {this.galleryItems()}
            </div>
          </div>
          ) : ( <div></div>)}

          {/********************    Bundle PRODUCTS   ******************/}

         {/* {appinfo.home_bundle && <div className="featured-products">
            <a name="bundle">
            <div style={{ color: "#ffffff", fontSize: 18, fontWeight: "bold",padding:"0px 8px"  }}>
              { appinfo.CONTENTS.HomePage.bundle.title }
            </div>
            <div
              style={{
                paddingTop: 8,
                color: "#ffffff",
                fontSize: 16,
                padding:"0px 8px" 
              }}
            >
            { appinfo.CONTENTS.HomePage.bundle.description }

            </div>
            <div >
              <div style={{display: "flex", flexDirection: "row", marginTop: 25}}>
                    <div className="carousel-arrow" style={{marginTop:140}}
                      onClick={() => this.Carousel._slidePrev()}>
                      <i className="material-icons arrows">keyboard_arrow_left</i>
                    </div>
                    <div className="carousel-container">
                      <AliceCarousel
                        infinite={true}
                        items={this.bundleItems()}
                        startIndex={0}
                        dotsDisabled={true}
                        mouseDragEnabled={true}
                        buttonsDisabled={true}
                        responsive={this.responsive}
                        ref={ el => this.Carousel = el }
                      />
                    </div>
                    <div className="carousel-arrow"
                      style={{marginTop:140,display: this.state.rightCarouselArrowEnabled ? "block" : "none"}}
                      onClick={() => this.Carousel._slideNext()}>
                      <i className="material-icons arrows">keyboard_arrow_right</i>
                    </div>
                  </div>
            </div> </a>  
            </div> } */}

            
           {/* <div className="moreBundle" >
             <button className="demo-button more-button" style={{border:"none"}} onClick={() => {this.toggleFeatures()}}></button>
           </div>        

          {/********************    FEATURED PRODUCTS   ******************/}

          {appinfo.home_featured && <div className="featured-products mpcenter" style={{backgroundColor:appinfo.PRIMARY_COLOR}}>
            <div style={{ color: "#ffffff", fontSize: 18, fontWeight: "bold",padding:"0px 8px" }}>{ appinfo.CONTENTS.HomePage.featuredProducts.title }</div>
            <div style={{paddingTop: 8, color: "#ffffff",padding:"0px 8px"}}>{ appinfo.CONTENTS.HomePage.featuredProducts.description }</div>
            <div style={{display: "flex", position: "relative"}} >
              {/* {this.renderFeaturedProducts()} */}
              <div className="feature-lines"> {this.renderFeaturedProductApi()}</div>
            </div>
          </div>}
          {/* <div className="more" >
            <button className="demo-button more-button" onClick={() => {this.toggleFeatures()}}>{this.state.button}</button>
          </div>  */}

          {/******************  SPECIALTIES CARDS   *************************/}

         {appinfo.home_specialities && <div className= "specialities">
                  <h4 className="specialities-title">
                    { appinfo.CONTENTS.HomePage.specialties.title }
                  </h4>
                  <div style={{display: "flex", flexDirection: "row",}}>
                    <button className="carousel-arrow"
                      onClick={() => this.Carousel._slidePrev()}>
                      <i className="material-icons arrows">keyboard_arrow_left</i>
                    </button>
                    <div className="carousel-container">
                      <AliceCarousel
                        infinite={true}
                        items={this.galleryItems()}
                        startIndex={0}
                        dotsDisabled={true}
                        mouseDragEnabled={true}
                        buttonsDisabled={true}
                        responsive={this.responsive}
                        ref={ el => this.Carousel = el }
                      />
                    </div>
                    <button className="carousel-arrow"
                      style={{display: this.state.rightCarouselArrowEnabled ? "block" : "none"}}
                      onClick={() => this.Carousel._slideNext()}>
                      <i className="material-icons arrows">keyboard_arrow_right</i>
                    </button>
                  </div>
          </div>}
          {/***************************   SHOP BY DEALS #D1FDE8*****************/}
          {appinfo.home_deals &&  <div className="mpcard mpcenter" style={{backgroundColor:"#F0F0F0"}}>
            <h2 style={{color:appinfo.PRIMARY_COLOR}}>{appinfo.deals_title}</h2>
            <p>{appinfo.deals_description}</p>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/> 
            {/* <Slider {...settings}> */}
            <div  className="product">
              {this.renderDealss()}
            </div>
            {/* </Slider>                */}
        </div>    }   

          {/***************************   WHY CHOOSE US SECTION *****************/}

         {appinfo.home_whychoose && <div className="mpcard mpcenter">

            <h2
              style={{
                paddingTop: 13,
                fontWeight: "bold",
                color: appinfo.PRIMARY_COLOR,
                fontSize: 18,
                alignItems: "center"
              }}
            >
              { appinfo.CONTENTS.HomePage.whyChooseUs.title }
            </h2>
            <div
              style={{
                marginTop: 8,
                width: 90,
                height: 3,
                backgroundColor: appinfo.PRIMARY_COLOR
              }}
            />

            <div className="why-choose-us-content">
            

                {
                  appinfo.CONTENTS.HomePage.whyChooseUs.desc.map( (wcu, index) => {
                    return (
                      <div className="wcu-div" key={index}>
                        <div className="wcu-div-image">
                          <img src={whyChooseUs[index]} width="77px" height="77px"/>
                        </div>
                        <div className="wcu-div-title">{wcu.title}</div>
                        <div className="wcu-div-desc">{wcu.content}</div>
                      </div>
                    );
                  })
                }
            
            </div>
            <div className="ctas">
                  {this.signInLive(history, authentication,user)}
                  <button
                    className="demo-button"
                    style={{marginTop: 24, maxWidth: 160,borderColor:appinfo.PRIMARY_COLOR,color:appinfo.PRIMARY_COLOR}}
                    onClick={() => {this.performSearchV2()}}
                    >
                    SEE PRODUCTS
                  </button>
              </div>
          </div>}
          {/**********************   HOW IT WORKS SECTION   ***********************/}
         {appinfo.home_works && <div className="mpcard mpcenter" style={{backgroundColor:appinfo.PRIMARY_COLOR,color:"white"}}>
            <h2>{appinfo.CONTENTS.HomePage.howitworks.title}</h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/>
            <div className="how-it-works-desc" style={{paddingTop: 20,lineHeight: 1.25}}>
              <ul>
                {appinfo.CONTENTS.HomePage.howitworks.description.map( (desc,index) => {
                    return (
                      <li key={index}>{desc}</li>
                    );
                  })
                }
              </ul>
            </div>
            <div className="ctas">
                  {this.signInLive(history, authentication,user)}
                  <button
                    className="demo-button"
                    onClick={() => {this.performSearchV2()}}
                    style={{marginTop: 24, maxWidth: 160,borderColor:appinfo.SECONDARY_COLOR,color:appinfo.SECONDARY_COLOR}}
                    >
                    SEE PRODUCTS
                  </button>
              </div>
          </div>}
          {/**************************   VENDORS    *************************/}
          {appinfo.home_vendors && brands != undefined && brands.status == "GETBRANDS_SUCCESS" && brands.result.brands.length != 0 && this.hasImage(brands.result.brands) ? (
          <div className="mpcard mpcenter">
            <h2 style={{color:appinfo.PRIMARY_COLOR}}>{appinfo.brand_shopping}</h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/>
            <div className="mpcardContent">
              { brands.result.brands.map( vendor => {
                  if(vendor.image_path){
                    return(
                      <img src={utils.imagePath(vendor.image_path, "x300")} style={{height:30,margin:"20px auto 0px auto"}} className="vendor-image" 
                      // onClick={()=>{
                      //   let payload = {filter: {brand: vendor.brand}, search: `brand: \"${vendor.brand}\"`};
                      //   dispatch(searchActions.searchV2(payload));
                      //   history.push(`/search`);
                      //   window.scrollTo(0, 0);}
                      // }
                      />
                    );
                  }
                }) }
            </div>
          </div>
          ) : ( <div></div>)}
          {/* <div style={{ paddingTop: 80}}></div> */}

          {/* <div style={{ paddingTop: 200, backgroundImage:('linear-gradient(to top,'+ appinfo.PRIMARY_COLOR+  ',white)') }}>
            <img src={WavePattern} width="100%" />
          </div>  */}
         {appinfo.home_wave &&  <div>
            <img src={WavePattern} width="100%" />
         </div>  }   
          {/************************   company numbers   ***********************/}
          {appinfo.home_numbers && <VisibilitySensor onChange={this.onVisibilityChange} offset={{top: 10, bottom: 10}} delayedCall>
          <div className="mpcard" style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#ffffff"}}>
            <h2 style={{color: appinfo.PRIMARY_COLOR}}>
              { appinfo.CONTENTS.HomePage.inNumbers.title}
            </h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/>
            <div style={{display: "flex",flexWrap: "wrap",marginLeft: "auto",marginRight: "auto"}}>
              {appinfo.stats.map( (stat, index) => {
                  return(
                    <CountUp key={index} start={this.state.didViewCountUp ? 0 : stat.value } 
                          end={this.state.didViewCountUp ? stat.value : 0}
                          suffix={stat.adder}
                          ref={(countUp)=>{this.myCountUp = countUp;}}>
                           {({ countUpRef, start }) => (
                      <div  className="numbers">
                        <div style={{color: "black", fontWeight: "400",fontSize: "20px",paddingBottom: "10px",justifyContent: "center"}} className={"counter"+index}>
                          <span ref={countUpRef}/>
                        </div>
                        <div style={{color: "rgba(0, 0, 0, 0.54)"}}>
                          {stat.key}
                        </div>
                      </div>)}
                    </CountUp>
                  );
                })
              }
            </div>
          </div>
          </VisibilitySensor>}
          {/********************  BLOG SECTION   *********************/}
         {appinfo.home_blog && <div className="mpcard mpcenter blog" style={{backgroundColor:appinfo.SECONDARY_COLOR,color:appinfo.PRIMARY_COLOR}}>   
            <h2 style={{color:appinfo.PRIMARY_COLOR}}>
              { appinfo.CONTENTS.HomePage.blog.title }
            </h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor: appinfo.PRIMARY_COLOR}}/>
            {appinfo.CONTENTS.HomePage.blog.description.map( val=> {
                return (
                  <div style={{maxWidth:800,textAlign:"center"}}>
                    <p>{val}</p>
                  </div>
                );
              })
            }
            <button className="demo-button" style={{color: "#ffffff",backgroundColor: appinfo.PRIMARY_COLOR,borderColor:appinfo.SECONDARY_COLOR}} onClick={()=>this.requestRedirect()}>BOOK NOW</button>
            <div>
              <p>For latest news and trends, check out our&nbsp;
              <a href={appinfo.BLOG} className="blog-connect" style={{fontWeight: 600,color:appinfo.PRIMARY_COLOR}} target="_blank">blog</a> </p>
            </div>
          </div>}
          {/********************  end of BLOG SECTION   *********************/}
        </div>
      </Container>
    );
  }
}

const styles = {
  contact: {
    height: 30,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 111
  },
  contactContentColor: {
    color: "rgba(0, 0, 0, 0.38)"
  }
};

function mapStateToProps(state) {
  const { brands, category,  sku, inventory, authentication, user, add, deals,featured } = state;
 
  return {
    brands,
    category,
    sku,
    inventory,
    authentication,
    user,
    add,
    deals,
    featured

  };
}

export default withRouter(connect(mapStateToProps)(Home));
