import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import { utils } from "../../utils";
import "../../screens/styles.css";
import { cartActions } from "../../actions";
import appinfo from "../../clientsJSON";

class Product extends Component {
  
  onHover = (classname,colour) => {
    document.getElementsByClassName(classname)[0].style.color=colour;
  }
  
  render() {
    const { data,  onClick  ,url} = this.props;
   
    
    let imgSrc = data.sku_image != null ?  utils.imagePath(data.sku_image.split(",")[0], "x300") : "" ;
    let redirection=window.screen.width >768 ? "_blank":"_self"
    if (data.inventory_id == 0) {
      return <span />;
    } else {
      return (
        <div>
         <a  style={{textDecoration:"none"}}href={url} target={redirection}>
        
        <div onClick={onClick} className="product-card" >
          <div className="card">
            <img src={imgSrc} alt="" />
          </div>
          <div
            className={"title"+data.inventory_id}
            onMouseEnter = {() => this.onHover("title"+data.inventory_id,appinfo.PRIMARY_COLOR)}
            onMouseLeave = {() => this.onHover("title"+data.inventory_id,appinfo.SECONDARY_COLOR)}
            style={{
              paddingTop: 13,
              color: appinfo.SECONDARY_COLOR,
              fontSize: 14,
              width: 165,
              fontWeight: "600",
              cursor: "pointer"
            }}
            data-tip data-for={"product"+data.inventory_id}
          >
            { window.screen.width >= 768 ? (data.inventory_name.length>=21 ? 
                data.inventory_name.substring(0,20) + "..." : 
                data.inventory_name): data.inventory_name}

          </div>
          {appinfo.product_name == 1 && (
          <div
            style={{
              paddingTop: 5,
              fontSize: 12,
              width: 165,
              color: "rgba(32, 48, 71, 0.54)",
              fontWeight: "600",
              cursor: "pointer"
            }}
          >
            { window.screen.width >= 768 ? 
                (data.product_name.length >=26 ? 
                    data.product_name.substring(0,25)+"..." : data.product_name ) : 
                    data.product_name}
          </div>)}
          <div
            style={{
              paddingTop: 5,
              fontSize: 12,
              width: 165,
              color: "rgba(32, 48, 71, 0.54)",
              fontWeight: "600",
              cursor: "pointer"
            }}
            
          >
            { window.screen.width >= 768 ?
              (data.brand.length>=26 ? data.brand.substring(0,25)+"..." : data.brand) :
              data.brand}
            
          </div>
          <div
            style={{
              paddingTop: 5,
              fontSize: 16,
              width: 165,
              color: "rgba(32, 48, 71, 0.54)",
              fontWeight: "600",
              cursor: "pointer"
            }}
            
          >
            ₹{data.recent_selling_price} <span style={{fontSize:12,textDecoration:"line-through"}}>₹{data.msrp_price}</span> {Math.round((data.msrp_price-data.recent_selling_price)*100/data.msrp_price)}% off
            
          </div>
          <ReactTooltip id={"product"+data.inventory_id} type='dark' place='bottom' effect='solid'>
            <span style={{fontSize: 10}}>{data.inventory_name}</span><br/>
            <span style={{fontSize: 10}}>{data.product_name}</span><br/>
            <span style={{fontSize: 10}}>{data.brand}</span>
          </ReactTooltip>
        </div>
        </a>
        </div>
      );
    }
  }
}

Product.defaultProps = {
  onPress: () => null
};



export default Product;
