import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import Product from "../../components/product";
import SearchLoader from "../../components/search_loader";
import Container from "../container";
import "../styles.css";
import { inventoryActions } from "../../actions";
import { searchActions } from "../../actions";
import FilterList from "./filterList";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils";
import { BreadCrumbs } from "../../components";
class Search extends Component {
  constructor() {
    super();
    this.state = {
      page: 0
    };

    this.searchParams = new URLSearchParams();
  }

  renderProductList = () => {
    const { dispatch, search, history, location, cart } = this.props;

    if (
      search.results.length != 0 &&
      (search.status == "SEARCH_SUCCESS" ||
        search.status == "SEARCH_SCROLL_SUCCESS")
    ) {
      return (
        <>
          {search.results.map(e => {
            let data = e["_source"];
            let onClick = () => {
              const { removeValueFromUrl } = utils;
              let searchParams = location.search;

              searchParams = removeValueFromUrl(searchParams, ["brand"]);
              searchParams = "&" + searchParams;

              dispatch(inventoryActions.getByInventoryId(data.inventory_id));
              {
                window.screen.width > 768
                  ? appinfo.product_redirect == "new_page"
                    ? window.open(
                      `product/${data.inventory_id}/${data.inventory_name.replace(/\s+/g, '-').toLowerCase()}?` + searchParams
                      )
                    : history.push(
                      `product/${data.inventory_id}/${data.inventory_name.replace(/\s+/g, '-').toLowerCase()}?` + searchParams
                      )
                  : history.push(
                      `product/${data.inventory_id}/${data.inventory_name.replace(/\s+/g, '-').toLowerCase()}?` + searchParams
                    );
              }
              window.scrollTo(0, 0);
            };

            if (data.status == undefined || data.status == 1) {
              return <Product 
                      onClick={onClick} 
                      data={e["_source"]} 
                      />;
            }
          })}
        </>
      );
    } else if (
      search.status == "SEARCH_SCROLL_REQUEST" ||
      search.status == "SEARCH_REQUEST"
    ) {
      return <SearchLoader />;
    }
  };


  requestRedirect = () => {
    const { history } = this.props;
    const cook = new Cookies();
    cook.set("fromPage", "CONTACT_US", { path: "/" });
    cook.set("id", "", {path: "/"});
    history.push("/submitform", { page: "demo" });
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    const { location } = this.props;
    const hasFilter = new URLSearchParams(location.search).has("filter");

    if (hasFilter) {
      this.applyFilter({});
    }
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    const { search } = this.props;

    if (search.status == "SEARCH_SUCCESS") {
      this.setState({ page: 0 });
    }
    let page = this.state.page;
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 100
    ) {
      let scroll = window.scrollY;
      if (
        (search.total - search.results.length != 0 &&
          search.status == "SEARCH_SCROLL_SUCCESS") ||
        search.status == "SEARCH_SUCCESS"
      ) {
        this.applyFilter({
          filters: {},
          page: page + 1,
          filter: false
        });

        window.scrollTo(0, scroll);
        this.setState({ page: page + 1 });
      }
    }
  };

  phraseWithKey = (key, stringVal) => {
    return `(${key}: ${stringVal})`;
  };

  constructPhrase = (key, value) => {
    let phrase = `"${value}"`;

    phrase = phrase.replace(/"/g, '\"');
    return phrase;
  };

  checkType = (key, value) => {
    if (utils.isString(value)) {
      let phrase = "";

      this.searchParams.append(key, value);
      phrase = this.constructPhrase(key, value);
      phrase = this.phraseWithKey(key, phrase);

      return phrase;
    } else if (Array.isArray(value)) {
      let phrase = [];

      value.map(val => {
        this.searchParams.append(key, val);
        phrase.push(this.constructPhrase(key, val));
      });
      phrase = phrase.join(" OR ");
      phrase = this.phraseWithKey(key, phrase);

      return phrase;
    }
  };

  getSearchString = rawData => {
    let combinedPhrase = [];
    let ignoreList = ["level", "category_id", "fromMenu", "id", "filter"];
    let searchFilterKeys = Object.keys(rawData);

    searchFilterKeys.map(key => {
      let value = rawData[key];

      if (value) {
        if (value.length === 0) {
          return "Skipping current iteration";
        }

        if (!ignoreList.includes(key)) {
          combinedPhrase.push(this.checkType(key, value));
        } else {
          this.searchParams.append(key, value);
        }
      }
    });

    return combinedPhrase.join(" AND ");
  };

  applyFilter = ({ filters = {}, page = 0, filter = true }) => {
    const { dispatch, history, location } = this.props;
    const { search } = location;
    const { getValueFromUrl } = utils;
    let finalSearchString = "";
    let rawMergedData = {};
    let urlData = utils.getDataFromUrl(location.search);
    let payload = {};
    let fromMenu = utils.hasParamKey(location.search, "fromMenu");
    this.searchParams = new URLSearchParams();

    rawMergedData = utils.mergeObjects(urlData, filters);

    if (fromMenu) {
      finalSearchString = this.getSearchString(rawMergedData);
    } else {
      finalSearchString = getValueFromUrl(search, "searchQuery");
    }

    payload = {
      search: finalSearchString
    };

    if (fromMenu) {
      payload["filter"] = {
        category_id: parseInt(urlData.category_id[0])
      };
    }

    let filterPayload = {
      search: "*",
      filter : {
        category_id: parseInt(urlData.category_id[0])
      }
    }

    if (!utils.hasParamKey(location.search, "filter") && filter) {
      this.searchParams.append("filter", true);
    }

    dispatch(searchActions.searchV2(payload, page, !filter));
    dispatch(searchActions.searchFilter(filterPayload, false));

    history.push({
      pathname: location.pathname,
      search: fromMenu ? `?${this.searchParams.toString()}` : search
    });
  };

  render() {
    const { history, location, search, cart } = this.props;
    const { removeValueFromUrl } = utils;
    // let url = this.props.location.search.split("?").pop();
    // let params = url.split("&");
    // let heading = "";
    // let id = "";
    let initialFilterData = utils.getDataFromUrl(location.search);
    let fromMenu = utils.hasParamKey(location.search, "fromMenu");

    // for (let i = 0; i < params.length; i++) {
    //   heading =
    //     params[i].indexOf("q=") != -1 ? params[i].split("q=").pop() : heading;
    //   id = params[i].indexOf("id=") != -1 ? params[i].split("id=").pop() : id;
    // }

    let render =
      (search.results.length != 0 &&
        (search.status == "SEARCH_SUCCESS" ||
          search.status == "SEARCH_SCROLL_SUCCESS")) ||
      search.status == "SEARCH_REQUEST" ||
      search.status == "SEARCH_SCROLL_REQUEST" ? (
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: 29
            }}
          >
            <BreadCrumbs
              hide={!fromMenu}
              containerStyle={{ padding: 0, marginTop: 59 }}
              searchParams={location.search}
              onSelection={e => {
                let newSearchParams = removeValueFromUrl(e, ["brand"]);

                history.push(location.pathname + "?" + newSearchParams);
                this.setState({}, () => {
                  this.applyFilter({ filter: false });
                });
              }}
            />

            {/* <h2
              style={{
                color: "rgba(0,0,0,0.38)",
                fontSize: 16,
                textTransform: "uppercase"
              }}
            >
              {heading != "" && heading != "*"
                ? heading.split("%20").join(" ")
                : id == "" || heading == "*"
                ? "ALL PRODUCTS"
                : ""}
            </h2> */}
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "60px", justifyContent:"center"}}
          >
            {this.renderProductList()}
          </div>
        </div>
      ) : (
        <div style={{paddingBottom: 60}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "60px",
            }}
          >
            {/* Sorry! We are yet to add products. Keep exploring. */}
            Could not find the product you are looking for? Contact us.
          </div>
          { window.screen.width > 768 && appinfo.ROLE == 3 &&
          <button
            className="demo-button"
            style={{
              color: "#ffffff",
              marginTop: 20,
              marginLeft: "43%",
              backgroundColor: appinfo.PRIMARY_COLOR,
              boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.18)",
              fontWeight: "600",
              maxWidth: 160
            }}
            onClick={()=>this.requestRedirect()}
          >
            CONTACT US
          </button>}
        </div>
      );

    return (
      <Container>
        <div style={{ display: "flex" }}>
          <FilterList
            hideFilters={!fromMenu}
            initialData={initialFilterData}
            resetFilters={search.resetFilters}
            applyFilter={filters => this.applyFilter({ filters })}
            data={search.aggregations}
          />
          <div style={{ flex: 5 }} className="search-container">
            {render}
          </div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { search, cart } = state;

  return {
    cart,
    search
  };
}

export default connect(mapStateToProps)(Search);
